const Event = require("../classes/event.js");
const tcLib = require("../lib/timecode.js");
const stlFunc =require('../functions/profiles/ebuStl.js');
const removeInvalidEvents = require("../functions/eventGroups/removeInvalidEvents.js");
const getFormatOptions = require("../functions/helpers/getFormatOptions.js");
const convertToHtml = require("../functions/quill/convertToHtml.js");
const quillClasses = require("../dict/quillClasses.js");

module.exports = {
    decode: function (input, options) {
        let events = [],
            ttiInfo,
            ccEvent,
            boldOpen = false,
            italicOpen = false,
            underlineOpen = false,
            decodingOptions = getFormatOptions(options.formatOptions),
            accentInfo = {
                set : false,
                value : ""
            };

        let gsi = stlFunc.decodeGsiBlock(input.substring(0, 2048));
        let ttiBlocks = input.substring(2048, input.length).match(/.{1,256}/g);

        //console.log(decodingOptions);

        ttiBlocks.forEach(ttiBlock => {            
            ttiInfo = stlFunc.decodeTtiBlock(ttiBlock);
            //console.log(ttiInfo);
            if (ttiInfo.commentFlag === "00") {
                ccEvent = new Event({
                    xOffset : 0,
                    yOffset : options.window.height * -0.10
                });
                ccEvent.start = tcLib.tcToSec(stlFunc.decodeTc(ttiInfo.incode), gsi.frameRate);
                ccEvent.end = tcLib.tcToSec(stlFunc.decodeTc(ttiInfo.outcode), gsi.frameRate);
                ttiInfo.text.match(/.{1,2}/g).forEach(char => {
                    if (char === "f8"){
                        /* Control Code */
                    } else if (parseInt(char, 16) >= 0 && parseInt(char, 16) <= 31) {
                        /* TELETEXT CONTROL CODES */
                        if (stlFunc.cntrlCodes[char]){
                            ccEvent.colors += 1;

                            if (decodingOptions['Decode Speakers By Color'] && decodingOptions['Decode Speakers By Color'] !== "false"){                                
                                if (decodingOptions['Insert Speaker Identifier'] && decodingOptions['Insert Speaker Identifier'] !== "false" && ccEvent.color !== stlFunc.cntrlCodes[char] && ccEvent.colors > decodingOptions['Speaker Is Greater Than']){
                                    ccEvent.text = decodingOptions['Speaker Identifier']+ccEvent.text.trim();
                                    ccEvent.text += "\n"+decodingOptions['Speaker Identifier'];
                                }
                            }                        

                            ccEvent.color = stlFunc.cntrlCodes[char]
                        }
                    } else if ((parseInt(char, 16) >= 32 && parseInt(char, 16) <= 127) || (parseInt(char, 16) >= 161 && parseInt(char, 16) <= 255)) {
                        if (gsi.alphabet.toLowerCase() === 'latin' && parseInt(char, 16) !== 201 && parseInt(char, 16) !== 204 && parseInt(char, 16) >= 193 && parseInt(char, 16) <= 207 ){
                            /* EBU STL uses a floating accent principle so we need to store the accents as they come in and apply them on the next character. See document for more info. */
                            accentInfo = {
                                set : true,
                                value : parseInt(char, 16)
                            }
                            //console.log(accentInfo);
                        } else {
                            if (accentInfo.set){       
                                //console.log(char.toLowerCase(), accentInfo.value)
                                if (stlFunc.accentMapping[char.toLowerCase()] && stlFunc.accentMapping[char.toLowerCase()][accentInfo.value]){
                                    ccEvent.text += stlFunc.accentMapping[char.toLowerCase()][accentInfo.value];
                                } else {
                                    ccEvent.text += "*";
                                }
                                
                                accentInfo.set = false;
                            } else {
                                //console.log(parseInt(char, 16) + " : " + stlFunc.decodeChar(char, stlFunc.codePageMapping[gsi.alphabet]));
                                ccEvent.text += stlFunc.decodeChar(char, stlFunc.codePageMapping[gsi.alphabet]);
                            }                           
                        }                        
                    } else if (parseInt(char, 16) >= 128 && parseInt(char, 16) <= 133) { 
                        /* Format Codes */
                        if (stlFunc.controlCodeMapping[char]){
                            if (stlFunc.controlCodeMapping[char] === "<i>"){
                                if (!italicOpen){
                                    ccEvent.text += stlFunc.controlCodeMapping[char];
                                    italicOpen = true;
                                }                                
                            } else if (stlFunc.controlCodeMapping[char] === "<u>"){
                                if (!underlineOpen){
                                    ccEvent.text += stlFunc.controlCodeMapping[char];
                                    underlineOpen = true;
                                }     
                            } else if (stlFunc.controlCodeMapping[char] === "<b>"){
                                if (!boldOpen){
                                    ccEvent.text += stlFunc.controlCodeMapping[char];
                                    boldOpen = true;
                                }     
                            } else if (stlFunc.controlCodeMapping[char] === "</i>"){                                
                                if (italicOpen){
                                    ccEvent.text += stlFunc.controlCodeMapping[char];
                                    italicOpen = false;
                                }     
                            } else if (stlFunc.controlCodeMapping[char] === "</u>"){
                                if (underlineOpen){
                                    ccEvent.text += stlFunc.controlCodeMapping[char];
                                    underlineOpen = false;
                                }                                   
                            } else if (stlFunc.controlCodeMapping[char] === "</b>"){
                                if (boldOpen){
                                    ccEvent.text += stlFunc.controlCodeMapping[char];
                                    boldOpen = false;
                                }
                            }
                        }
                    } else if (parseInt(char, 16) === 138 /* 8A */) { 
                        /* Insert New Line */
                        ccEvent.text = ccEvent.text.trim()+"\n";
                    }
                });    
                
                /* Set alignment and xPos */
                if (ttiInfo.xPos === "start"){
                    ccEvent.alignment = "left";
                    ccEvent.xPos = "start";
                    ccEvent.xOffset = options.window.width * 0.10;
                } else if (ttiInfo.xPos === "end"){
                    ccEvent.alignment = "right";
                    ccEvent.xPos = "end";
                    ccEvent.xOffset = options.window.width * -0.10;
                } else {
                    ccEvent.alignment = "center";
                    ccEvent.xPos = "center";
                }

                /* Set yPos */
                if (ttiInfo.yPos > 0 && ttiInfo.yPos < 40){
                    ccEvent.yPos = "start";
                    ccEvent.yOffset = options.window.height * 0.10 + (options.window.height * ((100-ttiInfo.yPos)/100));
                } else if (ttiInfo.yPos >= 45 && ttiInfo.yPos < 55){
                    ccEvent.yPos = "center";
                    ccEvent.yOffset = 0;
                } else {
                    ccEvent.yPos = "end";
                    ccEvent.yOffset = (options.window.height * -0.10) - (options.window.height * ((100-ttiInfo.yPos)/100));
                }

                //console.log(ttiInfo.yPos,"-->", ccEvent.yOffset, options.window.height);

                if (boldOpen){
                    ccEvent.text += stlFunc.controlCodeMapping["85"];
                    boldOpen = false;
                }

                if (underlineOpen){
                    ccEvent.text += stlFunc.controlCodeMapping["83"];
                    underlineOpen = false;
                }

                if (italicOpen){
                    ccEvent.text += stlFunc.controlCodeMapping["81"];
                    italicOpen = false;
                }               

                accentInfo.set = false;
                ccEvent.text = convertToHtml(ccEvent.text.replace(/ +/gmi, " ").replace(/^ +/gmi, ""), [quillClasses.align[ccEvent.alignment]])
                events.push(ccEvent);
            }
        });
        
        return events;
    },

    encode: function (eventGroup, options) {
        let output = "", gsi, encodingOptions = getFormatOptions(options.formatOptions), tti;
        
        gsi = stlFunc.encodeGsiBlock(eventGroup.events, encodingOptions, options);
        output += gsi.concat();
        eventGroup.events.forEach(function(event, index){
            tti = stlFunc.encodeTtiBlock(event, index, encodingOptions, options);
            output += tti.concat();
        });

        return output;
    },

    preProcess: {
        encode: function (eventGroup) {
            return removeInvalidEvents(eventGroup);
        },

        decode: function (input) {
            return input;
        }
    },

    postProcess: {
        encode: function (output) {
            return output;
        },

        decode: function (eventGroup) {
            eventGroup.events =  eventGroup.events.filter((event,index, events) => {
                if (events[index+1]){
                    if (event.start === events[index+1].start && event.end === events[index+1].end){
                        return false;
                    } else {
                        return true;
                    }
                } else {
                    return true;
                }
                
            });

            return eventGroup;
        }
    }

}
