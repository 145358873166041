/* 
    Title Safe Info:
    http://www.indefilms.net/html/title_safe_area.html
    SCC INFO:
    -We work on a grid that is 0-31 across and 0-14 down (32x15)
    -There is a fixed offset of 15% (x) and 5% (y). This is due to the caption area being 70% of the frames width and 90% of the frames height.
    -The fixed offset is only applied to decode. For encode we try and fit it to the best location.
    -The fixed offset is applied during post-process decode.
*/
const tcLib = require("../lib/timecode.js");
const removeInvalidEvents = require("../functions/eventGroups/removeInvalidEvents.js");
const getFormatOptions = require("../functions/helpers/getFormatOptions.js");
const eol = require("eol");
const sccFunc = require("../functions/profiles/scenerist.js");
const convertToPopOn = require("../functions/special/convertToPopOn.js")
const sccLookup = require("../dict/608.js");
const autoFormatSimple = require("../functions/utility/autoFormatSimple.js")
module.exports = {
    decode: function (input, options) {
        let events = [], displays = [];
        let buffer = new sccFunc.Display(),
            screen = false,
            clock,
            displayStyle = false,
            sccCode;

        buffer.insertLine();

        /* Split File lines and filter anything that doesn't have a timecode */
        let fileLines = input.split("\n").filter((fileLine) => {
            return /^\d\d:\d\d:\d\d;\d\d$|^\d\d:\d\d:\d\d:\d\d$/g.test(
                fileLine.split("\t")[0]
            );
        });

        fileLines.forEach((fileLine) => {
            clock = tcLib.createTc(
                fileLine.split("\t")[0],
                options.frameRate,
                options.dropFrame
            );

            /* Get all caption codes */
            let captionCodes = fileLine.split("\t")[1].trim().split(" ");
            captionCodes.forEach(function (code, index, codes) {
                if (code.length !== 4) {
                    throw new Error(
                        "SCC decode error at timecode: " +
                        clock.toString() +
                        "\nCommand length exceeds limit: " +
                        code
                    );
                }

                if ((sccLookup.all[code] && code !== codes[index + 1]) || sccLookup.specialChars.indexOf(sccLookup.all[code]) > -1) {
                    sccCode = sccLookup.all[code];
                    if (sccCode === "{RESUME LOADING}") {
                        displayStyle = "popOn";
                        if (buffer.lines.length > 0 && buffer.lines[0].text !== "") {
                            buffer = new sccFunc.Display();
                            buffer.insertLine();
                        }
                    } else if (sccCode === "{PAINT ON}") {
                        displayStyle = "paintOn";
                        screen = new sccFunc.Display({
                            style: "Paint-On",
                            start: tcLib.tcToSec(clock.toString(), options.frameRate)
                        });

                        screen.insertLine();
                    } else if (sccCode === "{ROLLUP2}") {
                        displayStyle = "rollUp2";

                        /* Add outcode for the top line of event */
                        if (displays.length > 0 && displays[displays.length - 1].style === "Roll-Up 2" && !displays[displays.length - 1].end) {
                            displays[displays.length - 1].end = tcLib.tcToSec(clock.toString(), options.frameRate);
                        }


                        if (screen) {
                            displays.push(screen);
                        }

                        screen = new sccFunc.Display({
                            style: "Roll-Up 2",
                            start: tcLib.tcToSec(clock.toString(), options.frameRate),
                        });

                        screen.insertLine({
                            yPos: 13,
                        });

                    } else if (sccCode === "{ROLLUP3}") {
                        displayStyle = "rollUp3";

                        /* Add outcode for the top line of event */
                        if (displays.length > 1 && displays[displays.length - 2].style === "Roll-Up 3" && !displays[displays.length - 2].end) {
                            displays[displays.length - 2].end = tcLib.tcToSec(clock.toString(), options.frameRate);
                        }


                        if (screen) {
                            displays.push(screen);
                        }

                        screen = new sccFunc.Display({
                            style: "Roll-Up 3",
                            start: tcLib.tcToSec(clock.toString(), options.frameRate),
                        });

                        screen.insertLine({
                            yPos: 12,
                        });

                    } else if (sccCode === "{ROLLUP4}") {
                        displayStyle = "rollUp4";

                        /* Add outcode for the top line of event */
                        if (displays.length > 2 && displays[displays.length - 3].style === "Roll-Up 4" && !displays[displays.length - 3].end) {
                            displays[displays.length - 3].end = tcLib.tcToSec(clock.toString(), options.frameRate);
                        }


                        if (screen) {
                            displays.push(screen);
                        }

                        screen = new sccFunc.Display({
                            style: "Roll-Up 4",
                            start: tcLib.tcToSec(clock.toString(), options.frameRate),
                        });

                        screen.insertLine({
                            yPos: 11,
                        });
                    } else if (sccCode === "{TEXT MSG}") {
                        /* Not Supported */
                    } else if (sccCode === "{RESUME TEXT MSG}") {
                        /* Not Supported */
                    } else if (sccCode === "{CLEAR BUFFER}") {
                        buffer = new sccFunc.Display();
                        buffer.insertLine();
                    } else if (sccCode === "{CLEAR DISPLAY}") {
                        //console.log(screen);
                        if (screen && !screen.end) {
                            screen.end = tcLib.tcToSec(
                                clock.toString(),
                                options.frameRate
                            );

                            displays.push(screen);
                            screen = new sccFunc.Display({
                                style: screen.style,
                                start: tcLib.tcToSec(clock.toString(), options.frameRate),
                            });

                            screen.insertLine({
                                yPos: 11,
                            });
                        }

                        displays.forEach((display, index, displaysArray) => {
                            if (["Roll-Up 2", "Roll-Up 3", "Roll-Up 4", "Paint-On"].indexOf(display.style) > -1 && !display.end) {
                                displaysArray[index].end = tcLib.tcToSec(clock.toString(), options.frameRate);
                            }
                        });
                    } else if (sccCode === "{DISPLAY BUFFER}") {
                        /* Clear what's on the screen if there is something still being shown */
                        if (screen && !screen.end) {
                            screen.end = tcLib.tcToSec(
                                clock.toString(),
                                options.frameRate
                            );

                            displays.push(screen);
                        }

                        buffer.start = tcLib.tcToSec(
                            clock.toString(),
                            options.frameRate
                        );

                        screen = buffer;
                        buffer = new sccFunc.Display();
                        buffer.insertLine();

                    } else if (sccCode === "{ITALICS}") {
                        if (displayStyle === "popOn") {
                            if (buffer.lines[buffer.lines.length - 1].text.length > 0 && !buffer.lines[buffer.lines.length - 1].text.endsWith(' ')) {
                                buffer.lines[buffer.lines.length - 1].text += " ";
                            }

                            buffer.lines[buffer.lines.length - 1].text += "<em>";
                        } else if (screen) {
                            if (screen.lines[screen.lines.length - 1].text.length > 0 && !screen.lines[screen.lines.length - 1].text.endsWith(' ')) {
                                screen.lines[screen.lines.length - 1].text += " ";
                            }

                            screen.lines[screen.lines.length - 1].text += "<em>";
                        }
                    } else if (sccCode === "{UNDERLINE}") {
                        if (displayStyle === "popOn") {
                            if (buffer.lines[buffer.lines.length - 1].text.length > 0 && !buffer.lines[buffer.lines.length - 1].text.endsWith(' ')) {
                                buffer.lines[buffer.lines.length - 1].text += " ";
                            }

                            buffer.lines[buffer.lines.length - 1].text += "<u>";
                        } else {
                            if (screen.lines[screen.lines.length - 1].text.length > 0 && !screen.lines[screen.lines.length - 1].text.endsWith(' ')) {
                                screen.lines[screen.lines.length - 1].text += " ";
                            }

                            screen.lines[screen.lines.length - 1].text += "<u>";
                        }
                    } else if (sccCode === "{ITALICS_UNDERLINE}") {
                        if (displayStyle === "popOn") {
                            buffer.lines[buffer.lines.length - 1].text += "<em><u>";
                        } else {
                            screen.lines[screen.lines.length - 1].text += "<em><u>";
                        }
                    } else if (/{\d\d_\d\d}{ITALICS_UNDERLINE}/.test(sccCode)) {
                        let xPos = parseInt(sccCode.substring(4, 6));
                        let yPos = parseInt(sccCode.substring(1, 3));

                        if (displayStyle === "popOn") {
                            buffer.insertLine({
                                text: "<em><u>",
                                xPos: xPos,
                                yPos: yPos,
                            });
                        } else {
                            screen.insertLine({
                                text: "<em><u>",
                                xPos: xPos,
                                yPos: yPos,
                            });
                        }
                    } else if (/{\d\d_\d\d}{ITALICS}/.test(sccCode)) {
                        let xPos = parseInt(sccCode.substring(4, 6));
                        let yPos = parseInt(sccCode.substring(1, 3));
                        if (displayStyle === "popOn") {
                            buffer.insertLine({
                                text: "<em>",
                                xPos: xPos,
                                yPos: yPos,
                            });
                        } else if (screen) {
                            screen.insertLine({
                                text: "<em>",
                                xPos: xPos,
                                yPos: yPos,
                            });
                        }
                    } else if (/{\d\d_\d\d}{UNDERLINE}/.test(sccCode)) {
                        let xPos = parseInt(sccCode.substring(4, 6));
                        let yPos = parseInt(sccCode.substring(1, 3));
                        if (displayStyle === "popOn") {
                            buffer.insertLine({
                                text: "<u>",
                                xPos: xPos,
                                yPos: yPos,
                            });
                        } else {
                            screen.insertLine({
                                text: "<u>",
                                xPos: xPos,
                                yPos: yPos,
                            });
                        }
                    } else if (/{\d\d_\d\d}/.test(sccCode)) {
                        let xPos = parseInt(sccCode.substring(4, 6));
                        let yPos = parseInt(sccCode.substring(1, 3));
                        if (displayStyle === "popOn") {
                            buffer.insertLine({
                                xPos: xPos,
                                yPos: yPos,
                            });
                        } else if (screen) {
                            screen.insertLine({
                                xPos: xPos,
                                yPos: yPos,
                            });
                        }
                    } else if (sccCode.includes("TAB")) {
                        let xOffset = parseInt(sccCode.split("TAB")[1]);
                        if (displayStyle === "popOn") {
                            buffer.lines[buffer.lines.length - 1].xPos += xOffset;
                        } else if (screen) {
                            screen.lines[screen.lines.length - 1].xPos += xOffset;
                        }
                    } else if (sccCode.includes("{COLOR:")) {
                        let color = sccCode.split(":")[1].split(";")[0].split("}")[0];
                        let underline = sccCode.includes("UNDERLINE");
                        if (displayStyle === "popOn") {
                            buffer.lines[buffer.lines.length - 1].color = sccFunc.colorMapping[color];
                            underline ? buffer.lines[buffer.lines.length - 1].text += "<u>" : null;
                            if (sccCode === "{COLOR:WHITE}" && codes[index + 1] && sccLookup.all[codes[index + 1]] !== " ") {
                                if (buffer.lines[buffer.lines.length - 1].text.includes("<em>") && !buffer.lines[buffer.lines.length - 1].text.includes("</em>")) {
                                    buffer.lines[buffer.lines.length - 1].text += "</em>";
                                }

                                buffer.lines[buffer.lines.length - 1].text += " ";
                            }
                        } else {
                            screen.lines[screen.lines.length - 1].color = sccFunc.colorMapping[color];
                            underline ? screen.lines[screen.lines.length - 1].text += "<u>" : null;
                            if (sccCode === "{COLOR:WHITE}" && codes[index + 1] && sccLookup.all[codes[index + 1]] !== " ") {
                                screen.lines[screen.lines.length - 1].text += " ";
                            }
                        }
                    } else if (sccCode.includes("{BACKGROUND:")) {
                        let bgColor = sccCode.split(":")[1].split(";")[0].split("}")[0];
                        let opacity = 1;
                        if (sccCode.includes("TRANSPARENT") && bgColor != "NONE") {
                            opacity = 0.5;
                        } else if (bgColor === "NONE") {
                            opacity = 0.0;
                        }

                        if (displayStyle === "popOn") {
                            buffer.lines[buffer.lines.length - 1].background = sccFunc.colorMapping[bgColor];
                            buffer.lines[buffer.lines.length - 1].opacity = opacity;
                        } else {
                            screen.lines[screen.lines.length - 1].background = sccFunc.colorMapping[bgColor];
                            screen.lines[screen.lines.length - 1].opacity = opacity;
                        }
                    } else if (sccCode === "{NEW LINE}") {
                        if (displayStyle === "popOn") {
                            if (buffer.lines.length > 0 && buffer.lines[buffer.lines.length - 1].text) {
                                buffer.insertLine();
                            }
                        } else if (displayStyle === 'rollUp2' || displayStyle === 'rollUp3' || displayStyle === 'rollUp4') {
                            if (screen && screen.lines[screen.lines.length - 1].text) {
                                /* Add outcode for the top line of event */
                                if (displayStyle === 'rollUp2') {
                                    if (displays.length > 0 && displays[displays.length - 1].style === "Roll-Up 2" && !displays[displays.length - 1].end) {
                                        displays[displays.length - 1].end = tcLib.tcToSec(clock.toString(), options.frameRate);
                                    }


                                    if (screen) {
                                        displays.push(screen);
                                    }

                                    screen = new sccFunc.Display({
                                        style: "Roll-Up 2",
                                        start: tcLib.tcToSec(clock.toString(), options.frameRate),
                                    });

                                    screen.insertLine({
                                        yPos: 13,
                                    });
                                } else if (displayStyle === 'rollUp3') {
                                    if (displays.length > 1 && displays[displays.length - 2].style === "Roll-Up 3" && displays[displays.length - 2].end) {
                                        displays[displays.length - 2].end = tcLib.tcToSec(clock.toString(), options.frameRate);
                                    }

                                    if (screen) {
                                        displays.push(screen);
                                    }

                                    screen = new sccFunc.Display({
                                        style: "Roll-Up 3",
                                        start: tcLib.tcToSec(clock.toString(), options.frameRate),
                                    });

                                    screen.insertLine({
                                        yPos: 12,
                                    });
                                } else if (displayStyle === 'rollUp4') {
                                    if (displays.length > 2 && displays[displays.length - 3].style === "Roll-Up 4" && !displays[displays.length - 3].end) {
                                        displays[displays.length - 3].end = tcLib.tcToSec(clock.toString(), options.frameRate);
                                    }


                                    if (screen) {
                                        displays.push(screen);
                                    }

                                    screen = new sccFunc.Display({
                                        style: "Roll-Up 4",
                                        start: tcLib.tcToSec(clock.toString(), options.frameRate),
                                    });

                                    screen.insertLine({
                                        yPos: 11,
                                    });
                                }
                            }
                        } else if (displayStyle === 'paintOn') {
                            if (screen) {
                                screen.insertLine({ style: "Paint-On" });
                            }
                        }
                    } else if (sccCode === "{BACKSPACE}") {
                        if (displayStyle === "popOn") {
                            if (buffer.lines && buffer.lines[buffer.lines.length - 1].text && !buffer.lines[buffer.lines.length - 1].text.endsWith('>')){
                                buffer.lines[buffer.lines.length - 1].text = buffer.lines[
                                    buffer.lines.length - 1
                                ].text.slice(0, -1);
                            }                            
                        } else {
                            screen.lines[screen.lines.length - 1].text = screen.lines[
                                buffer.lines.length - 1
                            ].text.slice(0, -1);
                        }
                    } else if (sccCode === "{DELETE ROW}") {
                        if (displayStyle === "popOn") {
                            buffer.lines[buffer.lines.length - 1].text = "";
                            buffer.lines[buffer.lines.length - 1].yPos = 0;
                        } else {
                            screen.lines[screen.lines.length - 1].text = "";
                            screen.lines[screen.lines.length - 1].yPos = 0;
                        }
                    } else if (sccCode !== "{FILLER}") {
                        if (displayStyle === "popOn") {
                            if (new RegExp(sccLookup.specialCharsFilter.join("|")).test(sccCode)) {
                                if (buffer.lines[buffer.lines.length - 1].text.length > 1 && sccCode !== "♪") {
                                    if (buffer.lines[buffer.lines.length - 1].text.slice(-2, -1) === buffer.lines[buffer.lines.length - 1].text.slice(-1)) {
                                        buffer.lines[buffer.lines.length - 1].text = buffer.lines[buffer.lines.length - 1].text.slice(0, -1)
                                    }
                                }
                            }

                            buffer.lines[buffer.lines.length - 1].text += sccCode;

                        } else if (screen) {
                            if (new RegExp(sccLookup.specialCharsFilter.join("|")).test(sccCode)) {
                                if (screen.lines[screen.lines.length - 1].text.length > 1 && sccCode !== "♪") {
                                    if (screen.lines[screen.lines.length - 1].text.slice(-2, -1) === screen.lines[screen.lines.length - 1].text.slice(-1)) {
                                        screen.lines[screen.lines.length - 1].text = screen.lines[screen.lines.length - 1].text.slice(0, -1)
                                    }
                                }
                            }

                            screen.lines[screen.lines.length - 1].text += sccCode;
                        }
                    }

                    // Add 1 frame for each byte (or hex group);
                    clock.add(1);
                } else if (!sccLookup.all[code]) {
                    [code.substring(0, 2), code.substring(2, 4)]
                        .filter((sccCode) => {
                            return (
                                sccLookup.all[sccCode] !== undefined &&
                                sccLookup.all[sccCode] !== "{FILLER}"
                            );
                        })
                        .forEach((sccCode) => {
                            if (displayStyle === "popOn") {
                                if (buffer.lines.length === 0) {
                                    buffer.insertLine();
                                }
                                buffer.lines[buffer.lines.length - 1].text +=
                                    sccLookup.all[sccCode];
                            } else if (screen.lines) {
                                screen.lines[screen.lines.length - 1].text +=
                                    sccLookup.all[sccCode];
                            }
                        });

                    // Add 1 frame for each byte (or hex group);
                    clock.add(1);
                }
            });
        });

        //console.log(JSON.stringify(displays, null, 4));
        displays.forEach(display => {
            //console.log(display);
            events.push(sccFunc.decodeDisplay(display, options.window));
        });

        //console.log(JSON.stringify(events, null, 4));
        return events;
    },
    encode: function (eventGroup, options) {
        let output = "Scenarist_SCC V1.0",
            encodingOptions = getFormatOptions(options.formatOptions),
            incode,
            outcodeOfPrevEvent,
            eventDetails,
            encodedText,
            encodedTextString,
            encodeTime,
            frameDifference,
            displayFlag = false,
            channel = "ch01",
            timecodeOption = "auto",
            clock;

        if (encodingOptions["Channel"]) {
            channel = encodingOptions["Channel"].toLowerCase();
        }

        if (encodingOptions["Timecode Format"]) {
            timecodeOption = encodingOptions["Timecode Format"].toLowerCase();
        }

        if (encodingOptions["Incode"] && encodingOptions["Incode"] !== "") {
            try {
                clock = tcLib.createTc(
                    encodingOptions["Incode"],
                    options.frameRate,
                    options.dropFrame
                )
            } catch (err) {
                throw new Error(err.message);
            }
        } else {
            clock = tcLib.createTc(
                tcLib.secToTc(eventGroup.events[0].start, options.frameRate),
                options.frameRate,
                options.dropFrame
            );

            try {
                clock.subtract(100);
            } catch (e) {
                clock = tcLib.createTc(
                    "00:00:00:01",
                    options.frameRate,
                    options.dropFrame
                );
            }
        }

        output += "\n\n" + tcLib.formatTimecodeString(clock.toString(), options.dropFrame, timecodeOption) + "\t";
        output += sccFunc.getCodeByCmd(sccLookup[channel], "{CLEAR DISPLAY}") + " " + sccFunc.getCodeByCmd(sccLookup[channel], "{CLEAR BUFFER}");
        clock.add(2);

        eventGroup.events.forEach(function (event, index, events) {
            incode = tcLib.createTc(
                tcLib.secToTc(event.start, options.frameRate),
                options.frameRate,
                options.dropFrame
            );

            eventDetails = sccFunc.getEventDetails(event, options.window);
            // console.log("----------");
            // console.log(eventDetails);
            encodedText = sccFunc.encodeEvent(eventDetails, channel, options.window);
            encodedTextString = sccFunc.formatEncodedCmds(encodedText);
            encodeTime = sccFunc.calculateEncodeTime(encodedTextString);
            if (event.style === "Pop-On") {
                output +=
                    "\n\n" +
                    tcLib.formatTimecodeString(
                        clock.toString(),
                        options.dropFrame,
                        timecodeOption
                    ) +
                    "\t" +
                    encodedTextString;

                clock.add(encodeTime);

                if (incode.frameCount <= clock.frameCount) {
                    output +=
                        " " + sccFunc.getCodeByCmd(sccLookup[channel], "{DISPLAY BUFFER}");

                    output +=
                        " " + sccFunc.getCodeByCmd(sccLookup[channel], "{CLEAR BUFFER}");

                    displayFlag = true;
                    clock.add(2);
                } else {
                    if (displayFlag) {
                        //console.log(events[index - 1].end)
                        outcodeOfPrevEvent = tcLib.createTc(
                            tcLib.secToTc(events[index - 1].end, options.frameRate),
                            options.frameRate,
                            options.dropFrame
                        );

                        if (outcodeOfPrevEvent.frameCount <= clock.frameCount && outcodeOfPrevEvent.frameCount >= clock.frameCount - 2) {
                            frameDifference = Math.max(
                                0,
                                clock.frameCount - (outcodeOfPrevEvent.frameCount + 1)
                            );

                            for (let j = 0; j < frameDifference; j++) {
                                output +=
                                    " " + sccFunc.getCodeByCmd(sccLookup[channel], "{FILLER}");
                            }

                            output +=
                                " " +
                                sccFunc.getCodeByCmd(sccLookup[channel], "{CLEAR DISPLAY}");

                            displayFlag = false;
                            clock.add(1 + frameDifference);


                            if (clock.frameCount < incode.frameCount) {
                                clock = incode;
                            } else if (clock.frameCount > incode.frameCount) {
                                //console.log("CLOCK IS AHEAD OF INCODE EVENT");
                                //console.log(clock.toString(), incode.toString());
                            }

                            output +=
                                "\n\n" +
                                tcLib.formatTimecodeString(
                                    clock.toString(),
                                    options.dropFrame,
                                    timecodeOption
                                ) +
                                "\t" +
                                sccFunc.getCodeByCmd(sccLookup[channel], "{DISPLAY BUFFER}");

                            output +=
                                " " +
                                sccFunc.getCodeByCmd(sccLookup[channel], "{CLEAR BUFFER}");

                            displayFlag = true;
                            clock.add(2);
                        } else {
                            if (clock.frameCount < outcodeOfPrevEvent.frameCount) {
                                clock = outcodeOfPrevEvent;
                            } else if (clock.frameCount > outcodeOfPrevEvent.frameCount) {
                                //console.log("CLOCK IS AHEAD OF PREV EVENT");
                                //console.log(clock.toString(), outcodeOfPrevEvent.toString());
                            }

                            if (incode.frameCount <= clock.frameCount + 1) {
                                output +=
                                    "\n\n" +
                                    tcLib.formatTimecodeString(
                                        clock.toString(),
                                        options.dropFrame,
                                        timecodeOption
                                    ) +
                                    "\t" +
                                    sccFunc.getCodeByCmd(sccLookup[channel], "{DISPLAY BUFFER}");

                                output +=
                                    " " +
                                    sccFunc.getCodeByCmd(sccLookup[channel], "{CLEAR BUFFER}");

                                displayFlag = true;
                                clock.add(2);
                            } else {
                                output += "\n\n" +
                                    tcLib.formatTimecodeString(
                                        clock.toString(),
                                        options.dropFrame,
                                        timecodeOption
                                    ) + "\t" + sccFunc.getCodeByCmd(sccLookup[channel], "{CLEAR DISPLAY}");

                                displayFlag = false;
                                clock.add(1);

                                if (clock.frameCount < incode.frameCount) {
                                    clock = incode;
                                } else if (clock.frameCount > incode.frameCount) {
                                    //console.log("CLOCK IS AHEAD OF INCODE EVENT");
                                    //console.log(clock.toString(), incode.toString());
                                }
                                output +=
                                    "\n\n" +
                                    tcLib.formatTimecodeString(
                                        clock.toString(),
                                        options.dropFrame,
                                        timecodeOption
                                    ) +
                                    "\t" +
                                    sccFunc.getCodeByCmd(sccLookup[channel], "{DISPLAY BUFFER}");

                                output +=
                                    " " +
                                    sccFunc.getCodeByCmd(sccLookup[channel], "{CLEAR BUFFER}");

                                displayFlag = true;
                                clock.add(2);
                            }
                        }
                    } else if (incode.frameCount <= clock.frameCount) {
                        output +=
                            " " +
                            sccFunc.getCodeByCmd(sccLookup[channel], "{DISPLAY BUFFER}");

                        output +=
                            " " + sccFunc.getCodeByCmd(sccLookup[channel], "{CLEAR BUFFER}");

                        displayFlag = true;
                        clock.add(2);
                    } else {
                        if (clock.frameCount < incode.frameCount) {
                            clock = incode;
                        } else if (clock.frameCount > incode.frameCount) {
                            //console.log("CLOCK IS AHEAD OF INCODE EVENT");
                            //console.log(clock.toString(), incode.toString());
                        }

                        output +=
                            "\n\n" +
                            tcLib.formatTimecodeString(
                                clock.toString(),
                                options.dropFrame,
                                timecodeOption
                            ) +
                            "\t" +
                            sccFunc.getCodeByCmd(sccLookup[channel], "{DISPLAY BUFFER}");

                        output +=
                            " " + sccFunc.getCodeByCmd(sccLookup[channel], "{CLEAR BUFFER}");

                        displayFlag = true;
                        clock.add(2);
                    }
                }
                /* RollUp */
            } else if (event.style === "Roll-Up 2" || event.style === "Roll-Up 3" || event.style === "Roll-Up 4"){
                if (displayFlag) {
                    outcodeOfPrevEvent = tcLib.createTc(
                        tcLib.secToTc(events[index - 1].end, options.frameRate),
                        options.frameRate,
                        options.dropFrame
                    );
                    
                    if ((events[index - 1].style === "Roll-Up 2" || events[index - 1].style === "Roll-Up 3" || events[index - 1].style === "Roll-Up 4") && parseFloat(events[index - 1].end) > parseFloat(event.start)){
                        /* Put the event up on screen start */
                        if (incode.frameCount <= clock.frameCount && incode.frameCount >= clock.frameCount - 2) {
                            frameDifference = Math.max(
                                0,
                                clock.frameCount - (incode.frameCount + 1)
                            );
        
                            for (let j = 0; j < frameDifference; j++) {
                                output +=
                                    " " + sccFunc.getCodeByCmd(sccLookup[channel], "{FILLER}");
                            }
        
                            output += " " + encodedTextString;
                            displayFlag = true;
                            clock.add(encodeTime + frameDifference);
                        } else {
                            if (clock.frameCount < incode.frameCount) {
                                clock = incode;
                            }
        
                            output +=
                                "\n\n" +
                                tcLib.formatTimecodeString(
                                    clock.toString(),
                                    options.dropFrame,
                                    timecodeOption
                                ) +
                                "\t" +
                                encodedTextString;
        
                            displayFlag = true;
                            clock.add(encodeTime);
                        }
                        /* Put the event up on screen end*/
                    } else {
                        if (outcodeOfPrevEvent.frameCount <= clock.frameCount && outcodeOfPrevEvent.frameCount >= clock.frameCount - 2) {
                            frameDifference = Math.max(
                                0,
                                clock.frameCount - (outcodeOfPrevEvent.frameCount + 1)
                            );
                            for (let j = 0; j < frameDifference; j++) {
                                output +=
                                    " " + sccFunc.getCodeByCmd(sccLookup[channel], "{FILLER}");
                            }
    
                            output +=
                                " " + sccFunc.getCodeByCmd(sccLookup[channel], "{CLEAR DISPLAY}");
    
                            displayFlag = false;
                            clock.add(1 + frameDifference);
                        } else {
                            if (clock.frameCount < outcodeOfPrevEvent.frameCount) {
                                clock = outcodeOfPrevEvent;
                            }
    
                            output +=
                                "\n\n" +
                                tcLib.formatTimecodeString(
                                    clock.toString(),
                                    options.dropFrame,
                                    timecodeOption
                                ) +
                                "\t" +
                                sccFunc.getCodeByCmd(sccLookup[channel], "{CLEAR DISPLAY}");
    
                            displayFlag = false;
                            clock.add(1);
                        }

                        /* Put the event up on screen start */
                        if (incode.frameCount <= clock.frameCount && incode.frameCount >= clock.frameCount - 2) {
                            frameDifference = Math.max(
                                0,
                                clock.frameCount - (incode.frameCount + 1)
                            );
        
                            for (let j = 0; j < frameDifference; j++) {
                                output +=
                                    " " + sccFunc.getCodeByCmd(sccLookup[channel], "{FILLER}");
                            }
        
                            output += " " + encodedTextString;
                            displayFlag = true;
                            clock.add(encodeTime + frameDifference);
                        } else {
                            if (clock.frameCount < incode.frameCount) {
                                clock = incode;
                            }
        
                            output +=
                                "\n\n" +
                                tcLib.formatTimecodeString(
                                    clock.toString(),
                                    options.dropFrame,
                                    timecodeOption
                                ) +
                                "\t" +
                                encodedTextString;
        
                            displayFlag = true;
                            clock.add(encodeTime);
                        }
                        /* Put the event up on screen end*/
                    }                    
                } else if (incode.frameCount <= clock.frameCount && incode.frameCount >= clock.frameCount - 2) {
                    frameDifference = Math.max(
                        0,
                        clock.frameCount - (incode.frameCount + 1)
                    );

                    for (let j = 0; j < frameDifference; j++) {
                        output +=
                            " " + sccFunc.getCodeByCmd(sccLookup[channel], "{FILLER}");
                    }

                    output += " " + encodedTextString;
                    displayFlag = true;
                    clock.add(encodeTime + frameDifference);
                } else {
                    if (clock.frameCount < incode.frameCount) {
                        clock = incode;
                    }

                    output +=
                        "\n\n" +
                        tcLib.formatTimecodeString(
                            clock.toString(),
                            options.dropFrame,
                            timecodeOption
                        ) +
                        "\t" +
                        encodedTextString;

                    displayFlag = true;
                    clock.add(encodeTime);
                }

                /* PaintOn */
            } else {
                if (displayFlag) {
                    outcodeOfPrevEvent = tcLib.createTc(
                        tcLib.secToTc(events[index - 1].end, options.frameRate),
                        options.frameRate,
                        options.dropFrame
                    );

                    if (outcodeOfPrevEvent.frameCount <= clock.frameCount && outcodeOfPrevEvent.frameCount >= clock.frameCount - 2) {
                        frameDifference = Math.max(
                            0,
                            clock.frameCount - (outcodeOfPrevEvent.frameCount + 1)
                        );
                        for (let j = 0; j < frameDifference; j++) {
                            output +=
                                " " + sccFunc.getCodeByCmd(sccLookup[channel], "{FILLER}");
                        }

                        output +=
                            " " + sccFunc.getCodeByCmd(sccLookup[channel], "{CLEAR DISPLAY}");

                        displayFlag = false;
                        clock.add(1 + frameDifference);
                    } else {
                        if (clock.frameCount < outcodeOfPrevEvent.frameCount) {
                            clock = outcodeOfPrevEvent;
                        } else if (clock.frameCount > outcodeOfPrevEvent.frameCount) {
                            //console.log("CLOCK IS AHEAD OF PREV EVENT");
                            //console.log(clock.toString(), outcodeOfPrevEvent.toString());
                        }

                        output +=
                            "\n\n" +
                            tcLib.formatTimecodeString(
                                clock.toString(),
                                options.dropFrame,
                                timecodeOption
                            ) +
                            "\t" +
                            sccFunc.getCodeByCmd(sccLookup[channel], "{CLEAR DISPLAY}");

                        displayFlag = false;
                        clock.add(1);
                    }
                }

                if (incode.frameCount <= clock.frameCount && incode.frameCount >= clock.frameCount - 2) {
                    frameDifference = Math.max(
                        0,
                        clock.frameCount - (incode.frameCount + 1)
                    );

                    for (let j = 0; j < frameDifference; j++) {
                        output +=
                            " " + sccFunc.getCodeByCmd(sccLookup[channel], "{FILLER}");
                    }

                    output += " " + encodedTextString;
                    displayFlag = true;
                    clock.add(encodeTime + frameDifference);
                } else {
                    if (clock.frameCount < incode.frameCount) {
                        clock = incode;
                    } else if (clock.frameCount > incode.frameCount) {
                        //console.log("CLOCK IS AHEAD OF INCODE EVENT");
                        //console.log(clock.toString(), incode.toString());
                    }

                    output +=
                        "\n\n" +
                        tcLib.formatTimecodeString(
                            clock.toString(),
                            options.dropFrame,
                            timecodeOption
                        ) +
                        "\t" +
                        encodedTextString;

                    displayFlag = true;
                    clock.add(encodeTime);
                }
            }
        });

        if (displayFlag) {
            outcodeOfPrevEvent = tcLib.createTc(
                tcLib.secToTc(eventGroup.events[eventGroup.events.length - 1].end, options.frameRate),
                options.frameRate,
                options.dropFrame
            );

            if (outcodeOfPrevEvent.frameCount <= clock.frameCount && outcodeOfPrevEvent.frameCount >= clock.frameCount - 2) {
                frameDifference = Math.max(
                    0,
                    clock.frameCount - (outcodeOfPrevEvent.frameCount + 1)
                );
                for (let j = 0; j < frameDifference; j++) {
                    output +=
                        " " + sccFunc.getCodeByCmd(sccLookup[channel], "{FILLER}");
                }

                output +=
                    " " + sccFunc.getCodeByCmd(sccLookup[channel], "{CLEAR DISPLAY}");

                displayFlag = false;
                clock.add(1 + frameDifference);
            } else {
                if (clock.frameCount < outcodeOfPrevEvent.frameCount) {
                    clock = outcodeOfPrevEvent;
                } else if (clock.frameCount > outcodeOfPrevEvent.frameCount) {
                    //console.log("CLOCK IS AHEAD OF PREV EVENT");
                    //console.log(clock.toString(), outcodeOfPrevEvent.toString());
                }
                output +=
                    "\n\n" +
                    tcLib.formatTimecodeString(
                        clock.toString(),
                        options.dropFrame,
                        timecodeOption
                    ) +
                    "\t" +
                    sccFunc.getCodeByCmd(sccLookup[channel], "{CLEAR DISPLAY}");
                output +=
                    " " + sccFunc.getCodeByCmd(sccLookup[channel], "{CLEAR DISPLAY}");
                output +=
                    " " + sccFunc.getCodeByCmd(sccLookup[channel], "{CLEAR BUFFER}");

                displayFlag = false;
                clock.add(3);
            }
        }

        if (encodingOptions["Line Endings"]) {
            if (encodingOptions["Line Endings"].toLowerCase() === "displays") {
                output = eol.crlf(output);
            } else if (
                encodingOptions["Line Endings"].toLowerCase() === "macintosh"
            ) {
                output = eol.cr(output);
            }
        }

        return output;
    },
    preProcess: {
        encode: function (eventGroup, options) {
            eventGroup.events.forEach((event, index, events) => {
                if (!sccFunc.verifyFormatting(event, options.window)) {
                    events[index].text = autoFormatSimple(event.text);
                }
            });

            return removeInvalidEvents(eventGroup);
        },

        decode: function (input) {
            return eol.lf(input.toLowerCase().replace(/ +/g, " ").trim());
        }
    },
    postProcess: {
        encode: function (output) {
            return output;
        },
        decode: function (eventGroup, options) {
            /* 
                We decoded the SCC using the 32x15 grid but now we need to center that grid over the window. There is a fixed offset of 30% (x) and 10% (y). This is due to the caption area being 70% of the frames width and 90% of the frames height. 
            */
            if (options.jobInfo.target_profile !== "scenerist" && options.jobInfo.target_profile !== "closedCaptionProject") {
                eventGroup = convertToPopOn(eventGroup, 2, 32);
            }
            
            eventGroup.events.forEach((event, index, events) => {                
                events[index].text = sccFunc.replaceMusicNotes(events[index].text);
                events[index].text = event.text.replace(/\s\s+/g, ' ')
                    .replace(/AÀ/g, "À")
                    .replace(/AÂ/g, "Â")
                    .replace(/aâ/g, "â")
                    .replace(/CÇ/g, "Ç")
                    .replace(/EÉ/g, "É")
                    .replace(/EÈ/g, "È")
                    .replace(/EÊ/g, "Ê")
                    .replace(/EË/g, "Ë")
                    .replace(/eë/g, "ë")
                    .replace(/IÎ/g, "Î")
                    .replace(/IÏ/g, "Ï")
                    .replace(/lï/g, "ï")
                    .replace(/OÔ/g, "Ô")
                    .replace(/UÙ/g, "Ù")
                    .replace(/uù/g, "ù")
                    .replace(/UÛ/g, "Û")
                    .replace(/AÃ/g, "Ã")
                    .replace(/aã/g, "ã")
                    .replace(/aâ/g, "â")
                    .replace(/IÍ/g, "Í")
                    .replace(/IÌ/g, "Ì")
                    .replace(/iï/g, "ï")
                    .replace(/iì/g, "ì")
                    .replace(/OÒ/g, "Ò")
                    .replace(/oò/g, "ò")
                    .replace(/OÕ/g, "Õ")
                    .replace(/oõ/g, "õ")
                    .replace(/AÄ/g, "Ä")
                    .replace(/aä/g, "ä")
                    .replace(/OÖ/g, "Ö")
                    .replace(/oö/g, "ö")
                    .replace(/AÅ/g, "Å")
                    .replace(/aå/g, "å")
                    .replace(/ÀÀ/g, "À")
                    .replace(/ÂÂ/g, "Â")
                    .replace(/ââ/g, "â")
                    .replace(/ÇÇ/g, "Ç")
                    .replace(/ÉÉ/g, "É")
                    .replace(/ÈÈ/g, "È")
                    .replace(/ÊÊ/g, "Ê")
                    .replace(/ËË/g, "Ë")
                    .replace(/ëë/g, "ë")
                    .replace(/êê/g, "ê")

                    .replace(/ôô/g, "ô")
                    .replace(/ÓÓ/g, "Ó")
                    .replace(/ÁÁ/g, "Á")
                    .replace(/ôô/g, "ô")

                    .replace(/ÎÎ/g, "Î")
                    .replace(/ÏÏ/g, "Ï")
                    .replace(/ïï/g, "ï")
                    .replace(/ÔÔ/g, "Ô")
                    .replace(/ÙÙ/g, "Ù")
                    .replace(/ùù/g, "ù")
                    .replace(/ÛÛ/g, "Û")
                    .replace(/ÃÃ/g, "Ã")
                    .replace(/ãã/g, "ã")
                    .replace(/ââ/g, "â")
                    .replace(/ÍÍ/g, "Í")
                    .replace(/ÌÌ/g, "Ì")
                    .replace(/ïï/g, "ï")
                    .replace(/ìì/g, "ì")
                    .replace(/ÒÒ/g, "Ò")
                    .replace(/òò/g, "ò")
                    .replace(/ÕÕ/g, "Õ")
                    .replace(/õõ/g, "õ")
                    .replace(/ÄÄ/g, "Ä")
                    .replace(/ää/g, "ä")
                    .replace(/ÖÖ/g, "Ö")
                    .replace(/öö/g, "ö")
                    .replace(/ÅÅ/g, "Å")
                    .replace(/åå/g, "å")
                    .replace(/¿¿/g, "¿")
                    .replace(/\?¿/g, "¿")
                    .replace(/'’/g, "'")
                    .replace(/íI/g, "I")
                    .replace(/<«/g, "«")
                    .replace(/>»/g, "»")
                    .replace(/éá/g, "é")
                    .replace(/á\*/g, "*á")
                    .replace(/\*á/g, "*");
            });

            return eventGroup;
        },
    },
};
