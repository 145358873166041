const orderByTime = require("../eventGroups/orderByTime.js");
const tcLib = require("../../lib/timecode.js");
module.exports = function conform(eventGroup, options){
    if (options.segments.length === 0){
        return eventGroup;
    }

    //console.log(JSON.stringify(eventGroup.events, null, 4));

    let filteredEvents = [], segmentGroups = [], prevEndTime  = 0, jobOffset = 0, minStartTime, maxStartTime, incodeOffset = 0;

    /* Determine the incode offset */
    if (options.incode === "auto"){
        eventGroup = orderByTime(eventGroup);
        incodeOffset = Math.round(eventGroup.events[0].start/3600) * 3600;
    } else if (options.incode){
        incodeOffset = tcLib.tcToSec(options.incode, options.frameRate);
    }

    options.segments.forEach(segment =>{
        if (segment.source.toLowerCase() === "file") {
            let segmentGroup = {
                posOffset: jobOffset,
                offset : 0,
                start : 0,
                end : 0,
                duration : 0,
                events: []
            };

            minStartTime = tcLib.tcToSec(segment.startTime, options.frameRate);
            maxStartTime = tcLib.tcToSec(segment.endTime, options.frameRate);
            segmentGroup.start = minStartTime;
            segmentGroup.end = maxStartTime;
            segmentGroup.duration = maxStartTime - minStartTime;

            if (segmentGroups.length > 0){
                segmentGroup.offset = segmentGroups[segmentGroups.length-1].duration + segmentGroups[segmentGroups.length-1].offset;
            }
            

            let copyEvents = JSON.parse(JSON.stringify(eventGroup.events));
            segmentGroup.events = copyEvents.filter(event => {   
                if ((event.start >= minStartTime || event.end > minStartTime) && event.start <= maxStartTime){
                    if (event.end > maxStartTime && event.start < maxStartTime){
                        event.end = maxStartTime;
                    } else if (event.start < minStartTime && event.end > minStartTime){
                        event.start = minStartTime;
                    }
                    
                    return true;
                }
            });

            segmentGroups.push(segmentGroup);

        } else if (segment.source.toLowerCase() === "black") {
            jobOffset += tcLib.tcToSec(segment.duration, options.frameRate);
        } else {
            console.log("Segment source ["+segment.source+"] not recognized. Ignoring segment");
        }
    });
    
    //console.log(JSON.stringify(segmentGroups, null, 4));
    segmentGroups.forEach(group=>{
        if (group.events.length === 0){
            return;
        }

        // console.log(JSON.stringify(group,null,4));
        // console.log("------------");
        // console.log("NEW GROUP", group.start, group.end, group.duration, group.offset, group.posOffset);
        group.events.forEach(function(event, i, events){
            events[i].start = (events[i].start-group.start) + group.offset + group.posOffset + incodeOffset;
            events[i].end = (events[i].end-group.start) + group.offset + group.posOffset + incodeOffset;
            
            // events[i].start -= group.negOffset;
            // events[i].end -= group.negOffset;
            // events[i].start -= incodeOffset;
            // events[i].end -= incodeOffset;
            
            events[i].start = parseFloat(events[i].start.toFixed(2)); 
            events[i].end = parseFloat(events[i].end.toFixed(2)); 
            filteredEvents.push(event);
        });
    });
    
    /* Remove duplicates and update the end time of the event */
    for (let count = filteredEvents.length-1; count > 0; count--){
        if (filteredEvents[count].id === filteredEvents[count-1].id){
            filteredEvents[count-1].end = filteredEvents[count].end;
            filteredEvents.splice(count,1);
        }
    }
    
    eventGroup.events = filteredEvents;
    //console.log(JSON.stringify(eventGroup.events, null, 4));
    return eventGroup;
}