const Event = require("../classes/event.js");
const tcLib = require("../lib/timecode.js");
const removeInvalidEvents = require("../functions/eventGroups/removeInvalidEvents.js");
const convertToHtml = require("../functions/quill/convertToHtml.js");
const convertToPlainText = require("../functions/quill/convertToPlainText.js");
const ttmlFunc = require("../functions/profiles/ttmlGeneral.js");
const eol = require("eol");

module.exports = {
    decode: function (input, options) {
        let events = [], ccEvent, tcFlag = false;
        const captionBlocks = input.split("\n\n");
        captionBlocks.forEach(captionBlock =>{
            eol.split(captionBlock).forEach(fileLine =>{
                let matches = fileLine.match(/\[\d{2}:\d{2}:\d{2}\.\d{2}\]/g);
                if (matches){
                    let tc = matches[0].replace(/\[|\]/g,"").replace(".", ":");
                    let tcSec = tcLib.tcToSec(tc, options.frameRate);
                    if (tcFlag){
                        ccEvent.end = tcSec;
                        ccEvent.text = convertToHtml(ccEvent.text);
                        events.push(ccEvent);
                        ccEvent = false;
                        tcFlag = false;
                    } else {
                        ccEvent = new Event({
                            xOffset : 0,
                            yOffset : options.window.height * -0.10
                        });
                        ccEvent.start = tcSec;
                        tcFlag = true;
                    }
                } else if (tcFlag) {
                    ccEvent.text += eol.after(fileLine);
                }
            });
        });

        return events;
    },

    encode: function (eventGroup, options) {
        let output = `{QTtext} {font:Tahoma}
{plain} {size:20}
{timeScale:${ttmlFunc.frameRateMap[options.frameRate]}}
{width:160} {height:32}
{timestamps:absolute} {language:0}
`;

        eventGroup.events.forEach(event =>{
            let start = tcLib.secToTc(event.start);
            let end = tcLib.secToTc(event.start);
            output += eol.after(`[${start.substring(0,8) + "." + start.substring(9)}]`);
            output += eol.after(`${convertToPlainText(event.text)}`);
            output += eol.after(`[${end.substring(0,8) + "." + end.substring(9)}]`);
            output += "\n";
        });

        return output;
    },

    preProcess: {
        encode: function (eventGroup) {
            return removeInvalidEvents(eventGroup);
        },

        decode: function (input) {
            return eol.lf(input).trim();
        }
    },

    postProcess: {
        encode: function (output) {
            return output;
        },

        decode: function (eventGroup) {
            return eventGroup;
        }
    },

}