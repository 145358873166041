const removeInvalidEvents = require("../functions/eventGroups/removeInvalidEvents.js");
module.exports = {
    decode : function(input, options){
        /* Input should be json stringified event group */
        return JSON.parse(input);
    },

    encode : function(eventGroup, options){
        return JSON.stringify(eventGroup, null, 4);
    },

    preProcess : {
        encode : function(eventGroup){
            return removeInvalidEvents(eventGroup);
        },

        decode : function(input){
            return input;
        }
    },

    postProcess : {
        encode : function(output){
            return output;
        },

        decode : function(eventGroup){
            return eventGroup;
        }
    },

}