<template>
<form id="JobInputForm" class="shadow p-3 rounded">
    <div id="fileInputDrop" @drop.prevent="addFiles" @dragover.prevent="changeMouseOverEffect">
        <p v-if="files.length ===0">Select Source File(s)</p>
        <p v-else>
            <strong>Selected File(s):</strong>
            {{fileNames}}
        </p>
        <input id="ccFileInput" type="file" multiple @change="addFiles" ref="ccFileInput" />
    </div>
    <p v-if="appEnv === 'web' && files.length>1" class="bg-danger p-1">
        <strong>WARNING:</strong> Batch processing is only available in the desktop version of
        <em>Closed Caption Converter</em>. Please download the desktop application from
        <a class="text-light" href="https://www.closedcaptionconverter.com/downloads.html" target="_BLANK">https://www.closedcaptionconverter.com/downloads.html</a>
    </p>
    <div class="form-group" v-if="files.length > 1">
        <div class="row">
            <div class="col-4">
                <label>Source Extension</label>
            </div>
            <div class="col">
                <label>Source Profiles</label>
            </div>
        </div>

        <div class="row" v-for="(ext, extIndex) in Object.keys(batchExtProfileMapping)" :key="extIndex">
            <div class="col-2">
                <h5><i>{{ext}}</i></h5>
            </div>
            <div class="col-2">
                <i class="fas fa-long-arrow-alt-right"></i>
            </div>
            <div class="col-6">
                <select class="custom-select" v-model="batchExtProfileMapping[ext]">
                    <option v-for="profile in srcProfiles[ext]" :key="srcProfiles[ext].indexOf(profile)">{{profile}}</option>
                </select>
            </div>
        </div>
    </div>
    <div class="form-group" v-if="extractFromSourceOption">
        <label>Source Profile</label>
        <select class="custom-select" v-model="updateSrcProfile">
            <option v-for="profile in allSrcProfiles" :key="allSrcProfiles.indexOf(profile)">{{profile}}</option>
        </select>
    </div>
    <div class="form-group" v-else-if="srcProfiles[srcExt] && srcProfiles[srcExt].length > 1">
        <label>Source Profile</label>
        <select class="custom-select" v-model="updateSrcProfile">
            <option v-for="profile in srcProfiles[srcExt]" :key="srcProfiles[srcExt].indexOf(profile)">{{profile}}</option>
        </select>
    </div>
    <div class="row">
        <div class="col">
            <div class="form-group">
                <label>Target Extension</label>
                <select class="custom-select" v-model="updateTrgExt">
                    <option v-for="ext in validTrgExt" :key="validTrgExt.indexOf(ext)">{{ext}}</option>
                </select>
            </div>
        </div>
        <div class="col">
            <div class="form-group">
                <label>Target Profile</label>
                <select class="custom-select" v-model="trgProfile">
                    <option v-for="profile in trgProfiles[trgExt]" :key="trgProfiles[trgExt].indexOf(profile)">{{profile}}</option>
                </select>
            </div>
        </div>
    </div>
    <div class="form-check pb-3" v-if="files.length === 1">
        <input class="form-check-input" type="checkbox" value id="extractFromSourceInput" v-model="updateExtractFromSourceOption" />
        <label class="form-check-label" for="extractFromSourceInput" title="Extract the contents from a wrapper file such as MP4 files containing text tracks.">Extract From Source</label>
    </div>
    <div class="form-check pb-3" v-if="srcProfile === 'Scenarist V1.0' && files.length === 1">
        <input class="form-check-input" type="checkbox" value id="sccDecode" v-model="updateSccDecode" />
        <label class="form-check-label" for="sccDecode">SCC Decode</label>
    </div>
    <div class="form-group animated fadeIn faster" v-if="files.length>1">
        <button type="button" class="btn btn-danger" @click="selectDir">
            <i class="far fa-folder"></i> Select Target Folder *
        </button>
        <span class="ml-2 text-muted">{{trgDir}}</span>
    </div>
    <button type="button" class="btn btn-success" @click="convert" :disabled="sccDecode||files.length===0||(files.length>1 && trgDir === '')">
        <i class="fas fa-retweet"></i> Convert
    </button>
    <button type="button" class="btn btn-outline-light ml-2" @click="clearProject">
        <i class="fas fa-snowplow"></i> Reset
    </button>
    <button type="button" class="btn btn-link text-warning ml-2" @click="advancedOptions=!advancedOptions">
        Advanced Options
        <i class="fas fa-caret-down" v-if="advancedOptions"></i>
        <i v-else class="fas fa-caret-right"></i>
    </button>
    <p class="form-text text-muted animated fadeIn faster" v-if="sccDecode">SCC Decode is enabled</p>

    <div id="advancedOptions" v-show="advancedOptions">
        <div class="row pt-4 mt-4 mb-2 border-top border-dark" v-if="srcProfileProperties.options && srcProfileProperties.options.decode.length > 0">
            <h5 class="col-12 lead text-warning">Decoding Options</h5>
            <div class="col-6" v-for="(decodingOption, decOptIndex) in srcProfileProperties.options.decode" :key="decOptIndex">
                <div class="form-group">
                    <label>{{decodingOption.name}}</label>
                    <!-- Text Area/Multi-Line -->
                    <textarea class="form-control" rows="3" v-model="decodingOption.selected" v-if="decodingOption.type==='textarea'"></textarea>

                    <!-- Text Input -->
                    <input class="form-control" v-else-if="decodingOption.type==='text-input'" type="text" v-model="decodingOption.selected" />

                    <!-- Number Input -->
                    <input class="form-control" v-else-if="decodingOption.type==='number-input'" type="number" v-model="decodingOption.selected" />

                    <!-- Dropdown List -->
                    <select class="custom-select" v-model="decodingOption.selected" v-else>
                        <option v-for="(value, decValIndex) in decodingOption.values" :key="decValIndex">{{value}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row pt-4 mt-4 mb-3 border-top border-dark" v-if="trgProfileProperties.options && trgProfileProperties.options.encode.length > 0">

            <h5 class="col-12 lead text-warning">Encoding Options</h5>

            <div class="col-6" v-for="(encodingOption, encOptIndex) in trgProfileProperties.options.encode" :key="encOptIndex">
                <div class="form-group">
                    <label>{{encodingOption.name}}</label>
                    <!-- Text Area/Multi-Line -->
                    <textarea class="form-control" rows="3" v-model="encodingOption.selected" v-if="encodingOption.type==='textarea'"></textarea>

                    <!-- Text Input -->
                    <input class="form-control" v-else-if="encodingOption.type==='text-input'" type="text" v-model="encodingOption.selected" />

                    <!-- Number Input -->
                    <input class="form-control" v-else-if="encodingOption.type==='number-input'" type="number" v-model="encodingOption.selected" />

                    <!-- Dropdown List -->
                    <select class="custom-select" v-model="encodingOption.selected" v-else>
                        <option v-for="(value, encValIndex) in encodingOption.values" :key="encValIndex">{{value}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row pt-4 mt-4 mb-3 border-top border-dark">
            <h5 class="col-12 lead text-warning">General Options</h5>
        </div>
        <div class="form-row" v-show="srcProfileProperties.timecode !== 'ms'">
            <div class="col-8">
                <label>Source Framerate</label>
                <select class="custom-select" v-model.number="updateSrcFrameRate">
                    <option v-for="frameRate in frameRates" :key="frameRates.indexOf(frameRate)">{{frameRate}}</option>
                </select>
            </div>
            <div class="col-4" v-if="srcFrameRate === 29.97 || srcFrameRate === 59.94">
                <label>Dropframe</label>
                <select class="custom-select" v-model="srcDropFrame">
                    <option>true</option>
                    <option>false</option>
                </select>
            </div>
        </div>

        <div class="form-row" v-show="trgProfileProperties.timecode !== 'ms'">
            <div class="col-8">
                <label>Target Framerate</label>
                <select class="custom-select" v-model.number="updateTrgFrameRate">
                    <option v-for="frameRate in frameRates" :key="frameRates.indexOf(frameRate)">{{frameRate}}</option>
                </select>
            </div>
            <div class="col-4" v-if="trgFrameRate === 29.97 || trgFrameRate === 59.94">
                <label>Dropframe</label>
                <select class="custom-select" v-model="trgDropFrame">
                    <option>true</option>
                    <option>false</option>
                </select>
            </div>
        </div>
        <div class="form-row">
            <div class="col-8">
                <label>TC Offset</label>
                <div>
                    <input type="text" class="form-control" v-model.lazy="setOffset" v-on:focus="selectAll($event)" :class="{'bg-light' : automaticOffset}" :disabled="automaticOffset" />
                </div>
            </div>
            <div class="col-4">
                <label>Offset Type</label>
                <select class="custom-select" v-model="offsetType" :class="{'bg-light' : automaticOffset}" :disabled="automaticOffset">
                    <option>add</option>
                    <option>subtract</option>
                </select>
            </div>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="checkbox" value id="automaticOffset" v-model="automaticOffset" />
            <label class="form-check-label" for="automaticOffset">
                Automatic Offset
                <i class="fas fa-info-circle" title="Attempts to offset timecode to 00:00:00:00 by using the incode of the first event. e.g. If your first caption event is at 10:00:04:15 - Automatic Offset will offset all timecodes by 10 hours."></i>
            </label>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="checkbox" value id="dropFrameConvert" v-model="dropFrameConvert" />
            <label class="form-check-label" for="dropFrameConvert">
                Drop Frame Convert
                <i class="fas fa-info-circle" title="This option is valid when converting from a format that uses SMPTE timecode (e.g. SCC, MCC, etc.) to an offset timecode format (e.g. SRT, WebVTT, etc.)"></i>
            </label>
        </div>
        <div class="form-group mt-2">
            <label>File Encoding (Source)</label>
            <select class="custom-select" v-model="updateSrcFileEncoding">
                <option v-for="encoding in fileEncodings" :key="fileEncodings.indexOf(encoding)">{{encoding}}</option>
            </select>
        </div>
        <div class="form-group mt-2">
            <label>File Encoding (Target)</label>
            <select class="custom-select" v-model="trgFileEncoding">
                <option v-for="encoding in fileEncodings" :key="fileEncodings.indexOf(encoding)">{{encoding}}</option>
            </select>
        </div>
        <div class="form-group mt-2">
            <label>Target Line Ending (EOL)</label>
            <select class="custom-select" v-model="trgEol">
                <option>auto</option>
                <option>windows</option>
                <option>macintosh</option>
                <option>unix</option>
            </select>
        </div>
        <div class="row pt-4 mt-4 mb-3 border-top border-dark">
            <h5 class="col-12 lead text-warning">Conform and Segmentation <i class="fas fa-info-circle" title="Segment/conform your closed caption file to generate a new file matching edit metadata. Specifying a black source will insert delay in your output caption file."></i></h5>
        </div>
        <div class="form-group">
            <label>Video Incode <i class="fas fa-info-circle" title="The timecode of the first frame of video used to calculate the initial offset for all segments."></i></label>
            <!-- Text Input -->
            <input class="form-control" type="text" v-model="incode" placeholder="Accepted values: 'auto', smpte timcode (e.g. 01:00:00:00)"/>
        </div>
        <div class="btn-group float-right" role="group">
            <button type="button" class="btn btn-success" :disabled="segments.length === 0" @click="saveSegments" title="Save Segment List"><i class="far fa-save"></i> Save</button>
            <button type="button" class="btn btn-primary" @click="selectSegmentFile" title="Load Segment List"><i class="fas fa-file-download"></i> Load</button>
            <input type='file' id="loadSegmentInput" accept="application/JSON" @change="loadSegments" hidden />
        </div>

        <div class="btn-group mb-3 mt-1" role="group">
            <button type="button" class="btn btn-success btn-sm" @click="createSegment" title="Create Segment">
                <i class="fas fa-plus"></i>
            </button>
            <button type="button" class="btn btn-secondary btn-sm" @click="deleteSegment" title="Delete Segment">
                <i class="fas fa-minus"></i>
            </button>
        </div>
        <div id="segmentationWrapper" v-if="segments.length>0">
            <div id="segmentationLabels" class="form-row">
                <div class="col-3">
                    <label>Incode</label>
                </div>
                <div class="col-3">
                    <label>Outcode</label>
                </div>
                <div class="col-3">
                    <label>Duration</label>
                </div>
                <div class="col">
                    <label>Source</label>
                </div>
            </div>
            <div class="form-row animated fadeIn faster" v-for="segment in segments" :key="segment.id">
                <div class="col-3">
                    <input type="text" class="form-control" v-model.lazy="segment.startTime" v-on:blur="formatTc($event)" v-on:focus="selectAll($event)" :id="segment.id + '_startTime'" :disabled="segment.source==='Black'" :class="{'bg-light' : segment.source==='Black'}" />
                </div>
                <div class="col-3">
                    <input type="text" class="form-control" v-model.lazy="segment.endTime" v-on:blur="formatTc($event)" v-on:focus="selectAll($event)" :id="segment.id + '_endTime'" :class="{'bg-light' : segment.source==='Black'}" :disabled="segment.source==='Black'" />
                </div>
                <div class="col-3">
                    <input type="text" class="form-control" v-model.lazy="segment.duration" v-on:blur="formatTc($event)" v-on:focus="selectAll($event)" :id="segment.id + '_duration'" :class="{'bg-light' : segment.source==='File'}" :disabled="segment.source==='File'" />
                </div>
                <div class="col">
                    <select class="custom-select" v-model="segment.source">
                        <option>File</option>
                        <option>Black</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row pt-4 mt-4 mb-3 border-top border-dark">
            <h5 class="col-12 lead text-warning">Minimum Frame Gap</h5>
        </div>
        <div class="form-group">
            <label for="blankFrames">Insert Blank Frames</label>
            <input type="number" step="0" min="0" class="form-control" id="blankFrames" v-model="blankFrames">
        </div>
        <div class="row pt-4 mt-4 mb-3 border-top border-dark">
            <h5 class="col-12 lead text-warning">Stretch/Shrink Time Code</h5>
        </div>
        <div class="form-group">
            <label for="StretchTc">Multiplier <i class="fa fa-info-circle" title="Correct for caption drift using a custom multiplier. Common values include 1.001 when converting frame rates from 23.976 to 24, 29.97 to 30, or 59.94 to 60. Use 0.999 when converting frame rates from 24 to 23.976, 30 to 29.97, 60 to 59.94."></i></label>
            <input type="number" class="form-control" id="tcMultiplier" v-model="tcMultiplier">
        </div>
        <div class="row pt-4 mt-4 mb-3 border-top border-dark">
            <h5 class="col-12 lead text-warning">Auto Format</h5>
        </div>
        <div class="form-check mb-2">
            <input class="form-check-input" type="checkbox" id="autoFormat" v-model="autoFormat.enable" />
            <label class="form-check-label" for="autoFormat">
                Enable Automatic Format
            </label>
        </div>
        <div class="form-group">
            <label for="maxLinesRange">Max Lines Per Event</label>
            <input type="range" class="custom-range" :disabled="!autoFormat.enable" min="1" max="5" step="1" id="maxLinesRange" v-model="autoFormat.maxLines">
            {{autoFormat.maxLines}}
        </div>
        <div class="form-group">
            <label for="maxCharsRange">Max Characters Per Line</label>
            <input type="range" class="custom-range" :disabled="!autoFormat.enable" min="1" max="50" step="1" id="maxCharsRange" v-model="autoFormat.maxChars">
            {{autoFormat.maxChars}}
        </div>
    </div>
</form>
</template>

<script>
import uniqueId from "lodash/uniqueId";
import defaults from "@/external/cc-lib/lib/defaults.js";
import encodings from "@/external/cc-lib/dict/encodings.js";
import formats from "@/external/cc-lib/lib/formats.js";
import frameRates from "@/external/cc-lib/dict/frameRates.js";
import timecode from "@/external/cc-lib/lib/timecode.js";
import sccDecode from "@/external/cc-lib/functions/special/sccDecode.js";
import extractFromSourceWeb from '@/external/cc-lib/functions/special/extractFromSourceWeb.js';
const fileSaver = require('file-saver');
const mammoth = require("mammoth");

export default {
    name: "JobInputForm",
    data() {
        return {
            validSrcExt: formats.srcExt,
            validTrgExt: formats.trgExt,
            srcProfiles: formats.srcProfiles,
            trgProfiles: formats.trgProfiles,
            advancedOptions: false,
            dropFrameConvert: false,
            frameRates: frameRates.frameRates,
            srcFrameRate: defaults.frameRate,
            srcDropFrame: defaults.dropFrame,
            trgFrameRate: defaults.frameRate,
            trgDropFrame: defaults.dropFrame,
            trgExt: defaults.trgExt,
            trgProfile: formats.trgProfiles[defaults.trgExt][0],
            srcExt: "",
            srcProfile: "",
            allSrcProfiles : formats.getSrcProfileDisplayNames(),
            allTrgProfiles : formats.getTrgProfileDisplayNames(),
            incode : "00:00:00:00",
            blankFrames : 0,
            offset: "00:00:00:00",
            offsetType: "add",
            automaticOffset: false,
            sccDecode: false,
            extractFromSourceOption : false,
            segments: [],
            files: [],
            batchExtProfileMapping: {},
            trgDir: "",
            srcFileEncoding: defaults.encoding,
            trgFileEncoding: defaults.encoding,
            trgEol : 'auto',
            fileEncodings: encodings,
            autoFormat: {
                enable: false,
                maxLines: 2,
                maxChars: 28
            },
            tcMultiplier: 1
        };
    },
    computed: {
        appEnv: function () {
            return process.env.VUE_APP_ENV;
        },
        fileNames: function () {
            return this.files.map(file => file.name).join(", ");
        },
        trgProfileProperties: function () {
            if (this.trgProfile) {
                return formats.profileMapping[this.trgProfile];
            } else {
                return [];
            }
        },
        srcProfileProperties: function () {
            if (this.srcProfile) {
                return formats.profileMapping[this.srcProfile];
            } else {
                return [];
            }
        },
        updateTrgExt: {
            get() {
                return this.trgExt;
            },
            set(trgExt) {
                this.trgExt = trgExt;
                this.trgProfile = formats.trgProfiles[trgExt][0];
            }
        },
        updateSrcFileEncoding: {
            get() {
                return this.srcFileEncoding
            },
            set(encoding) {
                this.srcFileEncoding = encoding;
                this.updateSrcPreview();
            }
        },
        updateSrcProfile: {
            get() {
                return this.srcProfile;
            },
            set(profile) {
                this.srcProfile = profile;
                this.srcFileEncoding = formats.defaultEncodingMap[formats.profileMapping[profile].name].toUpperCase();
                this.updateSrcPreview();
            }
        },
        updateExtractFromSourceOption : {
            get() {
                return this.extractFromSourceOption;
            },
            set(extract){
                this.extractFromSourceOption = extract;

                if (extract){
                    this.srcProfile = this.allSrcProfiles[0];
                } else {
                    this.srcProfile = this.srcProfiles[this.srcExt];
                }
                
                this.updateSrcPreview();
            }
        },
        updateSccDecode: {
            get() {
                return this.sccDecode;
            },
            set(decodeScc) {
                this.sccDecode = decodeScc;
                if (decodeScc) {
                    this.$notify({
                        title: "Please Wait",
                        text: "Decoding SCC..."
                    });

                    this.$store.commit("updateTrgPreview", {
                        text: sccDecode(this.$store.state.srcPreview),
                        ext: "",
                        profile: ""
                    });
                } else {
                    this.$store.commit("updateTrgPreview", {
                        text: "",
                        ext: "",
                        profile: ""
                    });
                }
            }
        },
        updateSrcFrameRate: {
            get() {
                return this.srcFrameRate;
            },
            set(fr) {
                this.srcDropFrame = frameRates.dropFrameMap[fr];
                this.srcFrameRate = fr;

                this.trgDropFrame = frameRates.dropFrameMap[fr] || "false";
                this.trgFrameRate = fr;
            }
        },
        updateTrgFrameRate: {
            get() {
                return this.trgFrameRate;
            },
            set(fr) {
                this.trgDropFrame = frameRates.dropFrameMap[fr] || "false";
                this.trgFrameRate = fr;
            }
        },
        setOffset: {
            get() {
                return this.offset;
            },
            set(tc) {
                this.offset = timecode.tcValidate(
                    tc,
                    this.srcFrameRate,
                    this.srcDropFrame
                );
            }
        }
    },
    methods: {
        saveSegments: function () {
            if (this.segments.length > 0) {
                let segmentJson = new Blob([JSON.stringify(this.segments)], {
                    type: "text/plain;charset=utf-8"
                });
                
                fileSaver.saveAs(segmentJson, "segments.json");
            }
        },
        selectSegmentFile: function () {
            document.getElementById('loadSegmentInput').click();
        },
        loadSegments: function (e) {
            let self = this;
            var reader = new FileReader();
            reader.onload = function () {
                self.segments = JSON.parse(reader.result);
            }
            reader.readAsText(e.target.files[0]);
        },
        selectDir: function () {
            const {
                remote
            } = require('electron');
            remote.dialog.showOpenDialog({
                    properties: ["openDirectory"]
                })
                .then(path => {
                    if (!path.canceled) {
                        this.trgDir = path.filePaths[0];
                    }
                });
        },
        selectAll: function (event) {
            setTimeout(function () {
                event.target.select();
            }, 0);
        },
        formatTc: function (event) {
            var self = this;
            var id = event.target.id;
            var tc = event.target.value;
            var segmentId = id.split("_")[0];
            var tc = id.split("_")[1];
            this.segments.filter(segment => {
                if (segment.id == segmentId) {
                    segment[tc] = timecode.tcValidate(
                        segment[tc],
                        self.srcFrameRate,
                        self.srcDropFrame
                    );
                }
            });
        },
        createSegment: function () {
            this.segments.push({
                id: uniqueId(),
                startTime: "00:00:00:00",
                endTime: "00:00:00:00",
                duration: "00:00:00:00",
                source: "File"
            });
        },
        deleteSegment: function (index) {
            this.segments.pop();
        },
        updateTrgDir: function (event) {
            this.trgDir = event.target.files[0].path;
        },
        addFiles: function (e) {
            if (e.type === "change") {
                /* The file was selected from the input */
                if (this.$refs.ccFileInput.files.length === 0) return;
                var droppedFiles = this.$refs.ccFileInput.files;
            } else if (e.type === "drop") {
                /* The file was selected using drag-n-drop */
                var droppedFiles = e.dataTransfer.files;
                if (!droppedFiles) return;
            } else {
                return;
            }

            this.$store.commit("resetState");
            this.$store.commit("updateSrcPreview", {
                text: "Updating Preview...",
                srcExt: "",
                fileName: ""
            });

            this.files = [];
            this.batchExtProfileMapping = {};
            this.srcProfile = "";
            this.trgDir = "";
            this.srcExt = "";
            this.sccDecode = false;
            this.dropFrameConvert = false;
            this.extractFromSourceOption = false;
            this.incode = "00:00:00:00";
            this.blankFrames = 0;
            this.trgEol = 'auto';
            this.offset = "00:00:00:00";
            this.offsetType = "add";
            this.automaticOffset = false;
            this.segments = [];
            this.autoFormat = {
                enable: false,
                maxLines: 2,
                maxChars: 28
            };

            [...droppedFiles].forEach(f => {
                var ext = f.name.split(".").pop().toLowerCase();
                this.files.push(f);
                if (formats.srcProfiles[ext]){
                    this.batchExtProfileMapping[ext] = formats.srcProfiles[ext][0]
                }                
            });

            this.$notify({
                title: "Please Wait",
                text: "Reading source files..."
            });

            if (this.files.length === 1) {
                var reader = new FileReader();
                var fileName = this.files[0].name;
                this.srcExt = this.files[0].name.split(".").pop().toLowerCase();
                
                if (this.validSrcExt.indexOf(this.srcExt) === -1) {
                    this.$notify({
                        title: "Source File Error",
                        text: "Source file with extension [" +
                            this.srcExt +
                            "] is unknown",
                        type: "warn" /* warn, error, success */
                    });

                    //this.$store.commit("resetState");
                    console.log("Source file with extension [" + this.srcExt + "] is not supported");
                    return;
                }

                this.srcProfile = formats.srcProfiles[this.srcExt][0];                
                var self = this;

                reader.onload = async function (e) {
                    if (self.srcExt.toLowerCase() === "docx"){
                        var srcPreview = await mammoth.extractRawText({arrayBuffer: e.target.result});
                        srcPreview = srcPreview.value;
                    } else if (
                        formats.binaryFormats.indexOf(
                            formats.profileMapping[self.srcProfile].name
                        ) > -1
                    ) {
                        var srcPreview = new Buffer(e.target.result).toString("hex");
                    } else {
                        var srcPreview = e.target.result;
                    }

                    self.$store.commit("updateSrcPreview", {
                        fileContents: srcPreview,
                        ext: self.srcExt,
                        fileName: fileName.split(".").shift()
                    });
                };

                if (
                    formats.binaryFormats.indexOf(
                        formats.profileMapping[self.srcProfile].name
                    ) > -1 || this.srcExt.toLowerCase() === "docx"
                ) {
                    reader.readAsArrayBuffer(this.files[0]);
                } else {
                    reader.readAsText(this.files[0], self.fileEncoding);
                }
            } else {
                var fileNameList = [];
                this.files.forEach(file => {
                    fileNameList.push(file.name);
                });
                this.$store.commit("updateMultiFile", true);
                this.$store.commit("updateSrcPreview", {
                    fileContents: "BATCH JOB: " + fileNameList.join(", "),
                    srcExt: "",
                    fileName: ""
                });
            }
        },
        changeMouseOverEffect: function (e) {
            e.dataTransfer.dropEffect = "copy";
        },
        clearProject: function () {
            this.files = [];
            this.batchExtProfileMapping = {};
            this.srcProfile = "";
            this.trgDir = "";
            this.srcExt = "";
            this.sccDecode = false;
            this.dropFrameConvert = false;
            this.extractFromSourceOption = false;
            this.incode = "00:00:00:00";
            this.blankFrames = 0;
            this.trgEol = 'auto';
            this.offset = "00:00:00:00";
            this.offsetType = "add";
            this.automaticOffset = false;
            this.segments = [];
            this.tcMultiplier = 1;
            this.autoFormat = {
                enable: false,
                maxLines: 2,
                maxChars: 28
            }
            this.$store.commit("resetState");
            document.getElementById("ccFileInput").value = "";
        },
        convert: function () {
            let srcDropFrame, trgDropFrame;
            if (this.srcDropFrame && this.srcDropFrame !== "false") {
                srcDropFrame = true;
            } else {
                srcDropFrame = false;
            }

            if (this.trgDropFrame && this.trgDropFrame !== "false") {
                trgDropFrame = true;
            } else {
                trgDropFrame = false;
            }

            if (this.files.length === 1) {
                this.$notify({
                    title: "Please Wait",
                    text: "Converting caption file...",
                    type: "warn" /* warn, error, success */
                });

                this.$store.commit("convert", {
                    fileName: this.$store.state.fileName,
                    fileContents: this.$store.state.srcPreview,
                    srcFrameRate: this.srcFrameRate,
                    srcDropFrame: srcDropFrame,
                    trgFrameRate: this.trgFrameRate,
                    trgDropFrame: trgDropFrame,
                    trgExt: this.trgExt,
                    srcExt: this.srcExt,
                    trgProfile: this.trgProfile,
                    srcProfile: this.srcProfile,
                    decodeOptions: this.srcProfileProperties.options.decode,
                    encodeOptions: this.trgProfileProperties.options.encode,
                    incode : this.incode,
                    blankFrames : this.blankFrames,
                    offset: this.offset,
                    offsetType: this.offsetType,
                    automaticOffset: this.automaticOffset,
                    segments: this.segments,
                    trgDir: this.trgDir,
                    fileEncoding: this.fileEncoding,
                    dropFrameConvert: this.dropFrameConvert,
                    srcFileEncoding: this.srcFileEncoding,
                    trgFileEncoding: this.trgFileEncoding,
                    eol : this.trgEol,
                    autoFormat: this.autoFormat,
                    extractFromSource : this.extractFromSourceOption,
                    extractFromSourceProfile : "mp4",
                    tcMultiplier: parseFloat(this.tcMultiplier)
                });
            } else if (this.files.length > 1) {
                this.$notify({
                    title: "Please Wait",
                    text: "Submitting batch convert job...",
                    type: "warn" /* warn, error, success */
                });

                var self = this;
                this.files.forEach(file => {
                    var reader = new FileReader();
                    try {
                        var fileName = file.name;
                        var srcExt = file.name.split(".").pop().toLowerCase();
                        if (self.validSrcExt.indexOf(srcExt) === -1) {
                            self.$notify({
                                title: "Source File Error",
                                text: "Source file with extension [" +
                                    srcExt +
                                    "] is not supported",
                                type: "error" /* warn, error, success */
                            });

                            throw new Error(
                                "Source file with extension [" + srcExt + "] is not supported"
                            );
                        }

                        reader.onload = async function (e) {
                            if (srcExt.toLowerCase() === "docx"){
                                var fileContents = await mammoth.extractRawText({arrayBuffer: e.target.result});

                                fileContents = fileContents.value;

                            } else if (["stl", "cap", "pac"].indexOf(srcExt) > -1) {
                                var fileContents = new Buffer(e.target.result).toString("hex");
                            } else {
                                var fileContents = e.target.result;
                            }

                            self.$store.commit("convert", {
                                fileName: fileName.split(".").shift(),
                                fileContents: fileContents,
                                srcFrameRate: self.srcFrameRate,
                                srcDropFrame: srcDropFrame,
                                trgFrameRate: self.trgFrameRate,
                                trgDropFrame: trgDropFrame,
                                trgExt: self.trgExt,
                                srcExt: srcExt,
                                trgProfile: self.trgProfile,
                                srcProfile: self.batchExtProfileMapping[srcExt],
                                decodeOptions: [],
                                encodeOptions: self.trgProfileProperties.options.encode,
                                offset: self.offset,
                                offsetType: self.offsetType,
                                automaticOffset: self.automaticOffset,
                                segments: self.segments,
                                trgDir: self.trgDir,
                                fileEncoding: self.fileEncoding,
                                eol : self.trgEol,
                                dropFrameConvert: self.dropFrameConvert,
                                autoFormat: self.autoFormat,
                                tcMultiplier: parseFloat(self.tcMultiplier)
                            });
                        };

                        if (["stl", "cap", "pac"].indexOf(srcExt) > -1 || srcExt.toLowerCase() === "docx") {
                            reader.readAsArrayBuffer(file);
                        } else {
                            reader.readAsText(file, self.fileEncoding);
                        }
                    } catch (e) {
                        console.log(e);
                        console.log(e.message);
                        self.$notify({
                            title: "Conversion Error",
                            text: "Failed to convert [" + fileName + "]",
                            type: "error" /* warn, error, success */
                        });
                    }
                });
            }
        },

        updateSrcPreview: function () {
            this.$store.commit("updateSrcPreview", {
                text: "Updating Preview...",
                srcExt: "",
                fileName: ""
            });
            
            this.sccDecode = false;
            this.dropFrameConvert = false;
            this.$notify({
                title: "Please Wait",
                text: "Reading source files..."
            });

            if (this.files.length === 1) {
                var reader = new FileReader();
                var fileName = this.files[0].name;
                this.srcExt = this.files[0].name
                    .split(".")
                    .pop()
                    .toLowerCase();
                if (!this.extractFromSourceOption && this.validSrcExt.indexOf(this.srcExt) === -1) {
                    this.$notify({
                        title: "Source File Error",
                        text: "Source file with extension [" +
                            this.srcExt +
                            "] is not supported",
                        type: "error" /* warn, error, success */
                    });

                    this.$store.commit("resetState");
                    throw new Error(
                        "Source file with extension [" + this.srcExt + "] is not supported"
                    );
                }

                var self = this;

                reader.onload = function (e) {
                    if (self.extractFromSourceOption && self.srcProfile){
                        var srcPreview = extractFromSourceWeb("mp4", e.target.result);
                    } else if (
                        formats.binaryFormats.indexOf(
                            formats.profileMapping[self.srcProfile].name
                        ) > -1
                    ) {
                        var srcPreview = new Buffer(e.target.result).toString("hex");
                    } else {
                        var srcPreview = e.target.result;
                    }

                    self.$store.commit("updateSrcPreview", {
                        fileContents: srcPreview,
                        ext: self.srcExt,
                        fileName: fileName.split(".").shift()
                    });
                };

                if (
                    formats.binaryFormats.indexOf(
                        formats.profileMapping[self.srcProfile].name
                    ) > -1
                ) {
                    reader.readAsArrayBuffer(this.files[0]);
                } else {
                    reader.readAsText(this.files[0], self.srcFileEncoding);
                }
            } else {
                var fileNameList = [];
                this.files.forEach(file => {
                    fileNameList.push(file.name);
                });

                this.$store.commit("updateMultiFile", true);
                this.$store.commit("updateSrcPreview", {
                    text: "BATCH JOB: " + fileNameList.join(", "),
                    srcExt: "",
                    fileName: ""
                });
            }
        },
    }
};
</script>

<style>
#JobInputForm {
    overflow-y: auto;
    max-height: 85vh;
    ;
    margin: 1%;
    border: 1px solid rgb(20, 20, 20);
    background-color: rgb(26, 26, 26);
}

#fileInputDrop {
    width: 100%;
    height: 100px;
    border: 1px dashed rgb(184, 184, 184);
    background-color: rgb(46, 46, 46);
    margin-bottom: 2%;
    position: relative;
    overflow: hidden;
}

#fileInputDrop p {
    position: relative;
    top: 40%;
    text-align: center;
}

#advancedOptions {
    margin-top: 2%;
    position: relative;
}

.form-row {
    padding-bottom: 2%;
}

h6 {
    padding-top: 2%;
}

#segmentationWrapper {
    border: 1px solid #fff;
    padding: 3%;
}

#ccFileInput {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
}
</style>
