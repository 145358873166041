const tcLib = require("../lib/timecode.js");
const removeInvalidEvents = require("../functions/eventGroups/removeInvalidEvents.js");
const getFormatOptions = require("../functions/helpers/getFormatOptions.js");
const convertToPlainText = require("../functions/quill/convertToPlainText.js");
const convertToPlainTextCustom = require("../functions/quill/convertToPlainTextCustom.js");
const eol = require("eol");
const xmlFormat = require('xml-formatter'); //Encode
const xmlToJson = require('fast-xml-parser'); //Decode
const ttmlFunc = require("../functions/profiles/ttmlGeneral.js");
const htmlEncodePlainText = require("../functions/utility/htmlEncodePlainText.js");

module.exports = {
    decode: function (input, options) {
        let events = [], regions = [], region;
        let fileJson = xmlToJson.parse(input, {
            ignoreAttributes: false,
            stopNodes: ["p"]
        });

        //console.log(JSON.stringify(fileJson, null, 4));

        /* Put the region data into an array */
        if (fileJson.tt.head.layout && fileJson.tt.head.layout.region) {
            if (Array.isArray(fileJson.tt.head.layout.region)) {
                regions = fileJson.tt.head.layout.region;
            } else {
                regions = [fileJson.tt.head.layout.region];
            }
        }

        if (Array.isArray(fileJson.tt.body.div)) {
            fileJson.tt.body.div.forEach(paragraphGroup => {
                if (Array.isArray(paragraphGroup.p)) {
                    paragraphGroup.p.forEach(paragraph => {
                        if (paragraph["@_region"]) {
                            region = regions.find(regionEl => {
                                return regionEl["@_xml:id"] === paragraph["@_region"]
                            });
                        } else {
                            region = false;
                        }
                        
                        events.push(ttmlFunc.multiLine.decodeSubtitle(paragraph, region, options.frameRate, options.window));
                    });
                } else {
                    if (paragraphGroup.p["@_region"]) {
                        region = regions.find(regionEl => {
                            return regionEl["@_xml:id"] === paragraphGroup.p["@_region"]
                        });
                    } else {
                        region = false;
                    }

                    events.push(ttmlFunc.multiLine.decodeSubtitle(paragraphGroup.p, region, options.frameRate, options.window));
                }
            });
        } else {
            fileJson.tt.body.div.p.forEach(paragraph => {
                if (paragraph["@_region"]) {
                    region = regions.find(regionEl => {
                        return regionEl["@_xml:id"] === paragraph["@_region"]
                    });
                } else {
                    region = false;
                }
                events.push(ttmlFunc.multiLine.decodeSubtitle(paragraph, region, options.frameRate, options.window));
            });
        }

        return events;
    },

    encode: function (eventGroup, options) {
        let encodingOptions = getFormatOptions(options.formatOptions),            
            version = encodingOptions["Version"] || "1.0",
            language = encodingOptions["Language Code"] || "ja",
            aspectRatio = encodingOptions["Language Code"] || "4:3",
            programName = encodingOptions["Program Name"] || "Untitled",
            programDescription = encodingOptions["Program Description"] || "Created by CC Converter 3",
            fontFamily = encodingOptions["Font Family"] || "monospaceSerif",
            fontSize = encodingOptions["Font Size"] || "80",
            lineHeight = encodingOptions["Line Height"] || "100",
            fontWeight = encodingOptions["Font Weight"] || "normal",
            fontStyle = encodingOptions["Font Style"] || "normal",
            fontColor = encodingOptions["Font Color"] || "white",
            bgColor = encodingOptions["Background Color"] || "black",
            tcFormat = encodingOptions["Timecode Format"] || "smpte",
            regions = "",
            paragraphs = "";

        let output = eol.after(`<?xml version="1.0" encoding="utf-8"?>`);
        output += eol.after(`<tt xmlns:ttm="http://www.w3.org/ns/ttml#metadata" xmlns:tts="http://www.w3.org/ns/ttml#styling" xmlns:ttp="http://www.w3.org/ns/ttml#parameter" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xml:lang="${language}" ttp:contentProfiles="http://www.w3.org/ns/ttml/profile/imsc1.1/text" xmlns:ebutts="urn:ebu:tt:style" xmlns:itts="http://www.w3.org/ns/ttml/profile/imsc1#styling" xmlns:ittp="http://www.w3.org/ns/ttml/profile/imsc1#parameter" xmlns:ittm="http://www.w3.org/ns/ttml/profile/imsc1#metadata" ttp:frameRate="${ttmlFunc.frameRateMap[options.frameRate]}" ttp:frameRateMultiplier="${ttmlFunc.frameRateMultiplierMap[options.frameRate]}" ttp:timeBase="media" xmlns="http://www.w3.org/ns/ttml">`);
        output += eol.after(`<head>`);
        output += eol.after(`<metadata>
    <ttm:title>${programName}</ttm:title>
    <ttm:desc>${programDescription}</ttm:desc>
</metadata>`);
        output += eol.after(`<styling>
    <initial xml:id="initialStyles" tts:color="${fontColor}" tts:fontFamily="${fontFamily}" tts:fontSize="${fontSize}%" tts:textOutline="black 4%" />
    <style tts:shear="16.67%" xml:id="italic" />
    <style tts:textEmphasis="filled dot outside" xml:id="bouten-filled-dot-outside" />
    <style tts:textCombine="all" xml:id="horizontalDigit" />
    <style tts:ruby="base" xml:id="ruby-base" />
    <style tts:ruby="text" xml:id="ruby-text" />
    <style tts:ruby="container" tts:rubyPosition="outside" tts:rubyAlign="center" xml:id="ruby-outside-container" />
</styling>`);
        output += eol.after(`<layout>
    <region ebutts:multiRowAlign="start" tts:displayAlign="after" tts:extent="80.000% 80.000%" tts:origin="10.000% 10.000%" tts:textAlign="center" tts:luminanceGain="3.0" xml:id="bottom-left-justified" />
    <region tts:displayAlign="before" tts:extent="80.000% 80.000%" tts:origin="10.000% 10.000%" tts:textAlign="center" tts:luminanceGain="3.0" xml:id="top-center-justified" />
    <region tts:displayAlign="before" tts:extent="80.000% 80.000%" tts:origin="10.000% 10.000%" tts:textAlign="start" tts:writingMode="tbrl" tts:luminanceGain="3.0" xml:id="right" />
    <region tts:displayAlign="after" tts:extent="80.000% 80.000%" tts:origin="10.000% 10.000%" tts:textAlign="start" tts:writingMode="tbrl" tts:luminanceGain="3.0" xml:id="left" />
    <region ebutts:multiRowAlign="end" tts:displayAlign="before" tts:extent="80.000% 80.000%" tts:origin="10.000% 10.000%" tts:textAlign="center" tts:shear="16.6667%" tts:luminanceGain="3.0" xml:id="force-narrative-example-region" />
</layout>`)
        output += eol.after(`</head>`);
        output += eol.after(`<body>`);
        output += eol.after(`<div>`);
        eventGroup.events.forEach(event =>{
            try {
                let start = tcFormat === "smpte" ? tcLib.secToTc(event.start, options.frameRate) : tcLib.secToTcMs(event.start).replace(",", ".");
                let end = tcFormat === "smpte" ? tcLib.secToTc(event.end, options.frameRate) : tcLib.secToTcMs(event.end).replace(",", ".");
                let region = "bottom-left-justified";
                if (event.yPos === 'start'){
                    region = event.xPos === 'start' ? 'left' : 'right'
                    region = event.xPos === "center" ? 'top-center-justified' : region;
                }

                event.text.split("</p>").forEach(eventLine => {
                    if (!eventLine){
                        return;
                    }

                    let style = eventLine.match(/<em>/);
                    let plainText = eventLine.replace(/<strong>/g,'__START_STRONG__');
                    plainText = plainText.replace(/<\/strong>/g, '__END_STRONG__');
                    plainText = plainText.replace(/(?<=\[.+)]|\[(?=.+])/g, "");
                    plainText = htmlEncodePlainText(convertToPlainText(plainText));

                    /* Parse for Ruby Start */
                    let plainTextArray = plainText.split(" ");
                    if (plainTextArray.length > 1){
                        plainText = "";
                        plainTextArray.forEach(textPart =>{
                            if (/.+\(.+\)/.test(textPart)){
                                plainText += `<span style="ruby-outside-container"><span style="ruby-base">`;
                                plainText += textPart.split("(")[0] + `</span><span style="ruby-text">`;
                                plainText += textPart.split("(")[1].replace(")", "") + `</span></span>`;
                            } else {
                                plainText += textPart;
                            }
                        });
                    }
                    /* Parse for Ruby end */

                    /* Parse for Boutens */
                    plainTextArray = plainText.split(/__END_STRONG__/g);
                    if (plainTextArray.length > 1){
                        plainText = "";
                        plainTextArray.forEach(word =>{
                            if (/__START_STRONG__/.test(word)){
                                word = word.replace("__START_STRONG__","");
                                let charArray = word.split("");
                                charArray.forEach((char, index, chars) =>{
                                    chars[index] = `<span style="bouten-filled-dot-outside">${char}</span>`;
                                });

                                word = charArray.join("");
                            }

                            plainText+=word;
                        })
                    }
                    /* Parse for Boutens End*/                
                    
                    output += eol.after(`<p begin="${start}" end="${end}" region="${region}"${style ? ' style="italic"' : ''}>${plainText}</p>`)
                });
            } catch(err){
                console.log(err, err.message);
            }            
        });
        output += eol.after(`</div>`);
        output += eol.after(`</body>`);
        output += `</tt>`;
        return output;

        /* eventGroup.events.forEach((event, index) =>{
            let plainTextCustom = convertToPlainTextCustom(event.text);
            let plainText = convertToPlainText(event.text);
            let start = tcFormat === "smpte" ? tcLib.secToTc(event.start, options.frameRate) : tcLib.secToTcMs(event.start).replace(",", ".");
            let end = tcFormat === "smpte" ? tcLib.secToTc(event.end, options.frameRate) : tcLib.secToTcMs(event.end).replace(",", ".");
            let text = htmlEncodePlainText(plainTextCustom);
            let regionId = `${event.style}_${index+1}`;
            let style = "basic";
            let extents = ttmlFunc.multiLine.calcExtents(plainText, fontSize);
            let origins = ttmlFunc.multiLine.calcOrigin(plainText, event.xPos, event.xOffset, event.yPos, event.yOffset, fontSize, options.window);
            let alignment = event.alignment;

            regions += eol.after(`<region xml:id="${regionId}" tts:backgroundColor="transparent" tts:showBackground="whenActive" tts:origin="${origins}" tts:extent="${extents}" tts:textAlign="${alignment}" tts:overflow="visible" tts:wrapOption="noWrap"></region>`);

            paragraphs += eol.after(`<div>`);
            paragraphs += eol.after(`<p begin="${start.replace(";",":")}" end="${end.replace(";",":")}" style="${style}" region="${regionId}">${ttmlFunc.multiLine.convertToTtml(text)}</p>`);
            paragraphs += eol.after(`</div>`);
        }); */

        /* let output = eol.after(`<?xml version="1.0" encoding="UTF-8"?>`);
        output += eol.after(`<tt xml:lang="${language}" xmlns="http://www.w3.org/ns/ttml" xmlns:tts="http://www.w3.org/ns/ttml#styling" xmlns:ttm="http://www.w3.org/ns/ttml#metadata" xmlns:ttp="http://www.w3.org/ns/ttml#parameter" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" ${version == '1.1' ? 'ttp:contentProfiles="http://www.w3.org/ns/ttml/profile/imsc1.1/text" ' : ''}  xmlns:smpte="http://www.smpte-ra.org/schemas/2052-1/2010/smpte-tt" xmlns:m608="http://www.smpte-ra.org/schemas/2052-1/2010/smpte-tt#cea608" ttp:timeBase="media" ttp:frameRate="${ttmlFunc.frameRateMap[options.frameRate]}" ttp:frameRateMultiplier="${ttmlFunc.frameRateMultiplierMap[options.frameRate]}" ttp:profile="http://www.w3.org/ns/ttml/profile/imsc1/text" xmlns:ebutts="urn:ebu:tt:style" xmlns:itts="http://www.w3.org/ns/ttml/profile/imsc1#styling" xmlns:ittp="http://www.w3.org/ns/ttml/profile/imsc1#parameter" xmlns:ittm="http://www.w3.org/ns/ttml/profile/imsc1#metadata" ittp:aspectRatio="${aspectRatio}">`);
        output += eol.after(`<head>`);
        output += eol.after(`<metadata>
        <ttm:title>${programName}</ttm:title>
        <ttm:desc>IMSC${version} Text Profile document created by Closed Caption Converter</ttm:desc>
        <smpte:information xmlns:m608="http://www.smpte-ra.org/schemas/2052-1/2010/smpte-tt#cea608" origin="http://www.smpte-ra.org/schemas/2052-1/2010/smpte-tt#cea608" mode="Preserved" m608:channel="CC1" m608:programName="${programName}" m608:captionService="F1C1CC"/>
    </metadata>`);
        output += eol.after(`<styling>
        <style xml:id="basic" tts:color="${fontColor}" tts:backgroundColor="${bgColor}" tts:fontFamily="${fontFamily}" tts:fontSize="${fontSize}%" tts:fontWeight="${fontWeight}" tts:fontStyle="${fontStyle}" tts:lineHeight="${lineHeight}%"/>
    </styling>`);
        output += eol.after(`<layout>`); */
        /* Regions Go Here: */
        /* output += regions;
        output += eol.after(`</layout>`);
        output += eol.after(`</head>`);
        output += eol.after(`<body>`);
        output += paragraphs;
        output += eol.after(`</body>`); */
        

        //console.log(output);
        //return xmlFormat(output);
    },

    preProcess: {
        encode: function (eventGroup) {
            return removeInvalidEvents(eventGroup);
        },

        decode: function (input) {
            return eol.lf(input);
        }
    },

    postProcess: {
        encode: function (output) {
            return output;
        },

        decode: function (eventGroup) {
            return eventGroup;
        }
    },

}