
const tcLib = require("../../lib/timecode.js");
const Event = require("../../classes/event.js");
const flexbox = require("../../dict/flexbox.js");
const convertToPlainText = require("../quill/convertToPlainText.js");
const convertToHtml = require("../quill/convertToHtml.js");
const htmlEntities = require("html-entities");
const escapeHtmlEntities = require('../events/escapeHtmlEntities.js');
module.exports = {
    frameRateMap: {
        "23.976": "24",
        "24": "24",
        "25": "25",
        "29.97": "30",
        "30": "30",
        "50": "50",
        "59.94": "60",
        "60": "60"
    },
    frameRateMultiplierMap: {
        "23.976": "999 1000",
        "24": "1 1",
        "25": "1 1",
        "29.97": "1000 1001",
        "30": "1 1",
        "50": "1 1",
        "59.94": "1000 1001",
        "60": "1 1"
    },
    decodeCaptionBlock : function(captionBlock, frameRate, dropFrame){
        let offset = false, events = [];
        if (captionBlock['@_begin']){
            offset = captionBlock['@_begin'];
        } else {
            offset = false;
        }

        if (Array.isArray(captionBlock.p)){
            captionBlock.p.forEach(captionEvent =>{
                events.push(this.decodeCaptionEvent(captionEvent, frameRate, dropFrame, offset));
            })
        } else {
            let captionEvent = captionBlock.p;
            events.push(this.decodeCaptionEvent(captionEvent, frameRate, dropFrame, offset));
        }

        return events;
    },
    decodeCaptionEvent : function(captionEvent, frameRate, dropFrame, offset){
        //console.log(captionEvent);
        let start = tcLib.createTc(captionEvent["@_begin"], frameRate, dropFrame);
        let end = tcLib.createTc(captionEvent["@_end"], frameRate, dropFrame);
        if (offset){
            if (offset.charAt(0) == "-"){
                start.subtract(offset.substring(1));
                end.subtract(offset.substring(1));
            } else {
                start.add(offset);
                end.add(offset);
            }            
        }

        //console.log(start.toString(),end.toString());
        let text = this.decodeCaptionText(captionEvent["#text"]);
        let region = flexbox.alignmentMap[captionEvent["@_region"]] || "end";

        return new Event({
            start : tcLib.tcToSec(start.toString(), frameRate),
            end : tcLib.tcToSec(end.toString(), frameRate),
            yPos : region,
            text : text
        })
    },
    decodeCaptionText : function(text){
        if (!text){
            return "";
        }
        text = convertToPlainText(text.replace(/<br\/>/g, "\n")).replace(/ +/g," ").replace(/\n +/g, "\n");
        return convertToHtml(htmlEntities.decode(text));
    },
    convertToItt : function(html){
        return escapeHtmlEntities(html).replace(/(<em><u>)+/gmi, "<span style='italic'>").replace(/(<\/em><\/u>)+/gmi, "</span>").replace(/(<em>)+/gmi, "<span style='italic'>").replace(/(<\/em>)+/gmi, "</span>").replace(/(<u>)+/gmi, "<span style='underline'>").replace(/(<\/u>)+/gmi, "</span>").replace(/(<\/span>)+/g, "</span>");
    },
}
