/* Shot changes should be an array of times where shot changes take place... if two events are on a shot change then the second event gets to stay there and the first event will get moved. */
module.exports = function insertBlankFrames(eventGroup, frameRate = 29.97, frames = 2, shotChanges = []){
    eventGroup.events.sort((eventA, eventB)=>{
        return eventA.start - eventB.start;
    }).forEach((event, eventId, events) => {
        /* test to see if this is the last event and there are no more events in the event group */
        if (!events[eventId+1]){
            return;
        }

        /* test end of event */
        if (!event.end){
            return;
        }

        frames = parseInt(frames);
        if (event.end + ((1/frameRate)*frames) > events[eventId+1].start){
            if (shotChanges.indexOf(events[eventId+1].start) > -1){
                event.end = events[eventId+1].start - ((1/frameRate)*frames);
            } else {                
                events[eventId+1].start = events[eventId].end + (1/frameRate)*frames;
            }
        }
    });

    return eventGroup;
}