const Event = require("../classes/event.js");
const tcLib = require("../lib/timecode.js");
const eol = require("eol");
const removeInvalidEvents = require("../functions/eventGroups/removeInvalidEvents.js");
const convertToHtml = require("../functions/quill/convertToHtml.js");
const convertToPlainText = require("../functions/quill/convertToPlainText.js");
module.exports = {
    decode : function(input, options){
        let events = [], ccEvent = false, header;
        let subtitleBlocks = input.split("\n").filter(fileLine => {return fileLine});
        subtitleBlocks.forEach(block => {
            if (/^\d{4} : \d\d:\d\d:\d\d:\d\d \d\d:\d\d:\d\d:\d\d/.test(block)){
                if (ccEvent){
                    ccEvent.text = convertToHtml(ccEvent.text);
                    events.push(ccEvent);
                }

                ccEvent = new Event({
                    xOffset : 0,
                    yOffset : options.window.height * -0.10
                });
                header = block.split(" ").filter(blockPart => {return blockPart});
                
                header.shift(); //return 0001
                header.shift(); //return :
                let tcStart = header.shift();
                let tcEnd = header.shift();

                ccEvent.start = tcLib.tcToSec(tcStart, options.frameRate);
                ccEvent.end = tcLib.tcToSec(tcEnd, options.frameRate);
            } else if (ccEvent){
                ccEvent.text += block + "\n";
            }
        });

        return events;
    },

    encode : function(eventGroup, options){
        let output = "";
        eventGroup.events.forEach(function(event, index){
            output += ("0000" + (index+1)).slice(-4);
            output += " : ";
            output += tcLib.secToTc(event.start, options.frameRate) + " " + tcLib.secToTc(event.end, options.frameRate) + "\n";
            output += convertToPlainText(event.text);
            output += "\n\n";
        });

        return output.trim();
    },

    preProcess : {
        encode : function(eventGroup){
            return removeInvalidEvents(eventGroup);
        },

        decode : function(input){
            return eol.lf(input.replace(/'(\n){3,}'/gim, "\n\n").trim());
        }
    },

    postProcess : {
        encode : function(output){
            return output;
        },

        decode : function(eventGroup){
            return eventGroup;
        }
    },

}