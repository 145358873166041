const Event = require("../classes/event.js");
const getFormatOptions = require("../functions/helpers/getFormatOptions.js");
const convertToHtml = require("../functions/quill/convertToHtml.js");
const _Speaker = require('../classes/speaker.js');
module.exports = {
    decode: function (input, options) {
        let events = [], ccEvent, decodingOptions = getFormatOptions(options.formatOptions);
        let fileJson = JSON.parse(input);
        let importType = decodingOptions["Import Type"] || "subtitle";
        let maxLines = decodingOptions["Max Lines"] || 2;
        let maxChars = decodingOptions["Max Characters"] || 32;
        let currentWord;
        let speakers = [];

        function insertOrGetSpeaker(speaker) {
            if (speaker === undefined || speaker === null) {
                return {};
            }
            let speakerLabel = 'sp' + speaker;
            let speakerIndex = speakers.findIndex(sp => {
                return sp.name === speakerLabel;
            });

            if (speakerIndex > -1) {
                /* Speaker Exists in Array Already */
                return speakers[speakerIndex];
            } else {
                let spNew = new _Speaker({
                    name: speakerLabel
                })
                speakers.push(spNew);

                return spNew;
            }
        }

        fileJson.utterances.forEach(utterance => {
            //console.log(utterance);
            if (!utterance.words || utterance.words.length === 0 || !utterance.transcript) {
                return;
            }

            if (importType === "transcription") {
                ccEvent = new Event({
                    xOffset: 0,
                    yOffset: options.window.height * -0.10
                });
                ccEvent.start = parseFloat(utterance.start);
                ccEvent.end = parseFloat(utterance.end);
                ccEvent.alignment = "left";
                ccEvent.text = convertToHtml(utterance.transcript.trim(), [""]);
                ccEvent.speaker = insertOrGetSpeaker(utterance.speaker);
                events.push(ccEvent);
            } else if (importType === "word map") {
                utterance.words.forEach(word => {
                    events.push(new Event(
                        {
                            start: parseFloat(word.start),
                            end: parseFloat(word.end),
                            text: word.word,
                            xOffset: 0,
                            yOffset: options.window.height * -0.10
                        }
                    ));
                });
            } else {
                ccEvent = new Event({
                    xOffset: 0,
                    yOffset: options.window.height * -0.10
                });
                let ccAdded = false;
                let lines = [];
                let line = "";
                while (utterance.words.length > 0) {
                    let word = utterance.words.shift();
                    if (!ccEvent.start) {
                        ccEvent.start = parseFloat(word.start);
                    }

                    /* Test for gaps between words > 0.5 seconds */
                    if (ccEvent.end && parseFloat(word.start) > ccEvent.end + 0.35) {
                        //console.log("BREAK", line, ccEvent.end, word.startTime);
                        lines.push(line);
                        ccEvent.text = convertToHtml(lines.join("\n"));
                        ccEvent.speaker = insertOrGetSpeaker(utterance.speaker);
                        events.push(ccEvent);
                        ccAdded = true;
                        lines = [];
                        line = "";
                        ccEvent = new Event({
                            xOffset: 0,
                            yOffset: options.window.height * -0.10
                        });
                        ccEvent.start = parseFloat(word.start);
                    } else if (/\./.test(line)) {
                        if (lines.length === 0 && line.split(" ").length === 1) {
                            events[events.length - 1].text = events[events.length - 1].text += `<p class="ql-align-center">${line}</p>`;
                        } else {
                            lines.push(line);
                            ccEvent.text = convertToHtml(lines.join("\n"));
                            ccEvent.speaker = insertOrGetSpeaker(utterance.speaker);
                            events.push(ccEvent);
                            ccAdded = true;
                        }

                        lines = [];
                        line = "";
                        ccEvent = new Event({
                            xOffset: 0,
                            yOffset: options.window.height * -0.10
                        });
                        ccEvent.start = parseFloat(word.start);
                    }

                    currentWord = word.punctuated_word || word.word;
                    if (line.length + currentWord.length > maxChars - 1) {
                        lines.push(line);
                        line = currentWord;
                    } else {
                        line += " " + currentWord;
                    }

                    if (lines.length >= maxLines) {
                        ccEvent.text = convertToHtml(lines.join("\n"));
                        ccEvent.speaker = insertOrGetSpeaker(utterance.speaker);
                        events.push(ccEvent);
                        ccAdded = true;
                        lines = [];
                        ccEvent = new Event({
                            xOffset: 0,
                            yOffset: options.window.height * -0.10
                        });
                        ccEvent.start = parseFloat(word.start);
                    }

                    /* Set the end time to the new word end time */
                    ccEvent.end = parseFloat(word.end);
                }

                if (line.length > 0) {
                    if (lines.length > 0 || utterance.transcript.trim().split(" ").length === 1) {
                        lines.push(line);
                        ccEvent.text = convertToHtml(lines.join("\n"));
                        ccEvent.speaker = insertOrGetSpeaker(utterance.speaker);
                        events.push(ccEvent);
                    } else if (events.length > 0 && ccAdded) {
                        if (events[events.length - 1].end + 0.4 < ccEvent.start) {
                            lines.push(line);
                            ccEvent.text = convertToHtml(lines.join("\n"));
                            ccEvent.speaker = insertOrGetSpeaker(utterance.speaker);
                            events.push(ccEvent);
                        } else {
                            events[events.length - 1].text += convertToHtml(line.trim());
                            events[events.length - 1].end = ccEvent.end;
                        }
                    } else {
                        lines.push(line);
                        ccEvent.text = convertToHtml(lines.join("\n"));
                        ccEvent.speaker = insertOrGetSpeaker(utterance.speaker);
                        events.push(ccEvent);
                    }
                }
            }
        });

        //console.log(events);
        return events;
    },

    encode: function (eventGroup, options) {
        throw new Error("JSON Transcript files are not supported for encoding by Closed Caption Converter.");
    },

    preProcess: {
        encode: function (eventGroup) {
            return eventGroup;
        },

        decode: function (input) { /* All */
            return input;
        }
    },

    postProcess: {
        encode: function (output) {
            return output;
        },

        decode: function (eventGroup) {
            eventGroup.events = eventGroup.events.filter((event, index, events) => {
                if (index !== 0) {
                    return event.start !== events[index - 1].start;
                } else {
                    return true;
                }
            });

            return eventGroup;
        }
    }

}
