const Event = require("../classes/event.js");
const tcLib = require("../lib/timecode.js");
const eol = require("eol");
const convertToHtml = require("../functions/quill/convertToHtml.js");
const convertToPlainText = require("../functions/quill/convertToPlainText.js");
const removeInvalidEvents = require("../functions/eventGroups/removeInvalidEvents.js");

/*

0001	00:00:08:00	00:00:09:24	DEMOo from the wi Mech Hoffman DEMO.

0002	00:00:11:13	00:00:14:13	DEMO pretty amazing times that DEMOe experiencing right now

0003	00:00:14:13	00:00:16:23	and DEMOuse of that we want to DEMO sure we're giving out the

0004	00:00:16:23	00:00:20:20	DEMO guidance we can for our DEMOols as we're going through

*/

module.exports = {
    decode: function (input, options) {
        let events = [];
        let subtitleLines = input.split("\n\n");
        subtitleLines.forEach(line => {
            let parts = line.split("\t");
            if (parts.length > 3){
                let ccEvent = new Event({
                    xOffset : 0,
                    yOffset : options.window.height * -0.10
                });
                let id = parts.shift();
                let startTc = parts.shift().trim();
                let endTc = parts.shift().trim();
                let text = parts.shift().trim();
                ccEvent.start = tcLib.tcToSec(startTc, options.frameRate);
                ccEvent.end = tcLib.tcToSec(endTc, options.frameRate);
                ccEvent.text = convertToHtml(text);
                events.push(ccEvent);
            }
        });

        return events;
    },

    encode: function (eventGroup, options) {
        let output = "";

        eventGroup.events.forEach((event, index) => {
            output += (`${String(index+1).padStart(4, '0')}\t${tcLib.secToTc(event.start, options.frameRate)}\t${tcLib.secToTc(event.end, options.frameRate)}\t${convertToPlainText(event.text, " ")}\n\n`);
        });

        return output.trim();
    },

    preProcess: {
        encode: function (eventGroup) {
            return removeInvalidEvents(eventGroup);
        },

        decode: function (input) {
            return eol.lf(input.trim());
        }
    },

    postProcess: {
        encode: function (output) {
            return output;
        },

        decode: function (eventGroup) {
            return eventGroup;
        }
    },

}