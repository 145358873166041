const Event = require("../classes/event.js");
const tcLib = require("../lib/timecode.js");
const eol = require("eol");
const convertToPlainText = require("../functions/quill/convertToPlainText.js");
const convertToHtml = require("../functions/quill/convertToHtml.js");
const flexbox = require("../dict/flexbox.js");
const removeInvalidEvents = require("../functions/eventGroups/removeInvalidEvents.js");
const htmlEntities = require('html-entities'); //Encode
const formatXml = require('xml-formatter'); //Encode
const xmlToJson = require('fast-xml-parser'); //Decode
const ttmlFunc = require("../functions/profiles/ttmlGeneral.js");
module.exports = {
    decode: function (input, options) {
        let events = [], ccEvent;

        let fileJson = xmlToJson.parse(input, {
            ignoreAttributes: false
        });

        //console.log(JSON.stringify(fileJson, null, 4));

        fileJson["tt:tt"]["tt:body"]["tt:div"]["tt:p"].forEach(paragraph => {
            ccEvent = new Event({
                xOffset : 0,
                yOffset : options.window.height * -0.10
            });
            ccEvent.start = tcLib.tcToSec(paragraph['@_begin'], options.frameRate);
            ccEvent.end = tcLib.tcToSec(paragraph['@_end'], options.frameRate);

            /* Get Y Positioning */
            if (paragraph['@_end'] && flexbox.alignmentMap[paragraph['@_end']]) {
                ccEvent.yPos = flexbox.alignmentMap[paragraph['@_end']];
            }

            if (Array.isArray(paragraph['tt:span'])) {
                ccEvent.text = paragraph['tt:span'].map(textLines => {
                    return textLines["#text"]
                }).join("\n");
            } else {
                ccEvent.text = paragraph['tt:span']["#text"];
            }

            ccEvent.text = convertToHtml(htmlEntities.decode(ccEvent.text));
            events.push(ccEvent);
        });
        return events;
    },

    encode: function (eventGroup, options) {
        let output = eol.after(`<?xml version="1.0" encoding="UTF-8"?>`);

        output += eol.after(`<tt:tt ttp:timeBase="smpte" xml:lang="en" xmlns:tt="http://www.w3.org/ns/ttml" xmlns:ttp="http://www.w3.org/ns/ttml#parameter" xmlns:tts="http://www.w3.org/ns/ttml#styling" ttp:cellResolution="50 30" xmlns:ebuttm="urn:ebu:tt:metadata" tts:extent="704px 576px" ttp:dropMode="${options.dropFrame ? 'drop' : 'nonDrop'}" ttp:frameRate="${ttmlFunc.frameRateMap[options.frameRate]}" ttp:frameRateMultiplier="${ttmlFunc.frameRateMultiplierMap[options.frameRate]}" ttp:markerMode="discontinuous">`);
        output += eol.after(`<tt:head>`);
        output += eol.after(`<tt:metadata>
        <ebuttm:documentMetadata>
            <ebuttm:documentEbuttVersion>v1.0</ebuttm:documentEbuttVersion>
            <ebuttm:documentTotalNumberOfSubtitles>${eventGroup.events.length}</ebuttm:documentTotalNumberOfSubtitles>
            <ebuttm:documentMaximumNumberOfDisplayableCharacterInAnyRow>40</ebuttm:documentMaximumNumberOfDisplayableCharacterInAnyRow>
            <ebuttm:documentStartOfProgramme>00:00:00:00</ebuttm:documentStartOfProgramme>
            <ebuttm:documentCountryOfOrigin>EN</ebuttm:documentCountryOfOrigin>
            <ebuttm:documentPublisher>Closed Caption Converter V3</ebuttm:documentPublisher>
        </ebuttm:documentMetadata>
    </tt:metadata>`);
        output += eol.after(`<tt:styling>
        <tt:style xml:id="defaultStyle" tts:fontFamily="monospaceSansSerif" tts:fontSize="1c 1c" tts:lineHeight="normal" tts:textAlign="center" tts:color="white" tts:backgroundColor="transparent" tts:fontStyle="normal" tts:fontWeight="normal" tts:textDecoration="none" />
        <tt:style xml:id="WhiteOnBlack" tts:color="white" tts:backgroundColor="black" tts:fontSize="1c 2c"/>
        <tt:style xml:id="textCenter" tts:textAlign="center"/>
    </tt:styling>`);
        output += eol.after(`<tt:layout>
        <tt:region xml:id="top" tts:origin="10% 10%" tts:extent="80% 80%" tts:padding="0c" tts:displayAlign="before" tts:writingMode="lrtb"/>
        <tt:region xml:id="bottom" tts:origin="10% 10%" tts:extent="80% 80%" tts:padding="0c" tts:displayAlign="after" tts:writingMode="lrtb"/>
    </tt:layout>`);
        output += eol.after(`</tt:head>`);
        output += eol.after(`<tt:body>`);
        output += eol.after(`<tt:div>`);

        eventGroup.events.forEach((event,index) => {
            output += eol.after(`<tt:p xml:id="sub${index+1}" style="textCenter" region="top" begin="${tcLib.secToTc(event.start, options.frameRate)}" end="${tcLib.secToTc(event.end, options.frameRate)}">`);
            convertToPlainText(event.text).split("\n").forEach((textLine, index) =>{
                if (index > 0){
                    output += eol.after(`<tt:br/>`);
                }                
                output += eol.after(`<tt:span style="WhiteOnBlack">${htmlEntities.encode(textLine)}</tt:span>`);
            });
            output += eol.after(`</tt:p>`);
        });

        output += eol.after(`</tt:div>`);
        output += eol.after(`</tt:body>`);
        output += eol.after(`</tt:tt>`);

        return formatXml(output);
    },

    preProcess: {
        encode: function (eventGroup) {
            return removeInvalidEvents(eventGroup);
        },

        decode: function (input) {
            return eol.lf(input.trim());
        }
    },

    postProcess: {
        encode: function (output) {
            return output;
        },

        decode: function (eventGroup) {
            return eventGroup;
        }
    },

}