const Event = require("../classes/event.js");
const tcLib = require("../lib/timecode.js");
const removeInvalidEvents = require("../functions/eventGroups/removeInvalidEvents.js");
const convertToHtml = require("../functions/quill/convertToHtml.js");
const convertToPlainText = require("../functions/quill/convertToPlainText.js");
const htmlEntities = require('html-entities'); //Encode
const formatXml = require('xml-formatter'); //Encode
const xmlToJson = require('fast-xml-parser'); //Decode
const eol = require("eol");

module.exports = {
    decode : function(input, options){
        let events = [], ccEvent;
        xmlToJson.parse(input, {
            ignoreAttributes : false,
            stopNodes: ["p"]
        }).tt.body.div.p.forEach(paragraph =>{
            ccEvent = new Event({
                xOffset : 0,
                yOffset : options.window.height * -0.10
            });
            ccEvent.start = tcLib.tcMsToSec(paragraph['@_begin']);
            ccEvent.end = tcLib.tcMsToSec(paragraph['@_end']);
            ccEvent.text = convertToHtml(htmlEntities.decode(paragraph["#text"].replace(/<br \/>|<br\/>|<br>/gmi, "\n")));

            events.push(ccEvent);
        });

        return events;       
    },

    encode : function(eventGroup, options){
        let output = eol.after("<?xml version=\"1.0\" encoding=\"UTF-8\"?>");

        output += eol.after("<tt xml:lang=\"en\" xmlns=\"http://www.w3.org/ns/ttml\"");
        output += eol.after("xmlns:tts=\"http://www.w3.org/ns/ttml#styling\"");
        output += eol.after("xmlns:ttm=\"http://www.w3.org/ns/ttml#metadata\">");


        output += eol.after("<head>");

        output += eol.after("<styling>");
        output += eol.after("<style xml:id=\"defaultCaption\" tts:fontSize=\"10\" tts:fontFamily=\"SansSerif\""); 
        output += eol.after("tts:fontWeight=\"normal\" tts:fontStyle=\"normal\"");
        output += eol.after("tts:textDecoration=\"none\" tts:color=\"white\"");
        output += eol.after("tts:backgroundColor=\"black\" />");
        output += eol.after("</styling>");

        output += eol.after("</head>");
        output += eol.after("<body>");
        output += eol.after("<div style=\"defaultCaption\" xml:lang=\"en\">");

        eventGroup.events.forEach(event =>{
            output += eol.after(`<p begin="${tcLib.secToTcMs(event.start).replace(',', '.')}" end="${tcLib.secToTcMs(event.end).replace(',', '.')}">${convertToPlainText(event.text).replace(/\n/gmi,"<br />")}</p>`);
        });

        output += eol.after("</div>");
        output += eol.after("</body>");
        output += eol.after("</tt>");
    
        return formatXml(output);
    },

    preProcess : {
        encode : function(eventGroup){
            return removeInvalidEvents(eventGroup);
        },

        decode : function(input){
            return input;
        }
    },

    postProcess : {
        encode : function(output){
            return output;
        },

        decode : function(eventGroup){
            return eventGroup;
        }
    },

}