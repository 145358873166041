const Event = require("../classes/event.js");
const getFormatOptions = require("../functions/helpers/getFormatOptions.js");
const convertToHtml = require("../functions/quill/convertToHtml.js");
const _Speaker = require('../classes/speaker.js');
module.exports = {
    decode: function (input, options) {
        let events = [], ccEvent = new Event({
            xOffset: 0,
            yOffset: options.window.height * -0.10
        }), decodingOptions = getFormatOptions(options.formatOptions);
        let fileJson = JSON.parse(input);
        let importType = decodingOptions["Import Type"] || "subtitle";
        let maxLines = decodingOptions["Max Lines"] || 2;
        let maxChars = decodingOptions["Max Characters"] || 32;
        let speaker = 1;
        let speakers = [];
        try {
            speaker = fileJson.result.words[0].spk || 1;
        } catch (err) {
            speaker = 1;
        }

        function insertOrGetSpeaker(speaker) {
            if (speaker === undefined || speaker === null) {
                return {};
            }
            let speakerLabel = 'sp' + speaker;
            let speakerIndex = speakers.findIndex(sp => {
                return sp.name === speakerLabel;
            });

            if (speakerIndex > -1) {
                /* Speaker Exists in Array Already */
                return speakers[speakerIndex];
            } else {
                let spNew = new _Speaker({
                    name: speakerLabel
                })
                speakers.push(spNew);

                return spNew;
            }
        }


        if (importType === "transcription") {
            fileJson.result.words.forEach(word => {
                if (word.confidence > 0.4) {
                    if (word.spk !== speaker || (ccEvent.end && word.start / 1000 >= ccEvent + 0.5)) {
                        ccEvent.speaker = insertOrGetSpeaker(speaker);
                        speaker = word.spk;
                        ccEvent.alignment = "left";
                        ccEvent.text = convertToHtml(ccEvent.text.trim(), [""]);
                        events.push(ccEvent);
                        ccEvent = new Event({
                            xOffset: 0,
                            yOffset: options.window.height * -0.10
                        });
                        ccEvent.text += word.utterance + " ";
                        ccEvent.start = word.start / 1000;
                        ccEvent.end = (word.start + word.duration) / 1000;
                    } else {
                        ccEvent.text += word.utterance + " ";
                        ccEvent.start = ccEvent.start ? ccEvent.start : word.start / 1000;
                        ccEvent.end = (word.start + word.duration) / 1000;
                    }
                }
            });

            ccEvent.alignment = "left";
            ccEvent.text = convertToHtml(ccEvent.text.trim(), [""]);
            events.push(ccEvent);
        } else if (importType === "word map") {
            fileJson.result.words.forEach(word => {
                if (word.utterance === "[MUSIC]") {
                    return;
                }
                events.push(new Event(
                    {
                        start: word.start / 1000,
                        end: (word.start + word.duration) / 1000,
                        text: word.utterance,
                        xOffset: 0,
                        yOffset: options.window.height * -0.10
                    }
                ));
            });
        } else {
            let ccAdded = false;
            let lines = [];
            let line = "";
            ccEvent.speaker = insertOrGetSpeaker(speaker);
            while (fileJson.result.words.length > 0) {
                let word = fileJson.result.words.shift();
                if (word.confidence > 0.4) {
                    if (!ccEvent.start) {
                        ccEvent.start = word.start / 1000;
                    }

                    /* Test for gaps between words > 0.6 seconds */
                    if ((ccEvent.end && word.start / 1000 > ccEvent.end + 0.5) || (line.charAt(line.length - 1) === ".")) {
                        //console.log("BREAK", line, ccEvent.end, word.start/1000);
                        lines.push(line);
                        ccEvent.text = convertToHtml(lines.join("\n"));
                        events.push(ccEvent);
                        ccAdded = true;

                        lines = [];
                        line = "";
                        ccEvent = new Event({
                            xOffset: 0,
                            yOffset: options.window.height * -0.10
                        });
                        ccEvent.speaker = insertOrGetSpeaker(word.spk);
                        ccEvent.start = word.start / 1000;
                    }

                    if (line.length + word.utterance.length > maxChars - 1) {
                        lines.push(line);
                        line = word.utterance;
                    } else {
                        line += " " + word.utterance;
                    }

                    if (lines.length >= maxLines) {
                        ccEvent.text = convertToHtml(lines.join("\n"));
                        events.push(ccEvent);
                        ccAdded = true;
                        lines = [];
                        ccEvent = new Event({
                            xOffset: 0,
                            yOffset: options.window.height * -0.10
                        });
                        ccEvent.speaker = insertOrGetSpeaker(word.spk);
                        ccEvent.start = word.start / 1000;
                    }

                    /* Set the end time to the new word end time */
                    ccEvent.end = (word.start + word.duration) / 1000;
                }
            }

            lines.push(line);
            ccEvent.text = convertToHtml(lines.join("\n"));
            events.push(ccEvent);
        }

        return events;
    },

    encode: function (eventGroup, options) {
        throw new Error("JSON Transcript files are not supported for encoding by Closed Caption Converter.");
    },

    preProcess: {
        encode: function (eventGroup) {
            return eventGroup;
        },

        decode: function (input) { /* All */
            return input;
        }
    },

    postProcess: {
        encode: function (output) {
            return output;
        },

        decode: function (eventGroup) {
            eventGroup.events = eventGroup.events.filter((event, index, events) => {
                if (index !== 0) {
                    return event.start !== events[index - 1].start;
                } else {
                    return true;
                }
            });

            return eventGroup;
        }
    }

}
