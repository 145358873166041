const Event = require("../classes/event.js");
const tcLib = require("../lib/timecode.js");
const removeInvalidEvents = require("../functions/eventGroups/removeInvalidEvents.js");
const convertToHtml = require("../functions/quill/convertToHtml.js");
const convertToPlainText = require("../functions/quill/convertToPlainText.js");
const eol = require("eol");
const replaceLineEndings = require("../functions/quill/replaceLineEndings.js");

module.exports = {
    decode: function (input, options) {
        let events = [], ccEvent;
        const tcRegex = /\{\d+\}\{\d+\}/gmi;
        let fileLines = eol.split(input).filter(fileLine => {return fileLine;});
        fileLines.forEach(fileLine =>{
            if (tcRegex.test(fileLine)){
                let ccEvent = new Event({
                    xOffset : 0,
                    yOffset : options.window.height * -0.10
                });
                let fileParts = fileLine.split("}");
                let frameStart = fileParts.shift().replace(/\{|\}/g, "");
                let frameEnd = fileParts.shift().replace(/\{|\}/g, "");
                let text = fileParts.shift().replace(/\|/gmi,"\n");

                ccEvent.start = tcLib.framesToSec(parseInt(frameStart), options.frameRate);
                ccEvent.end = tcLib.framesToSec(parseInt(frameEnd), options.frameRate);
                ccEvent.text = convertToHtml(text);
                events.push(ccEvent);
            }
        });
        return events;
    },

    encode: function (eventGroup, options) {
        let output = "";
        eventGroup.events.forEach(event => {
            output += (`{${tcLib.secToFrames(event.start, options.frameRate)}}{${tcLib.secToFrames(event.end, options.frameRate)}}${eol.after(replaceLineEndings(convertToPlainText(event.text), "|"))}`);
        });
        
        return output;
    },

    preProcess: {
        encode: function (eventGroup) {
            return removeInvalidEvents(eventGroup);
        },

        decode: function (input) {
            return eol.lf(input.trim());
        }
    },

    postProcess: {
        encode: function (output) {
            return output;
        },

        decode: function (eventGroup) {
            return eventGroup;
        }
    },

}