module.exports = function extractFromSource(profile, raw) {
    if (profile.toLowerCase() === 'mp4'){
        let matches = raw.match(/<p((.|\n)*?)<\/p>/gmi);
        //console.log(raw);
        //console.log(matches);
        let fileContents = `<?xml version="1.0" encoding="UTF-8"?>
        <tt xmlns="http://www.w3.org/2006/10/ttaf1" xmlns:tts="http://www.w3.org/2006/10/ttaf1#styling" xml:lang="en">
            <head>
                <layout>
                    <region xml:id="r1" tts:origin="0.000% 79.167%" tts:extent="100.000% 16.667%" tts:textAlign="center" tts:fontSize="160.000%" tts:lineHeight="125.000%" />
                    <region xml:id="r2" tts:origin="0.000% 0.000%" tts:extent="100.000% 8.333%" tts:textAlign="center" tts:fontSize="160.000%" tts:lineHeight="125.000%" />
                    <region xml:id="r3" tts:origin="0.000% 0.000%" tts:extent="100.000% 16.667%" tts:textAlign="center" tts:fontSize="160.000%" tts:lineHeight="125.000%" />                    
                </layout>
                <styling>
                    <style id="1" tts:textAlign="center" tts:color="#FFFFFF" tts:backgroundColor="#000000" tts:fontSize="16" tts:fontFamily="Verdana" tts:wrapOption="wrap"/>
                </styling>
            </head>
            <body>
                <div xml:lang="en" style="1">${matches.join("")}</div>
                </body>
            </tt>`

        //console.log(fileContents);
        return fileContents;
    } else {
        throw new Error(`Extract from source profile is not recognized. Supported profiles: mp4. Profile provided: ${profile}`);
    }
}