const Event = require("../classes/event.js");
const getFormatOptions = require("../functions/helpers/getFormatOptions.js");
const convertToHtml = require("../functions/quill/convertToHtml.js");
const _Speaker = require('../classes/speaker.js');
module.exports = {
    decode: function (input, options) {
        let events = [], ccEvent, decodingOptions = getFormatOptions(options.formatOptions);
        let fileJson = JSON.parse(input);
        let importType = decodingOptions["Import Type"] || "subtitle";
        let maxLines = decodingOptions["Max Lines"] || 2;
        let maxChars  = decodingOptions["Max Characters"] || 32;
        let speakers = [];

        function insertOrGetSpeaker(speaker){
            if (speaker === undefined || speaker === null){
                return {};
            }
            let speakerLabel = 'sp'+speaker;
            let speakerIndex = speakers.findIndex(sp =>{
                return sp.name === speakerLabel;
            });

            if (speakerIndex > -1){
                /* Speaker Exists in Array Already */
                return speakers[speakerIndex];
            } else {
                let spNew = new _Speaker({
                    name : speakerLabel
                })
                speakers.push(spNew);

                return spNew;
            }
        }


        fileJson.monologues.forEach(monologue => {
            //console.log(monologue);
            if (!monologue.elements || monologue.elements.length === 0) {
                return;
            }

            if (importType === "transcription") {
                ccEvent = new Event({
                    xOffset : 0,
                    yOffset : options.window.height * -0.10
                });
                ccEvent.speaker = insertOrGetSpeaker(monologue.speaker);
                let words = monologue.elements.filter(el => {
                    return el.type === "text";
                });
                ccEvent.start = parseFloat(words[0].ts);
                ccEvent.end = parseFloat(words[words.length - 1].end_ts);
                ccEvent.alignment = "left";
                let transcript = monologue.elements.map(el => {
                    return el.value;
                }).join("");

                ccEvent.text = convertToHtml(transcript.trim(), [""]);
                events.push(ccEvent);
            } else if (importType === "word map"){
                monologue.elements.forEach(word =>{
                    if (word.value === " " || word.type !== "text") {
                        return;
                    }

                    events.push(new Event(
                        {
                            start : parseFloat(word.ts),
                            end : parseFloat(word.end_ts),
                            text : word.value,
                            xOffset : 0,
                yOffset : options.window.height * -0.10
                        }
                    ));
                });
            } else {
                ccEvent = new Event({
                    xOffset : 0,
                yOffset : options.window.height * -0.10
                });
                ccEvent.speaker = insertOrGetSpeaker(monologue.speaker);
                let ccAdded = false;
                let lines = [];
                let line = "";
                while (monologue.elements.length > 0) {
                    let word = monologue.elements.shift();
                    if (word.value !== " ") {
                        if (!ccEvent.start && word.type === "text") {
                            ccEvent.start = parseFloat(word.ts);
                        }

                        /* Test for gaps between words > 0.6 seconds */
                        if ((ccEvent.end && word.type === "text" && parseFloat(word.ts) > ccEvent.end + 0.5) || (line.charAt(line.length-1) === ".")) {
                            //console.log("BREAK", line, ccEvent.end, word.startTime);
                            lines.push(line);
                            ccEvent.text = convertToHtml(lines.join("\n"));
                            events.push(ccEvent);
                            ccAdded = true;

                            lines = [];
                            line = "";
                            ccEvent = new Event({
                                xOffset : 0,
                yOffset : options.window.height * -0.10
                            });
                            ccEvent.speaker = insertOrGetSpeaker(monologue.speaker);
                            ccEvent.start = parseFloat(word.ts);
                        }

                        if (line.length + word.value.length > maxChars-2 && word.type !== "punct") {
                            lines.push(line);
                            line = word.value;
                        } else if (word.type === "punct"){
                            line += word.value;                            
                        } else {
                            line += " "+ word.value;
                        }

                        if (lines.length >= maxLines) {
                            ccEvent.text = convertToHtml(lines.join("\n"));
                            events.push(ccEvent);
                            ccAdded = true;
                            lines = [];
                            ccEvent = new Event({
                                xOffset : 0,
                yOffset : options.window.height * -0.10
                            });
                            ccEvent.speaker = insertOrGetSpeaker(monologue.speaker);
                            ccEvent.start = parseFloat(word.ts);
                        }

                        /* Set the end time to the new word end time */
                        if (word.type === "text"){
                            ccEvent.end = parseFloat(word.end_ts);
                        }                        
                    }
                }

                if (line.length > 0) {
                    let monoText = monologue.elements.map(el => {
                        return el.value;
                    }).join("");
                    if (lines.length > 0 || monoText.trim().split(" ").length === 1) {
                        lines.push(line);
                        ccEvent.text = convertToHtml(lines.join("\n"));
                        events.push(ccEvent);
                    } else if (events.length > 0 && ccAdded) {
                        if (events[events.length - 1].end + 0.4 < ccEvent.start) {
                            lines.push(line);
                            ccEvent.text = convertToHtml(lines.join("\n"));
                            events.push(ccEvent);
                        } else {
                            events[events.length - 1].text += convertToHtml(line.trim());
                            events[events.length - 1].end = ccEvent.end;
                        }
                    } else {
                        lines.push(line);
                        ccEvent.text = convertToHtml(lines.join("\n"));
                        events.push(ccEvent);
                    }
                }
            }
        });

        //console.log(events);
        return events;
    },

    encode: function (eventGroup, options) {
        throw new Error("JSON Transcript files are not supported for encoding by Closed Caption Converter.");
    },

    preProcess: {
        encode: function (eventGroup) {
            return eventGroup;
        },

        decode: function (input) { /* All */
            return input;
        }
    },

    postProcess: {
        encode: function (output) {
            return output;
        },

        decode: function (eventGroup) {
            eventGroup.events = eventGroup.events.filter((event, index, events) => {
                if (index !== 0) {
                    return event.start !== events[index - 1].start;
                } else {
                    return true;
                }
            });

            return eventGroup;
        }
    }

}
