const Event = require("../classes/event.js");
const tcLib = require("../lib/timecode.js");
const getFormatOptions = require("../functions/helpers/getFormatOptions.js");
const convertToHtml = require("../functions/quill/convertToHtml.js");
const fixEnglishTextInRTLLanguage = require("../functions/quill/fixEnglishTextInRTLLanguage.js");
const convertToPlainText = require("../functions/quill/convertToPlainText.js");
const pacFunc = require('../functions/profiles/ssPoliscript.js');

module.exports = {
    decode: function (input, options) {
        /* Code Page Decode */
        /* pacFunc.codePages.forEach(codePage =>{
            console.log(codePage + " --> " +pacFunc.decodeChar("87", codePage));
        }); */
        let events = [],
            tcFlag = false,
            subtitleFlag = false,
            seperator = "fe",
            codePage = 28591,
            italicFlag = false,
            ccEvent,
            incodeHex,
            outcodeHex,
            decodingOptions = getFormatOptions(options.formatOptions);

        if (decodingOptions["Code Page"]) {
            codePage = pacFunc.codePageMapping[decodingOptions["Code Page"]];
        }

        let pacLine = [],
            pacLines = [],
            tcMarkerIndex;
        input.match(/(..?)/g).forEach(hexCode => {
            if (hexCode === seperator) {
                /* Check if the seperator is part of a tc block */
                tcMarkerIndex = pacLine.findIndex(pacCode => {
                    return pacCode === "60";
                });                

                if (tcMarkerIndex > -1 && !pacLine[tcMarkerIndex+8]){
                    /* This is part of a TC block */
                    pacLine.push(hexCode);
                } else {
                    /* This is not part of a TC block and is used to separate a line */
                    pacLines.push(pacLine.join(""));
                    pacLine = [];
                }

            } else {
                pacLine.push(hexCode);
            }
        });

        /* push last pac Line */
        if (pacLine.length > 0){
            pacLines.push(pacLine.join(""));
        }       
        //console.log(pacLines);
        pacLines.forEach(function (pacLine) {
            //console.log(pacLine);
            italicFlag = false;
            pacLine = pacLine.match(/(..?)/g);
            //console.log(pacLine);

            /* Debug Logger: */
            //console.log(pacLine.join(", ") + "\n");
            ccEvent ? ccEvent.text += "\n" : "";

            /* Alignment Start*/
            if (ccEvent && pacLine[0] === "01"){
                ccEvent.alignment = "left";
            } else if (ccEvent && pacLine[0] === "02"){
                ccEvent.alignment = "center";
            } else if (ccEvent && pacLine[0] === "00"){
                ccEvent.alignment = "right";
            }
            /* Alignment End */
            while (pacLine.length > 0) {
                let char = pacLine.shift();
                if (char === "60" && pacLine[9] === "00" && pacLine.length < 15) {
                    tcFlag = true;
                    subtitleFlag = true;
                    if (ccEvent) {
                        //console.log(ccEvent.text);
                        if (codePage === 28596 || codePage === 58598){
                            ccEvent.text = fixEnglishTextInRTLLanguage(ccEvent.text, );
                        }                        
                        ccEvent.text = convertToHtml(ccEvent.text);
                        events.push(ccEvent);
                        //console.log("-------------");
                    }

                    ccEvent = new Event({
                        xOffset : 0,
                        yOffset : options.window.height * -0.10
                    });
                    italicFlag = false;
                    incodeHex = pacLine.splice(0, 4);
                    outcodeHex = pacLine.splice(0, 4);

                    /* Timecode Debug */
                    //console.log(incodeHex + "-->" + pacFunc.decodeTc(incodeHex));
                    //console.log(outcodeHex + "-->" + pacFunc.decodeTc(outcodeHex));

                    ccEvent.start = tcLib.tcToSec(pacFunc.decodeTc(incodeHex), options.frameRate);
                    ccEvent.end = tcLib.tcToSec(pacFunc.decodeTc(outcodeHex), options.frameRate);

                    /* Vertical Position Start */
                    if (pacLine[2]){
                        let vertPosValue = parseInt(pacLine[2], 16);
                        if (vertPosValue < 4){
                            ccEvent.yPos = "start";
                            ccEvent.yOffset = options.window.height * 0.10;
                        } else if (vertPosValue >= 4  && vertPosValue <= 8){
                            ccEvent.yPos = "center";
                            ccEvent.yOffset = 0;
                        }   
                    }
                    /* Vertical Position End */


                    /* remove end parts */
                    //console.log(pacLine);
                    pacLine = [];

                } else if (tcFlag) {
                    if (parseInt(char, 16) >= 240 && parseInt(char, 16) <= 255) {
                        /* control codes */
                        //console.log(`CONTROL CODE: ${char}`);
                    } else if (parseInt(char, 16) >= 0 && parseInt(char, 16) <= 31) {
                        //console.log("Garbage: " + char);
                        if (tcFlag && char === "00" && ccEvent.text.trim() && pacLine[0]) {
                            let subCounter = (events.length+1)%256;
                            /*console.log(                                
                                subCounter,
                                parseInt(pacLine[0], 16), 
                                pacLine[0],                                
                                events.length+1,  
                                ccEvent.text, 
                                subtitleFlag,
                                pacFunc.decodeChar(pacLine[0], codePage),
                                "\n"
                            ); */

                            if (parseInt(pacLine[0], 16) === subCounter || parseInt(pacLine[0], 16) === subCounter+1) {
                                subtitleFlag = false; 
                            } 
                        }
                    } else if (subtitleFlag) {
                        if (parseInt(char, 16) === 128 && codePage === 28591) {
                            ccEvent.text += "#";
                        } else if (parseInt(char, 16) >= 129 && parseInt(char, 16) < 160) {
                            /* Do Nothing? */   
                            //console.log(`Do Nothing? ${char}`);
                            if (codePage === 28591){
                                if (pacLine[0] && pacFunc.specialLatinChars[char + pacLine[0]]){
                                    ccEvent.text += pacFunc.specialLatinChars[char + pacLine.shift()];
                                } else if (pacFunc.specialLatinChars[char]){
                                    ccEvent.text += pacFunc.specialLatinChars[char];
                                }
                            } else if (codePage === 28592){
                                if (pacLine[0] && pacFunc.specialLatinChars[char + pacLine[0]]){
                                    ccEvent.text += pacFunc.specialLatinChars[char + pacLine.shift()];
                                } else if (pacFunc.specialLatinChars[char]){
                                    ccEvent.text += pacFunc.specialLatinChars[char];
                                }
                            } else if (codePage === 28593){
                                if (pacLine[0] && pacFunc.specialLatinChars[char + pacLine[0]]){
                                    ccEvent.text += pacFunc.specialLatinChars[char + pacLine.shift()];
                                } else if (pacFunc.specialLatinChars[char]){
                                    ccEvent.text += pacFunc.specialLatinChars[char];
                                }
                            } else if (codePage === 28595){
                                if (pacLine[0] && pacFunc.specialCyrillicChars[char + pacLine[0]]){
                                    ccEvent.text += pacFunc.specialCyrillicChars[char + pacLine.shift()];
                                } else if (pacFunc.specialCyrillicChars[char]){
                                    ccEvent.text += pacFunc.specialCyrillicChars[char];
                                }
                            } else if (codePage === 28596){
                                if (pacLine[0] && pacFunc.specialArabicChars[char + pacLine[0]]){
                                    ccEvent.text += pacFunc.specialArabicChars[char + pacLine.shift()];
                                } else if (pacFunc.specialArabicChars[char]){
                                    ccEvent.text += pacFunc.specialArabicChars[char];
                                }
                            } else if (codePage === 28597){
                                if (pacLine[0] && pacFunc.specialGreekChars[char + pacLine[0]]){
                                    ccEvent.text += pacFunc.specialGreekChars[char + pacLine.shift()];
                                } else if (pacFunc.specialGreekChars[char]){
                                    ccEvent.text += pacFunc.specialGreekChars[char];
                                }
                            } else if (codePage === 28598){
                                if (pacLine[0] && pacFunc.specialHebrewChars[char + pacLine[0]]){
                                    ccEvent.text += pacFunc.specialHebrewChars[char + pacLine.shift()];
                                } else if (pacFunc.specialHebrewChars[char]){
                                    ccEvent.text += pacFunc.specialHebrewChars[char];
                                }
                            } else if (codePage === 28599){
                                if (pacLine[0] && pacFunc.specialTurkishChars[char + pacLine[0]]){
                                    ccEvent.text += pacFunc.specialTurkishChars[char + pacLine.shift()];
                                } else if (pacFunc.specialTurkishChars[char]){
                                    ccEvent.text += pacFunc.specialTurkishChars[char];
                                }
                            }
                        } else {
                            //console.log(char);
                            if (codePage === 28591 || codePage === 28592 || codePage === 28593 || codePage === 28594){
                                if (pacLine[0] && pacFunc.specialLatinChars[char + pacLine[0]]){
                                    ccEvent.text += pacFunc.specialLatinChars[char + pacLine.shift()];
                                } else if (pacFunc.specialLatinChars[char]){
                                    ccEvent.text += pacFunc.specialLatinChars[char];
                                } else if (char === "3c" || char === "3e") {
                                    if (italicFlag){
                                        ccEvent.text += "</em>";
                                        italicFlag = false;
                                    } else {
                                        ccEvent.text += "<em>";
                                        italicFlag = true;
                                    }                          
                                } else {
                                    //console.log(char, pacFunc.decodeChar(char, codePage));
                                    //console.log(pacLine);
                                    if (pacFunc.decodeChar(char, codePage) === "j" && pacLine[0] === "00"){
                                        pacLine.shift();
                                    } else{
                                        //console.log(`${char} : ${pacFunc.decodeChar(char, codePage) || ""}`);
                                        ccEvent.text += pacFunc.decodeChar(char, codePage);
                                    }                                    
                                }
                            } else if (codePage === 28595){
                                if (pacLine[0] && pacFunc.specialCyrillicChars[char + pacLine[0]]){
                                    ccEvent.text += pacFunc.specialCyrillicChars[char + pacLine.shift()];
                                } else if (pacFunc.specialCyrillicChars[char]){
                                    ccEvent.text += pacFunc.specialCyrillicChars[char];
                                } else if (char === "3c" || char === "3e") {
                                    if (italicFlag){
                                        ccEvent.text += "</em>";
                                        italicFlag = false;
                                    } else {
                                        ccEvent.text += "<em>";
                                        italicFlag = true;
                                    }                          
                                } else {
                                    //console.log(char, pacFunc.decodeChar(char, codePage));
                                    //console.log(pacLine);
                                    if (pacFunc.decodeChar(char, codePage) === "j" && pacLine[0] === "00"){
                                        pacLine.shift();
                                    } else{
                                        //console.log(`${char} : ${pacFunc.decodeChar(char, codePage) || ""}`);
                                        ccEvent.text += pacFunc.decodeChar(char, codePage);
                                    }                                    
                                }
                            } else if (codePage === 28596){
                                if (pacLine[0] && pacFunc.specialArabicChars[char + pacLine[0]]){
                                    ccEvent.text += pacFunc.specialArabicChars[char + pacLine.shift()];
                                } else if (pacFunc.specialArabicChars[char]){
                                    ccEvent.text += pacFunc.specialArabicChars[char];
                                } else if (char === "3c" || char === "3e") {
                                    if (italicFlag){
                                        ccEvent.text += "</em>";
                                        italicFlag = false;
                                    } else {
                                        ccEvent.text += "<em>";
                                        italicFlag = true;
                                    }                          
                                } else {
                                    //console.log(char, pacFunc.decodeChar(char, codePage));
                                    //console.log(pacLine);
                                    if (pacFunc.decodeChar(char, codePage) === "j" && pacLine[0] === "00"){
                                        pacLine.shift();
                                    } else{
                                        //console.log(`${char} : ${pacFunc.decodeChar(char, codePage) || ""}`);
                                        ccEvent.text += pacFunc.decodeChar(char, codePage);
                                    }                                    
                                }
                            } else if (codePage === 28597){
                                if (pacLine[0] && pacFunc.specialGreekChars[char + pacLine[0]]){
                                    //console.log("DUAL CODE", char, pacLine[0], pacFunc.specialGreekChars[char + pacLine[0]]);
                                    ccEvent.text += pacFunc.specialGreekChars[char + pacLine.shift()];
                                } else if (pacFunc.specialGreekChars[char]){
                                    //console.log(pacFunc.specialGreekChars[char], char)
                                    //console.log("SINGLE CODE", char, pacFunc.specialGreekChars[char])
                                    ccEvent.text += pacFunc.specialGreekChars[char];
                                } else if (char === "3c" || char === "3e") {
                                    if (italicFlag){
                                        ccEvent.text += "</em>";
                                        italicFlag = false;
                                    } else {
                                        ccEvent.text += "<em>";
                                        italicFlag = true;
                                    }                          
                                } else {
                                    //console.log(char, pacFunc.decodeChar(char, codePage));
                                    //console.log(pacLine);
                                    if (pacFunc.decodeChar(char, codePage) === "j" && pacLine[0] === "00"){
                                        pacLine.shift();
                                    } else{
                                        //console.log(`${char} : ${pacFunc.decodeChar(char, codePage) || ""}`);
                                        //console.log("DEFAULT CODE", char, pacFunc.decodeChar(char, codePage));
                                        ccEvent.text += pacFunc.decodeChar(char, codePage);
                                    }                                    
                                }
                            } else if (codePage === 28598){
                                if (pacLine[0] && pacFunc.specialHebrewChars[char + pacLine[0]]){
                                    ccEvent.text += pacFunc.specialHebrewChars[char + pacLine.shift()];
                                } else if (pacFunc.specialHebrewChars[char]){
                                    ccEvent.text += pacFunc.specialHebrewChars[char];
                                } else if (char === "3c" || char === "3e") {
                                    if (italicFlag){
                                        ccEvent.text += "</em>";
                                        italicFlag = false;
                                    } else {
                                        ccEvent.text += "<em>";
                                        italicFlag = true;
                                    }                          
                                } else {
                                    //console.log(char, pacFunc.decodeChar(char, codePage));
                                    //console.log(pacLine);
                                    if (pacFunc.decodeChar(char, codePage) === "j" && pacLine[0] === "00"){
                                        pacLine.shift();
                                    } else{
                                        //console.log(`${char} : ${pacFunc.decodeChar(char, codePage) || ""}`);
                                        ccEvent.text += pacFunc.decodeChar(char, codePage);
                                    }                                    
                                }
                            } else if (codePage === 28599){
                                if (pacLine[0] && pacFunc.specialTurkishChars[char + pacLine[0]]){
                                    ccEvent.text += pacFunc.specialTurkishChars[char + pacLine.shift()];
                                } else if (pacFunc.specialTurkishChars[char]){
                                    ccEvent.text += pacFunc.specialTurkishChars[char];
                                } else if (char === "3c" || char === "3e") {
                                    if (italicFlag){
                                        ccEvent.text += "</em>";
                                        italicFlag = false;
                                    } else {
                                        ccEvent.text += "<em>";
                                        italicFlag = true;
                                    }                          
                                } else {
                                    //console.log(char, pacFunc.decodeChar(char, codePage));
                                    //console.log(pacLine);
                                    if (pacFunc.decodeChar(char, codePage) === "j" && pacLine[0] === "00"){
                                        pacLine.shift();
                                    } else{
                                        //console.log(`${char} : ${pacFunc.decodeChar(char, codePage) || ""}`);
                                        ccEvent.text += pacFunc.decodeChar(char, codePage);
                                    }                                    
                                }
                            }
                        }
                    }
                }
            }
        });

        /* Push Last ccEvent */
        if (codePage === 28596 || codePage === 58598){
            ccEvent.text = fixEnglishTextInRTLLanguage(ccEvent.text);
        }        

        ccEvent.text = convertToHtml(ccEvent.text);
        events.push(ccEvent);

        if (decodingOptions["Ignore Metadata"]) {
            try {
                if (tcLib.secToTc(events[0].start, options.frameRate) === "00:00:00:00" && tcLib.secToTc(events[0].end, options.frameRate) === "00:00:00:08") {
                    events.shift();
                }
            } catch (e) {
                console.log("Unable to detect metadata in PAC source");
            }
        }
        //console.log(events);
        return events;
    },

    encode: function (eventGroup, options) {
        throw new Error("PAC files are not supported for encoding by Closed Caption Converter.");
    },

    preProcess: {
        encode: function (eventGroup) {
            return events;
        },

        decode: function (input) {
            return input.toLowerCase();
        }
    },

    postProcess: {
        encode: function (output) {
            return output;
        },

        decode: function (eventGroup) {           
            eventGroup.events = eventGroup.events.filter(event => {
                event.text = event.text.replace(/äo/g, "ô").replace(/sº/g, "soe").replace("dummy end of file", "");
                //console.log(event.text);
                let plainText = convertToPlainText(event.text).toLowerCase();
                return plainText != "dummy end of file" && event.start != "" && event.end != "" && plainText != "";
            });
            //console.log(JSON.stringify(eventGroup));
            return eventGroup;
        }
    }

}
