const quillClasses = require("../../dict/quillClasses.js");
const formatTags = require("../../dict/formatTags.js");
const eol = require ('eol');
module.exports = function convertToHtml(text, classNames = null, boldTag = "b", italicTag = "i", underLineTag = "u"){
    let boldOpenPattern = new RegExp("<"+boldTag+">", "gmi"),
        boldClosePattern = new RegExp("</"+boldTag+">", "gmi"),
        italicOpenPattern = new RegExp("<"+italicTag+">", "gmi"),
        italicClosePattern = new RegExp("</"+italicTag+">", "gmi"),
        underlineOpenPattern = new RegExp("<"+underLineTag+">", "gmi"),
        underlineClosePattern = new RegExp("</"+underLineTag+">", "gmi");
    text = eol.lf(text).replace(boldOpenPattern, formatTags.bold.open)
            .replace(boldClosePattern, formatTags.bold.close)
            .replace(italicOpenPattern, formatTags.italics.open)
            .replace(italicClosePattern, formatTags.italics.close)
            .replace(underlineOpenPattern, formatTags.underline.open)
            .replace(underlineClosePattern, formatTags.underline.close);

    let textLines = text.split("\n").filter(line => {return line});
    //console.log(textLines);
    textLines.forEach((line,index,lines) => {
        if (line.match(/<u>/) && (line.match(/<u>/g)  || []).length !== (line.match(/<\/u>/g) || []).length) {
            lines[index] += "</u>";
        }

        if (line.match(/<em>/) && (line.match(/<em>/g)  || []).length !== (line.match(/<\/em>/g) || []).length) {
            lines[index] += "</em>";
        }            

        if (line.match(/<strong>/) && (line.match(/<strong>/g)  || []).length !== (line.match(/<\/strong>/g) || []).length) {
            lines[index] += "</strong>";
        }

        if (line.match(/<\/u>/) && (line.match(/<u>/g)  || []).length !== (line.match(/<\/u>/g) || []).length) {
            lines[index] = "<u>" + lines[index];
        }

        if (line.match(/<\/em>/) && (line.match(/<em>/g)  || []).length !== (line.match(/<\/em>/g) || []).length) {
            lines[index] = "<em>" + lines[index];
        }

        if (line.match(/<\/strong>/) && (line.match(/<strong>/g)  || []).length !== (line.match(/<\/strong>/g) || []).length) {
            lines[index] = "<strong>" + lines[index];
        }
    });

    let cleanedText = textLines.join(`</p><p class="${classNames ? classNames.join(" ") : quillClasses.align.center}">`).trim();
    
    return `<p class="${classNames ? classNames.join(" ") : quillClasses.align.center}">${cleanedText}</p>`;
}