const tcLib = require("../lib/timecode.js");
const removeInvalidEvents = require("../functions/eventGroups/removeInvalidEvents.js");
const getFormatOptions = require("../functions/helpers/getFormatOptions.js");
const convertToPlainText = require("../functions/quill/convertToPlainText.js");
const convertToPlainTextCustom = require("../functions/quill/convertToPlainTextCustom.js");
const eol = require("eol");
const htmlEntities = require('html-entities'); //Encode
const xmlFormat = require('xml-formatter'); //Encode
const xmlToJson = require('fast-xml-parser'); //Decode
const ttmlFunc = require("../functions/profiles/ttmlGeneral.js");
const htmlEncodePlainText = require("../functions/utility/htmlEncodePlainText.js");

const getRegion = function(index, style){
    let region = ttmlFunc.eventStyleMap[style];
    if (region === "pop"){
        return region+(index+1);
    } else if (region === "paint"){
        if (index === 0){
            return region;
        } else {
            return region+(index+1);
        }            
    } else {
        return region+(index+2);
    }
}

module.exports = {
    decode: function (input, options) {
        let events = [], displays = [], regions = {}, paragraphs = [];
        let fileJson = xmlToJson.parse(input, {
            ignoreAttributes: false,
            stopNodes: ["p"]
        });

        try {
            if (fileJson.tt.head.layout && fileJson.tt.head.layout.region){
                fileJson.tt.head.layout.region.forEach(region =>{
                    regions[region['@_xml:id']] = region;
                });
            }

        } catch(err){
            console.log("Unable to decode region data");
        }        

        if (Array.isArray(fileJson.tt.body.div)) {
            fileJson.tt.body.div.forEach(paragraphGroup =>{
                if (Array.isArray(paragraphGroup.p)) {
                    paragraphGroup.p.forEach(paragraph =>{
                        paragraphs.push(paragraph);
                    })
                } else {
                    paragraphs.push(paragraphGroup.p);
                }
            })
        } else {
            if (Array.isArray(fileJson.tt.body.div.p)){
                fileJson.tt.body.div.p.forEach(paragraph =>{
                    paragraphs.push(paragraph);
                }); 
            } else {
                paragraphs.push(fileJson.tt.body.div.p);
            }
        }

        paragraphs.forEach(paragraph => {
            let ccDisplay = new ttmlFunc.singleLine.Display();
            let start = tcLib.parseTcToSec(paragraph["@_begin"], options.frameRate);
            let end = tcLib.parseTcToSec(paragraph["@_end"], options.frameRate);
            let original = htmlEntities.decode(paragraph["#text"]);
            let text = convertToPlainText(original);
            let html = ttmlFunc.multiLine.formatText(original);
            let originX, originY, extentX, extentY, region;

            if (paragraph["@_tts:origin"]) {
                originX = parseFloat(paragraph["@_tts:origin"].split(" ")[0].replace("%", ""));
                originY = parseFloat(paragraph["@_tts:origin"].split(" ")[1].replace("%", ""));
            }

            if (paragraph["@_tts:extent"]) {
                extentX = parseFloat(paragraph["@_tts:extent"].split(" ")[0].replace("%", ""));
                extentY = parseFloat(paragraph["@_tts:extent"].split(" ")[1].replace("%", ""));
            }

            if (paragraph["@_region"] && regions) {
                region = regions[paragraph["@_region"]];
            }
            
            if (displays.length > 0 && displays[displays.length - 1].start === start) {
                displays[displays.length - 1].insertLine({
                    text: text,
                    html: html,
                    original: original,
                    extentX: extentX,
                    extentY: extentY,
                    originX: originX,
                    originY: originY,
                    region : region
                });
            } else {
                ccDisplay.start = start;
                ccDisplay.end = end;
                ccDisplay.insertLine({
                    text: text,
                    html: html,
                    original: original,
                    extentX: extentX,
                    extentY: extentY,
                    originX: originX,
                    originY: originY,
                    region : region
                });

                displays.push(ccDisplay);
            }
        });
        
        //console.log(JSON.stringify(displays, null, 4));
        displays.forEach(display => {
            events.push(ttmlFunc.singleLine.decodeDisplay(display,options.window));
        });
        
        //console.log(JSON.stringify(events, null, 4));
        return events;
    },
    encode: function (eventGroup, options) {
        let encodingOptions = getFormatOptions(options.formatOptions);
        let languageCode = encodingOptions["Language Code"] || "en";
        let fontFamily = encodingOptions["Font Family"] || "monospace";
        let fontSize = encodingOptions["Font Size"] || 80;
        let fontWeight =encodingOptions["Font Weight"] || "normal";
        let fontColor =encodingOptions["Font Color"] || "white";
        let backgroundColor = encodingOptions["Background Color"] || "black";
        let tcFormat = encodingOptions["Timecode Format"] || "smpte";


        let output = eol.after(`<?xml version="1.0" encoding="UTF-8"?>`);
        output += eol.after(`<tt xml:lang="${languageCode}"
        xmlns="http://www.w3.org/ns/ttml"
        xmlns:tts="http://www.w3.org/ns/ttml#styling"
        xmlns:ttm="http://www.w3.org/ns/ttml#metadata"
        xmlns:smpte="http://www.smpte-ra.org/schemas/2052-1/2010/smpte-tt"
        xmlns:m608="http://www.smpte-ra.org/schemas/2052-1/2010/smpte-tt#cea608"
        xmlns:ttp="http://www.w3.org/ns/ttml#parameter"
        ttp:timeBase="media" ttp:frameRate="${ttmlFunc.frameRateMap[options.frameRate]}" ttp:frameRateMultiplier="${ttmlFunc.frameRateMultiplierMap[options.frameRate]}">`);
        output += eol.after(`<head>`);
        output += eol.after(`<metadata>`);
        output += eol.after(`<ttm:desc>Closed Caption Converter - SMPTE-TT Captions (4x3)</ttm:desc>`);
        output += eol.after(`<smpte:information
        xmlns:m608="http://www.smpte-ra.org/schemas/2052-1/2010/smpte-tt#cea608"
        origin="http://www.smpte-ra.org/schemas/2052-1/2010/smpte-tt#cea608"
        mode="Preserved" m608:channel="CC1" m608:programName="Demo" m608:captionService="F1C1CC"
       />`);
        output += eol.after(`</metadata>`);
        output += eol.after(`<styling>
        <style xml:id='basic' tts:color='${fontColor}' tts:backgroundColor='${backgroundColor}' tts:fontFamily='${fontFamily}' tts:fontSize='${fontSize}%' tts:fontWeight="${fontWeight}"/>
       </styling>`);
        output += eol.after(`<layout>
        <region xml:id='pop1' tts:backgroundColor='transparent' tts:showBackground='whenActive'></region>
        <region xml:id='pop2' tts:backgroundColor='transparent' tts:showBackground='whenActive'></region>
        <region xml:id='pop3' tts:backgroundColor='transparent' tts:showBackground='whenActive'></region>
        <region xml:id='pop4' tts:backgroundColor='transparent' tts:showBackground='whenActive'></region>
        <region xml:id='paint' tts:backgroundColor='transparent' tts:showBackground='whenActive'></region>
        <region xml:id='paint2' tts:backgroundColor='transparent' tts:showBackground='whenActive'></region>
        <region xml:id='paint3' tts:backgroundColor='transparent' tts:showBackground='whenActive'></region>
        <region xml:id='paint4' tts:backgroundColor='transparent' tts:showBackground='whenActive'></region>
        <region xml:id='rollup2' tts:backgroundColor='transparent' tts:showBackground='whenActive'></region>
        <region xml:id='rollup3' tts:backgroundColor='transparent' tts:showBackground='whenActive'></region>
        <region xml:id='rollup4' tts:backgroundColor='transparent' tts:showBackground='whenActive'></region>
       </layout>`);
        output += eol.after(`</head>`);
        output += eol.after(`<body>`);
        output += eol.after(`<div>`);

        eventGroup.events.forEach(event =>{
            let start, end;
            if (tcFormat.toLowerCase() === "smpte"){
                start = tcLib.secToTc(event.start, options.frameRate);
                end = tcLib.secToTc(event.end, options.frameRate);
            } else {
                start = tcLib.secToTcMs(event.start);
                end = tcLib.secToTcMs(event.end);
            }

            let plainTextCustom = convertToPlainTextCustom(event.text);

            eol.split(plainTextCustom).forEach((textLine, index, textLines) =>{
                let region = getRegion(index, event.style);
                let extents = ttmlFunc.singleLine.calcExtents(textLine, parseFloat(fontSize));
                let origins = ttmlFunc.singleLine.calcOrigin(event, textLine, index, textLines.length, parseFloat(fontSize), options.window); 
                
                let text = htmlEncodePlainText(textLine);
                output += `<p region='${region}' style='basic' begin='${start}' end='${end}' tts:origin='${origins}' tts:extent='${extents}'>${ttmlFunc.singleLine.convertToTtml(text)}</p>`;
            });
        });
        
        output += eol.after(`</div>`);
        output += eol.after(`</body>`);
        output += eol.after(`</tt>`);
        return xmlFormat(output);
    },
    preProcess: {
        encode: function (eventGroup) {
            return removeInvalidEvents(eventGroup);
        },

        decode: function (input) {
            return eol.lf(input.trim());
        }
    },

    postProcess: {
        encode: function (output) {
            return output;
        },

        decode: function (eventGroup) {
            return eventGroup;
        }
    },
}