const sccLookup = require('../../dict/608.js');
const Event = require("../../classes/event.js")
const convertToHtml = require("../quill/convertToHtml.js");
const quillClasses = require("../../dict/quillClasses.js");
const convertToPlainTextCustom = require('../quill/convertToPlainTextCustom.js');
const convertToPlainText = require('../quill/convertToPlainText.js');
const stripTags = require("../quill/stripTags.js");
const eol = require("eol");
const findCenter = require("../utility/findCenter.js");
const getLongestLine = require("../utility/getLongestLine.js");
const getShortestLine = require("../utility/getShortestLine.js");

module.exports = {
    colorMapping: {
        "WHITE": "#FFFFFF",
        "GREEN": "#008000",
        "BLUE": "#0000FF",
        "CYAN": "#00FFFF",
        "RED": "#FF0000",
        "YELLOW": "#FFFF00",
        "MAGENTA": "#FF00FF",
        "BLACK": "#000000",
        "NONE": "",
    },
    Display: class {
        constructor(options = {
            style: "Pop-On",
            start: false,
            end: false,
            lines: []
        }) {
            this.style = options.style || "Pop-On",
                this.start = options.start || false,
                this.end = options.end || false,
                this.lines = options.lines || []
        }

        insertLine(options = {
            text: "",
            xPos: 1,
            yPos: 1,
            italics: false,
            underline: false,
            bold: false,
            color: "#FFFFFF",
            background: "#000000",
            opacity: 1
        }) {
            this.lines.push({
                text: options.text || "",
                xPos: options.xPos, //0-31
                yPos: options.yPos, //0-14 
                italics: options.italics,
                underline: options.underline,
                bold: options.bold,
                color: options.color || "#FFFFFF",
                background: options.background || "#000000",
                opacity: options.opacity || 1
            });
        }
    },
    decodeDisplay: function (display, win) {
        let ccEvent = new Event({
            style: display.style,
            start: display.start,
            end: display.end
        });

        let position = this.getPositionFromDisplay(JSON.parse(JSON.stringify(display.lines)), win);
        ccEvent.text = convertToHtml(this.getTextFromDisplay(display.lines), [quillClasses.align[position.alignment]]);
        if (!ccEvent.text) { return false; }
        //console.log(position);

        ccEvent.alignment = position.alignment;
        ccEvent.xPos = position.xPos;
        ccEvent.yPos = position.yPos;
        ccEvent.xOffset = position.xOffset;
        ccEvent.yOffset = position.yOffset;

        return ccEvent;
    },
    getTextFromDisplay: function (lines) {
        if (lines.length === 0) { return false }
        let text = "", yPos;

        lines.filter(line => {
            return line.text;
        }).sort((lineA, lineB) => {
            return lineA.yPos - lineB.yPos || lineA.xPos - lineB.xPos;
        }).forEach(line => {
            if (line.yPos === yPos) {
                text += " " + line.text;
            } else {
                text += "\n" + line.text;
            }

            if (line.text.match(/<em>/) && (line.text.match(/<em>/g) || []).length !== (line.text.match(/<\/em>/g) || []).length) {
                text += "</em>"
            }

            if (line.text.match(/<u>/) && (line.text.match(/<u>/g) || []).length !== (line.text.match(/<\/u>/g) || []).length) {
                text += "</u>"
            }

            yPos = line.yPos;
        });

        return text.trim();
    },
    getPositionFromDisplay: function (lines, win) {
        let position = {
            xPos: "center",
            yPos: "end",
            xOffset: 0,
            yOffset: win.height * -0.10,
            alignment: "center"
        }, details;

        /* Sort */
        lines = lines.filter(line => { return line.text }).sort((lineA, lineB) => {
            return lineA.yPos - lineB.yPos || lineA.xPos - lineB.xPos;
        });

        //console.log(lines);

        lines = this.organizeLines(lines);
        details = this.getHorzDetails(lines);
        
        if (details.length === 0){
            return position;
        }

        position.alignment = this.getAlignmentFromDetails(details);

        let highestYPos = Math.max.apply(Math, lines.map(function (line) { return line.yPos; }));
        let lowestYPos = Math.min.apply(Math, lines.map(function (line) { return line.yPos; }));
        let lowestXPos = Math.min.apply(Math, lines.map(function (line) { return line.xPos; }));
        let highestEnd = Math.max.apply(Math, details.map(function (detail) { return detail.end; }));

        /* Calculate Y (vertical) Position */
        if (lowestYPos > 10) {
            position.yPos = "end";
            position.yOffset = ((19-(highestYPos + 3))/-19) * win.height;
        } else if (lowestYPos < 6) {
            position.yPos = "start"
            position.yOffset = ((lowestYPos + 3 )/19) * win.height;
        } else {
            position.yPos = "center";
            position.yOffset = (((lowestYPos + 3) - findCenter(19, lines.length)) / 19) * win.height;
        }

        /* Calculate X (horizontal) Position */
        if (position.alignment === "left") {
            if (lowestXPos > 13) {
                position.xPos = "end";
                position.xOffset = ((40-(highestEnd+5))/40) * win.width;
            } else {
                position.xPos = "start";
                position.xOffset = ((lowestXPos+5) / 40) * win.width;
            }
        } else if (position.alignment === "center") {
            position.xPos = "center";
            position.xOffset = 0;
        } else {
            if (lowestXPos > 13) {
                position.xPos = "end";
                position.xOffset = ((40-(highestEnd+5))/40) * win.width;
            } else {
                position.xPos = "start";
                position.xOffset = ((lowestXPos+5) / 40) * win.width;
            }
        }

        // console.log("---------------")
        // console.log("Metrics:");
        // console.log(JSON.stringify({
        //     "LowestY" : lowestYPos,
        //     "HighestY" : highestYPos,
        //     "LowestX" : lowestXPos,
        //     "HighestEnd" : highestEnd,
        // },null,4))

        // console.log("Lines:");
        // console.log(lines);

        // console.log("Details:");
        // console.log(details);

        // console.log("Position:")
        // console.log(JSON.stringify(position, 4));
        return position;
    },
    organizeLines: function (lines) {
        let sameLineTest = true, yPos;
        while (sameLineTest) {
            sameLineTest = false;
            yPos = undefined;

            lines.forEach((line, index, lines) => {
                lines[index].text = lines[index].text.replace(/<em>|<u>/g, "");
                if (line.yPos === yPos) {
                    lines[index - 1].text += " " + line.text.trim();
                    lines[index].text = "";
                    sameLineTest = true;
                } else {
                    yPos = line.yPos;
                }
            });

            lines = lines.filter(line => {
                return line.text;
            });
        }

        return lines;
    },
    getHorzDetails: function (lines) {
        //console.log(lines);
        return lines.map(line => {
            let alignment;
            let text = this.replaceMusicNotes(line.text);
            let center = findCenter(32, text.length);
            if (line.xPos > center - 2 && line.xPos < center + 2) {
                alignment = "center";
            } else if (line.xPos > center) {
                alignment = "right";
            } else {
                alignment = "left";
            }
            return {
                text: line.text,
                xPos: line.xPos,
                length: text.length,
                alignment: alignment,
                end: line.xPos + text.length
            }
        });
    },
    getAlignmentFromDetails: function (details) {
        //console.log(details);
        if (details.length === 1) {
            return details[0].alignment;
        }

        let xPosSame = details.every(detail => {
            return detail.xPos === details[0].xPos;
        });

        let endSame = details.every(detail => {
            return detail.end === details[0].end;
        });

        let alignmentCenter = details.every(detail => {
            return detail.alignment === "center";
        });

        if (alignmentCenter) {
            return "center";
        } else if (xPosSame) {
            return "left";
        } else if (endSame) {
            return "right";
        } else {
            return "center";
        }
    },
    getEventDetails: function (event) {
        let eventTextPlainFormat = convertToPlainTextCustom(event.text);
        let eventTextPlain = convertToPlainText(event.text);
        let longestLine = getLongestLine(eventTextPlain);
        let shortestLine = getShortestLine(eventTextPlain);
        let details = {
            text: eventTextPlainFormat,
            lines: eol.split(eventTextPlainFormat),
            plainText: eventTextPlain,
            longestLine: longestLine,
            shortestLine: shortestLine,
            alignment: event.alignment,
            xPos: event.xPos,
            yPos: event.yPos,
            xOffset: event.xOffset,
            yOffset: event.yOffset,
            style: event.style,
            start: event.start,
            end: event.end
        }
        //console.log(details);
        return details;
        /* NOTES:
            xOffset : parseInt((event.xOffset/win.width)*100),
            yOffset : parseInt((event.yOffset/win.height)*100) 
        */
    },
    getCodeByCmd: function (codes, cmd) {
        let sccCode = Object.keys(codes).find(code => codes[code] === cmd);
        if (!sccCode) {
            if (cmd.charCodeAt(0) === 8217) {
                return "a7"
            }
        }

        return sccCode || "80";
    },
    replaceSpecialChars: function (text) {
        return text
            .replace(/À/g, "AÀ")
            .replace(/Â/g, "AÂ")
            .replace(/Ç/g, "CÇ")
            .replace(/É/g, "EÉ")
            .replace(/È/g, "EÈ")
            .replace(/Ê/g, "EÊ")
            .replace(/Ë/g, "EË")
            .replace(/ë/g, "eë")
            .replace(/Î/g, "IÎ")
            .replace(/Ï/g, "IÏ")
            .replace(/ï/g, "iï")
            .replace(/Ô/g, "OÔ")
            .replace(/Ù/g, "UÙ")
            .replace(/ù/g, "uù")
            .replace(/Û/g, "UÛ")
            .replace(/Ú/g, "UÚ")
            .replace(/Ã/g, "AÃ")
            .replace(/ã/g, "aã")
            .replace(/Í/g, "IÍ")
            .replace(/Ì/g, "IÌ")
            .replace(/ì/g, "iì")
            .replace(/Ò/g, "oÒ")
            .replace(/ò/g, "oò")
            .replace(/Õ/g, "OÕ")
            .replace(/õ/g, "oõ")
            .replace(/Ä/g, "AÄ")
            .replace(/¿/g, "?¿")
            .replace(/ä/g, "aä")
            .replace(/Ö/g, "OÖ")
            .replace(/ö/g, "oö")
            .replace(/Å/g, "AÅ")
            .replace(/å/g, "aå")
            .replace(/‘/g, "'‘")
            .replace(/♪/g, "♪♪");
    },
    getSccPosition: function (textLine, details, lineIndex, win) {
        let position = { posCode: false, tabCode: false };
        let xPos = this.getXPos(textLine, details, win);
        let yPos = this.getYPos(stripTags(textLine), details, lineIndex, win);
        let tabs = xPos % 4;
        let column = xPos - tabs;
        //console.log("Col:",column,tabs,xPos);
        column = column > 0 ? column - 1 : column;
        column = column.toString().padStart(2, '0');
        position.tabCode = "{TAB0" + tabs + "}";
        position.posCode = "{" + yPos.toString().padStart(2, '0') + "_" + column.toString().padStart(2, '0') + "}";
        //console.log(position);
        return position;
    },
    getXPos: function (textLine, details, win) {
        let xPos = 0;
        let italics = textLine.match(/<em>/g);
        let textLineStripped = stripTags(textLine);
        //console.log(details);
        if (details.xPos === "start") {
            if (details.alignment === "center") {               
                xPos = findCenter(details.longestLine.length,textLineStripped.length) + (((details.xOffset/win.width) * 40) - 4) ;
            } else if (details.alignment === "right") {
                xPos = (details.longestLine.length - textLineStripped.length) + (((details.xOffset/win.width) * 40) - 4) - (italics ? 1 : 0);
            } else {
                /* Left Alignment */                
                xPos = parseInt(((details.xOffset/win.width) * 40) - 5) - (italics ? 1 : 0);
            }
        } else if (details.xPos === "center") {
            xPos = findCenter(32, textLineStripped.length) + ((details.xOffset/win.width) * 40) + 1;
        } else {
            /* xPos = end */
            if (details.alignment === "center") {   
                xPos = (32-((((details.xOffset/win.width) * -40) - 4) + details.longestLine.length)) + findCenter(details.longestLine.length,textLineStripped.length);              
            } else if (details.alignment === "right") {
                xPos = (32-(italics ? textLineStripped.length+1 : textLineStripped.length)) + ((details.xOffset/win.width) * 40);
            } else {
                /* Left Alignment */
                xPos = (32- (italics ? details.longestLine.length+1 : details.longestLine.length)) + (((details.xOffset/win.width) * 40) + 4);
            }
        }

        //console.log(xPos,textLineStripped, textLineStripped.length);

        /* Safety check to make sure line doesn't go off screen */
        if ((xPos + textLineStripped.length) > 32) {
            xPos = 32 - (textLineStripped.length + (italics ? 2 : 0));
        }

        /* Safety check to make sure line doesn't start before screen */
        xPos = Math.max(0, xPos);
        return parseInt(xPos);
    },
    getYPos: function (textLine, details, lineIndex, win) {
        /* Total Height of Picture is 19 boxes (15 + 4 padding) */
        let yPos = 0;
        if (details.yPos === "start") {
            yPos = lineIndex + ((details.yOffset/win.height)*19) - 2;
        } else if (details.yPos === "center") {
            yPos = parseInt(findCenter(15, details.lines.length)) + lineIndex;
        } else {
            /* End */
            yPos = 19 + ((details.yOffset/win.height)*19) - (details.lines.length+lineIndex);
        }

        /* Check to make sure line isn't past where it should be */
        if (14 - (details.lines.length - lineIndex) < yPos){
            yPos = 15 - (details.lines.length - lineIndex);
        } else if (lineIndex > yPos){
            yPos = lineIndex;
        }

        yPos = parseInt(yPos);
        /* Safety check to make sure line doesn't go off screen */
        if (yPos > 14) {
            yPos = 14;
        }

        yPos = Math.max(0, yPos);
        return yPos;
    },    
    getPositionCodes: function (textLine, details, lineIndex, channel, win) {
        //console.log(details, lineIndex);
        let positionCodes = [];
        if (details.style === "Roll-Up 2") {
            positionCodes.push(this.getCodeByCmd(sccLookup[channel], "{ROLLUP2}"));
            positionCodes.push(this.getCodeByCmd(sccLookup[channel], "{NEW LINE}"));
            let position = this.getSccPosition(textLine, details, lineIndex, win);
            positionCodes.push(this.getCodeByCmd(sccLookup[channel], position.posCode));
        } else if (details.style === "Roll-Up 3") {
            positionCodes.push(this.getCodeByCmd(sccLookup[channel], "{ROLLUP3}"));
            positionCodes.push(this.getCodeByCmd(sccLookup[channel], "{NEW LINE}"));
            let position = this.getSccPosition(textLine, details, lineIndex, win);
            positionCodes.push(this.getCodeByCmd(sccLookup[channel], position.posCode));

        } else if (details.style === "Roll-Up 4"){
            positionCodes.push(this.getCodeByCmd(sccLookup[channel], "{ROLLUP4}"));
            positionCodes.push(this.getCodeByCmd(sccLookup[channel], "{NEW LINE}"));
            let position = this.getSccPosition(textLine, details, lineIndex, win);
            positionCodes.push(this.getCodeByCmd(sccLookup[channel], position.posCode));

        } else if (details.style === "Paint-On") {
            if (lineIndex === 0) {
                positionCodes.push(this.getCodeByCmd(sccLookup[channel], "{CLEAR DISPLAY}"));
                positionCodes.push(this.getCodeByCmd(sccLookup[channel], "{PAINT ON}"));
            }

            let position = this.getSccPosition(textLine, details, lineIndex, win);
            positionCodes.push(this.getCodeByCmd(sccLookup[channel], position.posCode));

            if (position.tabCode !== "{TAB00}") {
                positionCodes.push(this.getCodeByCmd(sccLookup[channel], position.tabCode));
            }

        } else {
            /* Pop-On */
            //console.log(details);
            positionCodes.push(this.getCodeByCmd(sccLookup[channel], "{RESUME LOADING}"));
            let position = this.getSccPosition(textLine, details, lineIndex, win);
            // console.log(position,details);
            positionCodes.push(this.getCodeByCmd(sccLookup[channel], position.posCode));

            if (position.tabCode !== "{TAB00}") {
                positionCodes.push(this.getCodeByCmd(sccLookup[channel], position.tabCode));
            }
        }
        //console.log(positionCodes);
        return positionCodes;
    },
    encodeEvent: function (details, channel = "ch01", win) {
        let cmds = [];
        //console.log("-----");
        //console.log(details);
        details.lines.forEach((line, index) => {
            line = this.replaceSpecialChars(line);
            let positionCmds = this.getPositionCodes(line, details, index, channel, win);
            //console.log(line, positionCmds);
            positionCmds.forEach(cmd => {
                cmds.push(cmd);
            })

            let chars = line.split("");
            while (chars.length > 0) {
                let char = chars.shift();
                if (char === "<") {
                    if (this.isUnderlineItalicOpenTag(chars)) {
                        chars.splice(0, 6);
                        cmds.push(this.getCodeByCmd(sccLookup[channel], "{ITALICS_UNDERLINE}"));
                    } else if (this.isStrongOpenTag(chars)) {
                        chars.splice(0, 7);
                        cmds.push(this.getCodeByCmd(sccLookup[channel], "{ITALICS}"));
                    } else if (this.isItalicsOpenTag(chars)) {
                        chars.splice(0, 3);
                        cmds.push(this.getCodeByCmd(sccLookup[channel], "{ITALICS}"));
                    } else if (this.isUnderlineOpenTag(chars)) {
                        chars.splice(0, 2);
                        cmds.push(this.getCodeByCmd(sccLookup[channel], "{COLOR:WHITE;UNDERLINE}"));
                    } else if (this.isUnderlineItalicCloseTag(chars)) {
                        chars.splice(0, 8);
                        cmds.push(this.getCodeByCmd(sccLookup[channel], "{COLOR:WHITE}"));
                    } else if (this.isStrongCloseTag(chars)) {
                        chars.splice(0, 8);
                        cmds.push(this.getCodeByCmd(sccLookup[channel], "{COLOR:WHITE}"));
                    } else if (this.isItalicsCloseTag(chars)) {
                        chars.splice(0, 4);
                        cmds.push(this.getCodeByCmd(sccLookup[channel], "{COLOR:WHITE}"));
                    } else if (this.isUnderlineCloseTag(chars)) {
                        chars.splice(0, 3);
                        cmds.push(this.getCodeByCmd(sccLookup[channel], "{COLOR:WHITE}"));
                    } else {
                        cmds.push(this.getCodeByCmd(sccLookup[channel], char))
                    }
                } else {
                    cmds.push(this.getCodeByCmd(sccLookup[channel], char))
                }

            }
        });

        return cmds;
    },
    formatEncodedCmds: function (encodedCmds) {
        let cmdString = "";
        for (let i = 0; i < encodedCmds.length; i++) {
            if (encodedCmds[i].length === 4) {
                cmdString += " " + encodedCmds[i];
            } else if (encodedCmds[i + 1] && encodedCmds[i].length === 2 && encodedCmds[i + 1].length === 2) {
                cmdString += " " + encodedCmds[i] + encodedCmds[++i];
            } else if (encodedCmds[i].length === 2 && (!encodedCmds[i + 1] || encodedCmds[i + 1].length === 4)) {
                cmdString += " " + encodedCmds[i] + "80";
            }
        }

        return cmdString.trim();
    },
    calculateEncodeTime: function (encodedTextString) {
        return encodedTextString.split(" ").length;
    },
    verifyFormatting: function (event, win) {
        let result = true;
        let xOffset = ((event.xOffset/win.width)*40);
        //console.log("------");
        let text = event.text.replace(/<em>/g,"i").replace(/<\/em>/g,"").replace(/<u>/g,"u").replace(/<\/u>/g,"").replace(/<b>/g,"b").replace(/<\/b>/g,"");

        eol.split(convertToPlainText(text)).forEach(textLine => {     
            //console.log(textLine, textLine.length, xOffset,textLine.length + xOffset + 4);     
            if (textLine.length + xOffset + 4 > 40) {
                result = false;
            }
        });

        return result;
    },
    replaceMusicNotes: function (line) {
        let matches = line.match(/♪+/g);
        if (!matches){return line};
        matches.forEach(noteMatch => {
            let regexPat = new RegExp(noteMatch);
            let musicNotes = Math.ceil(noteMatch.length / 2);
            line = line.replace(regexPat, "♪".repeat(musicNotes));
        });
        
        return line;
    },
    isStrongOpenTag: function (chars) {
        return chars.slice(0, 7).join("") === "strong>";
    },
    isItalicsOpenTag: function (chars) {
        return chars.slice(0, 3).join("") === "em>"
    },
    isUnderlineOpenTag: function (chars) {
        return chars.slice(0, 2).join("") === "u>"
    },
    isUnderlineItalicOpenTag: function (chars) {
        return chars.slice(0, 6).join("") === "em><u>" || chars.slice(0, 6).join("") === "u><em>"
    },
    isStrongCloseTag: function (chars) {
        return chars.slice(0, 8).join("") === "/strong>"
    },
    isItalicsCloseTag: function (chars) {
        return chars.slice(0, 4).join("") === "/em>";
    },
    isUnderlineCloseTag: function (chars) {
        return chars.slice(0, 3).join("") === "/u>"
    },
    isUnderlineItalicCloseTag: function (chars) {
        return chars.slice(0, 8).join("") === "/em></u>" || chars.slice(0, 8).join("") === "/u></em>"
    }
}