const Event = require("../classes/event.js");
const tcLib = require("../lib/timecode.js");
const getFormatOptions = require("../functions/helpers/getFormatOptions.js");
const cavenaFunc = require('../functions/profiles/cavena890.js');
const convertToHtml = require("../functions/quill/convertToHtml.js");

module.exports = {
    decode: function (input, options) {
        let events = [],
            textLength = 51,
            language = 'english',
            startFrame,
            endFrame,
            decodingOptions = getFormatOptions(options.formatOptions);

        let hexPairs = input.match(/(..?)/g);
        let primaryLang=  cavenaFunc.languageCodes[hexPairs[146]];
        let secondaryLang = cavenaFunc.languageCodes[hexPairs[147]];
        let primaryFontName = cavenaFunc.hexToAscii(hexPairs.slice(187, 193).join(""));
        let secondaryFontName = cavenaFunc.hexToAscii(hexPairs.slice(246, 252).join(""));

        /* console.log("Languages: ", primaryLang, secondaryLang);
        console.log("Fonts: ", primaryFontName,secondaryFontName); */

        /* Hebrew */
        if (primaryFontName.toLowerCase() === "hebnoa" || secondaryFontName.toLowerCase() === "hebnoa" || primaryLang === "hebrew"){
            language = "hebrew";
        } 
        
        /* Arabic */
        else if (primaryFontName.toLowerCase() === "arabic" || secondaryFontName.toLowerCase() === "arabic" || secondaryLang === "arabic"){
            language = "arabic";
        }

        /* Russian */
        else if (primaryFontName.toLowerCase().startsWith("kyril") || secondaryFontName.toLowerCase().startsWith("kyril") || primaryLang === "russian"){
            language = "russian";
        }

        /* chinese simplified */
        else if (primaryLang === "chineseSimplified"){
            language = "chineseSimplified";
        }

        /* chinese traditional */
        else if (primaryLang === "chineseTraditional"){
            language = "chineseTraditional";
        }

        //console.log("LANGUAGE: ",language)

        let i = 455;
        let start;
        let lastSubtitleId = -1;
        let lineA, lineB, currentSubtitleId;
        while (i < hexPairs.length - 20){
            //console.log("---------------");
            start = i - textLength;
            currentSubtitleId = hexPairs[start - 16] + hexPairs[start - 15]
            //console.log("Current Subtitle Id: ", currentSubtitleId);
            
            startFrame = cavenaFunc.calcTotalFrames(hexPairs[start-14], hexPairs[start-13],hexPairs[start-12]);
            endFrame = cavenaFunc.calcTotalFrames(hexPairs[start-11], hexPairs[start-10],hexPairs[start-9]);

            /* TC Troubleshooting */
            //console.log(startFrame, tcLib.frameToTc(startFrame, options.frameRate));
            //console.log(endFrame, tcLib.frameToTc(endFrame, options.frameRate));

            lineA = hexPairs.slice(start, start+textLength).filter(char =>{return char !== '7f' && char !== 'be'});
            lineB = hexPairs.slice(start+textLength+6, start+textLength+6+textLength).filter(char =>{return char !== '7f' && char !== 'be'});

            //console.log(lineA, lineB);

            events.push(new Event({
                start : tcLib.framesToSec(startFrame, options.frameRate),
                end : tcLib.framesToSec(endFrame, options.frameRate),
                text : convertToHtml(cavenaFunc.hexToText(lineA, lineB, primaryLang, secondaryLang)),
                xOffset : 0,
                yOffset : options.window.height * -0.10
            }));
            
            lastSubtitleId = currentSubtitleId;
            i += 128;
        }
        return events;
    },

    encode: function (eventGroup, options) {
        throw new Error("PAC files are not supported for encoding by Closed Caption Converter.");
    },

    preProcess: {
        encode: function (eventGroup) {
            return events;
        },

        decode: function (input) {
            return input.toLowerCase();
        }
    },

    postProcess: {
        encode: function (output) {
            return output;
        },

        decode: function (eventGroup) {           
            return eventGroup;
        }
    }

}
