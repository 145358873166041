const Event = require("../classes/event.js");
const tcLib = require("../lib/timecode.js");
const removeInvalidEvents = require("../functions/eventGroups/removeInvalidEvents.js");
const getFormatOptions = require("../functions/helpers/getFormatOptions.js");
const convertToHtml = require("../functions/quill/convertToHtml.js");
const convertToPlainText = require("../functions/quill/convertToPlainText.js");
const convertToPlainTextCustom = require("../functions/quill/convertToPlainTextCustom.js");
const eol = require("eol");

module.exports = {
    decode : function(input, options){
        let events = [], eventId, tcInfo;
        let subtitleBlocks = input.split("\n\n");
        subtitleBlocks.forEach(block => {
            let ccEvent = new Event({
                xOffset : 0,
                yOffset : options.window.height * -0.10
            });
            
            let blockInfo = block.split("\n").filter(blockLine => {
                return blockLine !== "";
            });

            if (blockInfo.length > 1){
                eventId = blockInfo.shift();
                tcInfo = blockInfo.shift();
                ccEvent.start = tcLib.tcMsToSec(tcInfo.split(" --> ")[0]);
                ccEvent.end = tcLib.tcMsToSec(tcInfo.split(" --> ")[1]);
                ccEvent.text = convertToHtml(blockInfo.join("\n"));
                events.push(ccEvent);
            }
        });
        return events;
    },

    encode : function(eventGroup, options){
        let output = "",
            encodingOptions = getFormatOptions(options.formatOptions);
        eventGroup.events.forEach(function(event, id){
            try {
                output += (id+1) + "\n";
                output += tcLib.secToTcMs(event.start) + " --> " + tcLib.secToTcMs(event.end) + "\n";
                if (encodingOptions["Encode Formatting"] && encodingOptions["Encode Formatting"] != "false"){
                    output += convertToPlainTextCustom(event.text, "\n", false, "b", "i", "u") + "\n\n";
                } else {
                    output += convertToPlainText(event.text) + "\n\n";
                }          
            } catch(e){
                console.log(e, e.message);
            }
              
        });

        return output.trim();
    },

    preProcess : {
        encode : function(eventGroup){
            return removeInvalidEvents(eventGroup);
        },

        decode : function(input){
            return (eol.lf(input).trim()).replace(/'(\n){3,}'/gim, "\n\n");
        }
    },

    postProcess : {
        encode : function(output){
            return output.replace(new RegExp('(\n){3,}', 'gim'), "\n\n").replace(/\n\n(?=\D)/gmi,"\n");
        },

        decode : function(eventGroup){
            return eventGroup;
        }
    },

}