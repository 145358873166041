const uuidv4 = require('uuid').v4;
module.exports = class EventGroup {
    constructor (options = {
        name: "Untitled", /* Event group name */
        type: "subtitle", /* subtitle, transcription, translation, or audio description */
        originalFontFamily: "arial",        
        events: [],
        overlap : false,
        maxCps : 9999,
        maxWpm : 9999,
        maxChars : 9999,
        maxLines : 9999,
        minDuration : 0.0001,
        maxDuration : 9999,
        illegalChars : false,
        language: "en-US", /* event group language */
        ad : {
            gender : "MALE",
            language : "en-US",
            voice : {
                "provider":"google",
                "name":"en-US-Wavenet-A",
                "styles":["default"],
                "gender":"Male",
                "language":"en-US"
            }
        }, //AD Settings
        rtl : false, /* Right To Left Support */       
        metadata : []
    }) {
        this.id = uuidv4();
        this.type = options.type || "subtitle";
        this.originalFontFamily = options.originalFontFamily || "arial",
        this.name = options.name || "Untitled";
        this.events = options.events || [];
        this.overlap = options.overlap;
        this.maxCps = options.maxCps || 9999;
        this.maxWpm = options.maxWpm || 9999;
        this.maxChars = options.maxChars || 9999;
        this.maxLines = options.maxLines || 9999;
        this.minDuration = options.minDuration || 0.0001;
        this.maxDuration = options.maxDuration || 9999;
        this.illegalChars = options.illegalChars;
        this.language = options.language || "en-US";
        this.ad = options.ad || {
            gender : "MALE",
            language : "en-US",
            voice : {
                "provider":"google",
                "name":"en-US-Wavenet-A",
                "styles":["default"],
                "gender":"Male",
                "language":"en-US"
            }
        };
        this.rtl = options.rtl;
        this.metadata = options.metadata;
    }
}