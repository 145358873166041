const Event = require("../classes/event.js");
const tcLib = require("../lib/timecode.js");
const removeInvalidEvents = require("../functions/eventGroups/removeInvalidEvents.js");
const getFormatOptions = require("../functions/helpers/getFormatOptions.js");
const convertToHtml = require("../functions/quill/convertToHtml.js");
const convertToPlainText = require("../functions/quill/convertToPlainText.js");
const convertToPlainTextCustom = require("../functions/quill/convertToPlainTextCustom.js");
const eol = require("eol");
const xmlFormat = require('xml-formatter'); //Encode
const xmlToJson = require('fast-xml-parser'); //Decode
const ttmlFunc = require("../functions/profiles/ttmlGeneral.js");
const flexbox = require("../dict/flexbox.js");
const htmlEncodePlainText = require("../functions/utility/htmlEncodePlainText.js");

module.exports = {
    decode: function (input, options) {
        let events = [], ccEvent;
        let fileJson = xmlToJson.parse(input, {
            ignoreAttributes: false,
            stopNodes: ["p"] //combines span and paragraph tags together
        });
        //console.log(JSON.stringify(fileJson, null, 4));
        fileJson.tt.body.div.p.forEach(paragraph => {
            ccEvent = new Event({
                xOffset : 0,
                yOffset : options.window.height * -0.10
            });
            ccEvent.start = tcLib.tcMsToSec(paragraph['@_begin']);
            ccEvent.end = tcLib.tcMsToSec(paragraph['@_end']);
            let position = paragraph["@_tts:origin"];

            if (position) {
                let vPos = parseInt(position.split(" ")[1].replace("%", ""));
                if (vPos < 25) {
                    ccEvent.yPos = flexbox.alignmentMap["top"];
                    ccEvent.yOffset = options.window.height * 0.10;
                } else if (vPos < 70) {
                    ccEvent.yPos = flexbox.alignmentMap["center"];
                    ccEvent.yOffset = 0;
                } else {
                    ccEvent.yPos = flexbox.alignmentMap["bottom"];
                }
            }

            if (events.length > 0 && events[events.length - 1].start === ccEvent.start && events[events.length - 1].end === ccEvent.end) {
                ccEvent.yPos = events[events.length - 1].yPos;
                ccEvent.text = events[events.length - 1].text;
                events.pop();
            }

            let textLines = paragraph["#text"].split("</span>");
            textLines.forEach(line => {
                if (/textDecoration/.test(line) && /bold/.test(line)) {
                    ccEvent.text += "<strong>" + convertToPlainText(line) + "</strong>" + "\n";
                } else if (/textDecoration/.test(line) && /underline/.test(line)) {
                    ccEvent.text += "<u>" + convertToPlainText(line) + "</u>" + "\n";
                } else if (/fontStyle/.test(line) && /italic/.test(line)) {
                    ccEvent.text += "<em>" + convertToPlainText(line) + "</em>" + "\n";
                } else {
                    ccEvent.text += convertToPlainText(line) + "\n";
                }
            });

            events.push(ccEvent);
        });

        return events;
    },

    encode: function (eventGroup, options) {
        let encodingOptions = getFormatOptions(options.formatOptions);
        let output = eol.after(`<?xml version="1.0" encoding="UTF-8"?>`);
        output += eol.after(`<tt xml:lang="${encodingOptions["Language Code"] || "en"}"
        xmlns="http://www.w3.org/ns/ttml"
        xmlns:tts="http://www.w3.org/ns/ttml#styling"
        xmlns:ttm="http://www.w3.org/ns/ttml#metadata"
        xmlns:smpte="http://www.smpte-ra.org/schemas/2052-1/2010/smpte-tt"
        xmlns:m608="http://www.smpte-ra.org/schemas/2052-1/2010/smpte-tt#cea608"
        ttp:timeBase="media" ttp:frameRate="${ttmlFunc.frameRateMap[options.frameRate]}" ttp:frameRateMultiplier="${ttmlFunc.frameRateMultiplierMap[options.frameRate]}">`);
        output += eol.after(`<head>`);
        output += eol.after(`<metadata>
        <ttm:title>${encodingOptions["Title"] || "Untitled"}</ttm:title>
        <ttm:desc>${encodingOptions["Description"] || ""}</ttm:desc>
        <smpte:information
         xmlns:m608="http://www.smpte-ra.org/schemas/2052-1/2010/smpte-tt#cea608"
         origin="http://www.smpte-ra.org/schemas/2052-1/2010/smpte-tt#cea608"
         mode="Preserved" m608:channel="CC1" m608:programName="${encodingOptions["Title"] || 'Untitled'}" m608:captionService="F1C1CC"
        />
       </metadata>`);
        output += eol.after(`<styling>
       <style xml:id='basic' tts:fontFamily='monospace'/>
      </styling>`);
        output += eol.after(`<layout>
      <region xml:id='pop1' tts:backgroundColor='transparent'></region>
      <region xml:id='pop2' tts:backgroundColor='transparent'></region>
      <region xml:id='pop3' tts:backgroundColor='transparent'></region>
      <region xml:id='pop4' tts:backgroundColor='transparent'></region>
      <region xml:id='paint' tts:backgroundColor='transparent'></region>
      <region xml:id='rollup2' tts:backgroundColor='transparent'></region>
      <region xml:id='rollup3' tts:backgroundColor='transparent'></region>
      <region xml:id='rollup4' tts:backgroundColor='transparent'></region>
     </layout>`);
        output += eol.after(`</head>`);
        output += eol.after(`<body>`);
        output += eol.after(`<div>`);
        eventGroup.events.forEach(event => {
            let start = tcLib.secToTcMs(event.start);
            let end = tcLib.secToTcMs(event.end);
            let plainTextCustom = convertToPlainTextCustom(event.text);

            eol.split(plainTextCustom).forEach((textLine, index, textLines) =>{
                let origins = ttmlFunc.singleLine.calcOrigin(event, textLine, index, textLines.length, 80, options.window); 
                let text = htmlEncodePlainText(textLine);

                output += `<p region='pop${index+1}' style='basic' begin='${start.replace(",", ".")}' end='${end.replace(",", ".")}' tts:origin='${origins}'>${ttmlFunc.singleLine.convertToTtml(text)}</p>`
            });
        });

        output += eol.after("</div>");
        output += eol.after("</body>");
        output += eol.after("</tt>");

        return xmlFormat(output);
    },

    preProcess: {
        encode: function (eventGroup) {
            return removeInvalidEvents(eventGroup);
        },

        decode: function (input) {
            return input;
        }
    },

    postProcess: {
        encode: function (output) {
            return output;
        },

        decode: function (eventGroup) {
            eventGroup.events.forEach((event, index, events) => {
                events[index].text = convertToHtml(event.text);
            });

            return eventGroup;
        }
    },
}
