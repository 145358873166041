const Event = require("../classes/event.js");
const tcLib = require("../lib/timecode.js");
const cheetahFunc = require('../functions/profiles/cheetahBin.js');
const formatTags = require("../dict/formatTags.js");
const insertMissingOutcodes = require("../functions/eventGroups/insertMissingOutcodes.js");
const convertToHtml = require("../functions/quill/convertToHtml.js");
module.exports = {
    decode: function (input, options) {
        let events = [], ccEvent, char, hexBuffer, header, tcFlag = false, textFlag = false, formatting, incodeHex, outcodeHex;

        hexBuffer = input.match(/(..?)/g);
        header = hexBuffer.splice(0, 128);
        /* debug logger */
        //console.log(header.join(" ") + "\n");
        //console.log(hexBuffer.join(" "));
        while (hexBuffer.length > 0) {
            char = hexBuffer.shift();
            if (!tcFlag) {
                if ((char === "61" || char === "62" || char === "63") && parseInt(hexBuffer[0],16) < 24) {
                    tcFlag = true;
                    ccEvent = new Event({
                        xOffset : 0,
                        yOffset : options.window.height * -0.10
                    });

                    incodeHex = hexBuffer.splice(0, 4);
                    outcodeHex = hexBuffer.splice(0, 4);

                    /* Timecode Debug */
                    //console.log(char);
                    //console.log(incodeHex + "-->" + cheetahFunc.decodeTc(incodeHex));
                    //console.log(outcodeHex + "-->" + cheetahFunc.decodeTc(outcodeHex));

                    ccEvent.start = tcLib.tcToSec(cheetahFunc.decodeTc(incodeHex), options.frameRate);
                    ccEvent.end = tcLib.tcToSec(cheetahFunc.decodeTc(outcodeHex), options.frameRate);

                    hexBuffer.splice(0, 9);
                    textFlag = true;
                } else if ((char === "40" || char === "41" || char === "42") && parseInt(hexBuffer[0],16) < 24) {
                    tcFlag = true;
                    ccEvent = new Event({
                        xOffset : 0,
                        yOffset : options.window.height * -0.10
                    });
                    incodeHex = hexBuffer.splice(0, 4);
                    
                    /* Timecode Debug */
                    //console.log(char);
                    //console.log(incodeHex + "-->" + cheetahFunc.decodeTc(incodeHex));

                    ccEvent.start = tcLib.tcToSec(cheetahFunc.decodeTc(incodeHex), options.frameRate);

                    if (char !== "40"){
                        hexBuffer.splice(0, 9);
                        textFlag = true;
                    }
                }

            } else if (!textFlag && tcFlag) {
                if (char === "22") {
                    textFlag = true;
                    formatting = hexBuffer.splice(0, 8);
                } else if (char + hexBuffer[0] + hexBuffer[1] + hexBuffer[2] === "00000000") {
                    ccEvent.text += "\n";
                    tcFlag = false;
                }
            } else if (textFlag) {
                if (char + hexBuffer[0] + hexBuffer[1] + hexBuffer[2] === "00000000") {
                    ccEvent.text += "\n";
                    /* Remove the rest of the null codes */
                    hexBuffer.splice(0, 3);
                } else if (char === "00" && hexBuffer[0] != "00") {
                    tcFlag = false;
                    textFlag = false;
                    ccEvent.text = convertToHtml(ccEvent.text.trim());
                    events.push(ccEvent);
                    hexBuffer.splice(0, 1);
                } else if (parseInt(char, 16) >= 129 && parseInt(char, 16) <= 149) {
                    /* Extended Characters */
                    ccEvent.text += cheetahFunc.charLookup[char];
                } else if (char === "c0") {
                    /* Open Italics */
                    ccEvent.text += formatTags.italics.open;
                } else if (char === "d0" ) {
                    /* Close Italics */
                    ccEvent.text += formatTags.italics.close;
                } else {
                    ccEvent.text += cheetahFunc.decodeChar(char, 1252);
                }
            }
        }

        return events;
    },

    encode: function (eventGroup, options) {
        throw new Error("Cheetah Cap binary files are not supported for encoding by Closed Caption Converter.");
    },

    preProcess: {
        encode: function (eventGroup) {
            return eventGroup;
        },

        decode: function (input) {
            return input.toLowerCase();
        }
    },

    postProcess: {
        encode: function (output) {
            return output;
        },

        decode: function (eventGroup, options) {
            return insertMissingOutcodes(eventGroup);
        }
    },

}