const uuidv4 = require('uuid').v4;
module.exports = class Event {
    constructor(options = {
        start : false,
        end : false,
        duration : false,
        rate : 1, //Playback rate for audio description
        xPos : "center", //start, center, end
        yPos : "end", //start, center, end
        xOffset : 0, //px
        yOffset : 0, //px
        vertical : false,
        alignment : "center", //left, center, right
        original : "", //Original Language for translation
        text : "",
        style : "Pop-On", //Pop-On, Paint-On, Roll-Up 2, Roll-Up 3, Roll-Up 4
        tags : [],
        speaker : {},
        voice : {
            "provider":"google",
            "name":"en-US-Wavenet-A",
            "styles":["default"],
            "gender":"Male",
            "language":"en-US"
        },
        speakingStyle : "default",
        extended : false,
        audioFile : "",
        audioFileDuration : false,
        notes : "",
        forced : false,
        background: "#000000",
        color : "#FFFFFF",
        colors : 0,
        metadata : []
    }) {
        this.id = uuidv4(),
        this.start = options.start,
        this.end = options.end,
        this.rate = options.rate || 1,
        this.xPos = options.xPos || "center",
        this.yPos = options.yPos ||"end",
        this.xOffset = options.xOffset ||0,
        this.yOffset = options.yOffset || 0,
        this.vertical = options.vertical,
        this.alignment = options.alignment || "center",
        this.original = options.original || "",
        this.text = options.text || "",
        this.style = options.style || "Pop-On",
        this.tags = options.tags || [],
        this.speaker = options.speaker || {},
        this.voice = options.voice || {
            "provider":"google",
            "name":"en-US-Wavenet-A",
            "styles":["default"],
            "gender":"Male",
            "language":"en-US"
        },
        this.speakingStyle = options.speakingStyle || "default",
        this.extended = options.extended,
        this.audioFile = options.audioFile || "",
        this.audioFileDuration = options.audioFileDuration,
        this.notes = options.notes || "",
        this.forced = options.force,
        this.background = options.background || "#000000",
        this.color = options.color || "#ffffff",
        this.colors = options.colors || 0,
        this.metadata = options.metadata || []
    }
}
