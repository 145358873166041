const Event = require("../classes/event.js");
const tcLib = require("../lib/timecode.js");
const removeInvalidEvents = require("../functions/eventGroups/removeInvalidEvents.js");
const getFormatOptions = require("../functions/helpers/getFormatOptions.js");
const convertToHtml = require("../functions/quill/convertToHtml.js");
const convertToPlainText = require("../functions/quill/convertToPlainText.js");
const convertToPlainTextCustom = require("../functions/quill/convertToPlainTextCustom.js");
const eol = require("eol");
const flexbox = require("../dict/flexbox.js");
const vttFunc = require('../functions/profiles/webVtt.js');
const getLineCount = require("../functions/quill/getLineCount.js");

module.exports = {
    decode: function (input, options) {
        let events = [];
        let subtitleBlocks = input.split("\n\n");
        subtitleBlocks.forEach(subtitleBlock => {
            let tcFlag = false,
                ccEvent = new Event({
                    xOffset : 0,
                    yOffset : options.window.height * -0.10,
                });

            let blockLines = subtitleBlock.split("\n").filter(blockLine => {
                return blockLine.trim();
            });

            if (blockLines.length > 1) {
                blockLines.forEach(blockLine => {
                    if (!tcFlag && blockLine.split(" --> ").length > 1) {
                        tcFlag = true;
                        let lineInfo = blockLine.split(/ |,/);
                        let tcIn = "00:" + lineInfo[0];
                        let tcOut = "00:" + lineInfo[2];

                        ccEvent.start = tcLib.tcMsToSec(tcIn.substring(tcIn.length - 12, tcIn.length));
                        ccEvent.end = tcLib.tcMsToSec(tcOut.substring(tcOut.length - 12, tcOut.length));

                        /* Check to see if the last event has the same timecode as the current event. If so... copy the text from the last event to the current event and remove it from the events list */
                        if (events.length > 0 && (ccEvent.start === events[events.length - 1].start || ccEvent.end === events[events.length - 1].end)) {
                            ccEvent.text = events[events.length - 1].text;
                            events.pop();
                        }

                        /* Check for extra metadata */
                        if (lineInfo.length > 3) {
                            lineInfo.forEach(info => {
                                if (/align:/g.test(info)) {
                                    let alignmentMetadata = info.split(":")[1].replace(",", "");
                                    ccEvent.alignment = flexbox.alignmentNormalize[alignmentMetadata] || "center";
                                    ccEvent.xPos = flexbox.positionNormalize[alignmentMetadata] || "center";
                                } else if (/position:/g.test(info)) {
                                    let positionMetadata = info.split(":")[1].split(",")[0].replace(/%|,/gmi, "");
                                    if (!isNaN(parseInt(positionMetadata))) {
                                        let pos = parseInt(positionMetadata);
                                        if (pos < 35) {
                                            ccEvent.xPos = "start";
                                        } else if (pos < 65) {
                                            ccEvent.xPos = "center";
                                        } else {
                                            ccEvent.xPos = "end"
                                        }
                                    }
                                } else if (/line:/g.test(info)) {
                                    let vPosition = info.split(":")[1].replace(/%|,/gmi, "");
                                    if (!isNaN(vPosition) && vPosition < 40){
                                        ccEvent.yPos = "start";
                                        ccEvent.yOffset = options.window.height * (vPosition/100) 
                                    } else if (!isNaN(vPosition) && vPosition < 60){
                                        ccEvent.yPos = "center";
                                    } else {
                                        ccEvent.yPos = "end";
                                        ccEvent.yOffset = parseInt(vPosition);                                     
                                    }
                                }
                            });
                        }
                    } else if (tcFlag) {
                        ccEvent.text += blockLine + "\n";
                    }
                });
            }

            if (tcFlag) {
                ccEvent.text = convertToHtml(ccEvent.text.trim());
                events.push(ccEvent);
            }
        });

        return events;
    },

    encode: function (eventGroup, options) {
        let encodingOptions = getFormatOptions(options.formatOptions), formatting, fontStyleCues = false, overrideFontColor = false, overrideBackgroundColor = false, htmlTags = false, encodeId = false, fontColor = "white", backgroundColor = "black", maxLength = 32;

        if (encodingOptions["Encode Id"] && encodingOptions["Encode Id"] !== "false") {
            encodeId = encodingOptions["Encode Id"];
        }

        if (encodingOptions["Use HTML Tags"] && encodingOptions["Use HTML Tags"] !== "false") {
            htmlTags = encodingOptions["Use HTML Tags"];
        }

        if (encodingOptions["Override Font Color"] && encodingOptions["Override Font Color"] !== "false") {
            overrideFontColor = encodingOptions["Override Font Color"];
        }

        if (encodingOptions["Override Background Color"] && encodingOptions["Override Background Color"] !== "false") {
            overrideBackgroundColor = encodingOptions["Override Background Color"];
        }

        if (encodingOptions["Font Color"]) {
            fontColor = encodingOptions["Font Color"];
        }

        if (encodingOptions["Background Color"]) {
            backgroundColor = encodingOptions["Background Color"];
        }

        if (encodingOptions["Max Characters Per Line"]) {
            maxLength = encodingOptions["Max Characters Per Line"] || 32;
        }


        let output = "WEBVTT\n\n";

        if (encodingOptions["Encode Font Styles"] && encodingOptions["Encode Font Styles"] !== "false") {
            fontStyleCues = true;
            output += vttFunc.fontStyleCues + "\n\n";
        }

        if (encodingOptions["Encode Metadata Notes"] && encodingOptions["Metadata Notes"]) {
            output += "NOTE\n";
            output += encodingOptions["Metadata Notes"] + "\n\n";
        }

        eventGroup.events.forEach(function (event, eventIndex) {
            let eventText;
            let plainText = convertToPlainText(event.text);
            let numberOfLines = eol.split(plainText).length;
            if (encodeId) {
                output += `${eventIndex + 1}` + "\n";
            }

            output += tcLib.secToTcMs(event.start).replace(",", ".") + " --> " + tcLib.secToTcMs(event.end).replace(",", ".");

            if (encodingOptions["Encode Position"] && encodingOptions["Encode Position"] != "false") {
                let line = vttFunc.calcLineValue(event, numberOfLines, options.window);
                let size = vttFunc.calcSize(event,maxLength);
                let position = vttFunc.calcPos(event, options.window);
                let alignment = "center";

                if (event.alignment === "left") {
                    alignment = "right"
                } else if (event.alignment === "right") {
                    alignment = "left"
                }

                switch (encodingOptions["Position Template"]) {
                    case "YouTube":
                        /* eg. line:50% align:middle size:35% | line = y position, align = x position (left, middle, right)*/
                        output += ` line:${line.toFixed(2)}% align:${alignment} size:${size}%`;
                        break;
                    case "MUFI":
                        /* eg. line:78% position:50% align:middle*/
                        output += ` line:${line.toFixed(2)}% position:50% align:${alignment === 'center' ? 'middle' : alignment}`;
                        break;
                    case "Standard":
                        /* Align - font alignment, Position - Horizontal offset, Line - Vertical Offset */
                        output += ` align:${event.alignment} position:${position}% line:${line}%`;
                        break;
                    case "Custom01":
                        line = vttFunc.webcargoLineValue(event, numberOfLines, options.window);
                        output += " align:" + event.alignment + " line:" + line + "%";
                    default:
                        output += ` align:${event.alignment} position:${position.toFixed(2)}% line:${line.toFixed(2)}% size:${size.toFixed(2)}%`;
                }
            }

            if (encodingOptions["Encode Formatting"] && encodingOptions["Encode Formatting"] !== "false") {
                eventText = convertToPlainTextCustom(event.text, "\n", false, "b", "i", "u");
            } else {
                eventText = convertToPlainTextCustom(event.text, "\n", true);
            }

            eventText.split("\n").filter(lineText => {return lineText;}).forEach(line =>{
                if (fontStyleCues && (overrideFontColor || overrideBackgroundColor)) {
                    output += htmlTags ? "\n<font" : "\n<c";
                    if (overrideFontColor) {
                        output += htmlTags ? ` color="${fontColor}"` : "." + fontColor;
                    }
    
                    if (overrideBackgroundColor) {
                        output += htmlTags ? ` background-color="${backgroundColor}"` : ".bg_" + backgroundColor;
                    }
    
                    output += ">" + line + (htmlTags ? "</font>" : "</c>");
                } else if (fontStyleCues && event.color !== "#FFFFFF" && vttFunc.colorMapping[event.color]) {
                    output += "\n<c." + vttFunc.colorMapping[event.color] + ">";
                    output += ">" + line + "</c>";
                } else {
                    output += "\n" + line;
                }
            });

            output += "\n\n";
        });

        return output.trim();
    },

    preProcess: {
        encode: function (eventGroup) {
            /* All */
            return removeInvalidEvents(eventGroup);

        },

        decode: function (input) {
            return (eol.lf(input).trim()).replace(/'(\n){3,}'/gim, "\n\n");
        }
    },

    postProcess: {
        encode: function (output) {
            return output.replace(new RegExp('(\n){3,}', 'gim'), "\n\n");
        },

        decode: function (eventGroup, options) {
            eventGroup.events.forEach((event,index,events) => {
                if (event.yOffset > 0 && event.yPos === 'end'){
                    let numberOfLines = getLineCount(event.text);
                    events[index].yOffset = options.window.height * -((100-(event.yOffset + (5.33*numberOfLines)))/100);
                }
            });

            return eventGroup;
        }
    },

}