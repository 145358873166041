const eol = require("eol");
const Event = require("../classes/event.js");
const tcLib = require("../lib/timecode.js");
const removeInvalidEvents = require("../functions/eventGroups/removeInvalidEvents.js");
const convertToHtml = require("../functions/quill/convertToHtml.js");
const convertToPlainText = require("../functions/quill/convertToPlainText.js");
module.exports = {
    decode : function(input, options){
        let events = [];
        let subtitleBlocks = input.split("\n");
        subtitleBlocks.forEach(block => {
            let ccEvent = new Event({
                xOffset : 0,
                yOffset : options.window.height * -0.10
            });
            var blockInfo = block.split("\t");
            if (blockInfo.length > 1){
                ccEvent.start = tcLib.tcToSec(blockInfo[0], options.frameRate, options.dropFrame);
                ccEvent.end = tcLib.tcToSec(blockInfo[1], options.frameRate, options.dropFrame);
                ccEvent.text = convertToHtml(blockInfo[2].replace(/<P>/g, "\n"));
                events.push(ccEvent);
            }            
        });

        return events;
    },

    encode : function(eventGroup, options){
        let output = "";
        eventGroup.events.forEach(event=>{
            output += tcLib.secToTc(event.start, options.frameRate);
            output += "\t";
            output += tcLib.secToTc(event.end, options.frameRate);
            output += "\t";
            output += convertToPlainText(event.text).replace(/\n/g,"<P>") + "\n";
        });

        return output.trim();
    },

    preProcess : {
        encode : function(eventGroup){
            return removeInvalidEvents(eventGroup);
        },

        decode : function(input){
            return eol.lf(input.trim());
        }
    },

    postProcess : {
        encode : function(output){
            return output;
        },

        decode : function(eventGroup){
            return eventGroup;
        }
    },

}