const tcLib = require("../lib/timecode.js");
const Event = require("../classes/event.js");
const stripTags = require("../functions/quill/stripTags.js");
const convertToHtml = require("../functions/quill/convertToHtml.js");
const convertToPlainText = require("../functions/quill/convertToPlainText.js");
const removeInvalidEvents = require("../functions/eventGroups/removeInvalidEvents.js");
const getFormatOptions = require("../functions/helpers/getFormatOptions.js");
const Papa = require('papaparse');

module.exports = {
    decode: function (input, options) {
        let events = [], decodingOptions = getFormatOptions(options.formatOptions), startTime, endTime;

        //Get which column the user selected for caption data
        const startIndex = decodingOptions['Start Time'].charCodeAt(0) - 65;
        const endIndex = decodingOptions['End Time'].charCodeAt(0) - 65;
        const dialogIndex = decodingOptions['Text'].charCodeAt(0) - 65;

        //Converts csv string data to json objects
        let parser = Papa.parse(input, { skipEmptyLines: true });

        for (let i = 0; i < parser.data.length; i++) {
            let ccEvent = new Event({
                xOffset : 0,
                yOffset : options.window.height * -0.10
            });

            switch(decodingOptions['Start Time Format']) {
                case 'SMPTE Timecode (HH:MM:SS:FF)':
                    startTime = tcLib.tcToSec(parser.data[i][startIndex], options.frameRate);
                    break;
                case 'MS Timecode (HH:MM:SS.MSS)':
                    startTime = tcLib.tcMsToSec(parser.data[i][startIndex].replace(',', '.'));
                    break;
                case 'Seconds (SS)':
                    startTime = parseFloat(parser.data[i][startIndex]);
                    break;
                default:
                    startTime = tcLib.tcToSec(parser.data[i][startIndex] + ":00", options.frameRate);
            }

              switch(decodingOptions['End Time Format']) {
                case 'SMPTE Timecode (HH:MM:SS:FF)':
                    endTime = tcLib.tcToSec(parser.data[i][endIndex], options.frameRate);
                    break;
                case 'MS Timecode (HH:MM:SS.MSS)':
                    endTime = tcLib.tcMsToSec(parser.data[i][endIndex].replace(',', '.'));
                    break;
                case 'Seconds (SS)':
                    endTime = parseFloat(parser.data[i][endIndex]);
                    break;
                default:
                    endTime = tcLib.tcToSec(parser.data[i][endIndex] + ":00", options.frameRate);
              }

            //start time and end time will return with timecodes if it contained them
            if (startTime !== "" && endTime !== "") {
                ccEvent.start = startTime;
                ccEvent.end = endTime;
                ccEvent.text = convertToHtml(stripTags(parser.data[i][dialogIndex]));

                events.push(ccEvent);
            }
        }
        
        return events;
    },

    encode: function (eventGroup, options) {
        let output = "id,startSec,endSec,startSmpte,endSmpte,frameRate,xPos,yPos,xOffset,yOffset,text\n";

        eventGroup.events.forEach((event, index) => {
            output += index + "," + event.start + "," + event.end + "," + tcLib.secToTc(event.start, options.frameRate) + "," + tcLib.secToTc(event.end, options.frameRate) + "," + options.frameRate + "," + event.xPos + "," + event.yPos + "," + event.xOffset + "," + event.yOffset + ',"' + convertToPlainText(event.text).replace(/"/gmi, '""') + '"' + "\n";
        })
        
        return output;
    },

    preProcess: {
        encode: function (eventGroup) {
            return removeInvalidEvents(eventGroup);
        },

        decode: function (input) {
            return input;
        }
    },

    postProcess: {
        encode: function (output) {
            return output;
        },

        decode: function (eventGroup) {
            return eventGroup;
        }
    },
}

