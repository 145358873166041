const Event = require("../classes/event.js");
const tcLib = require("../lib/timecode.js");
const removeInvalidEvents = require("../functions/eventGroups/removeInvalidEvents.js");
const getFormatOptions = require("../functions/helpers/getFormatOptions.js");
const convertToHtml = require("../functions/quill/convertToHtml.js");
const convertToPlainText = require("../functions/quill/convertToPlainText.js");
const replaceBoldTags = require("../functions/quill/replaceBoldTags.js");
const replaceItalicTags = require("../functions/quill/replaceItalicTags.js");
const replaceUnderlineTags = require("../functions/quill/replaceUnderlineTags.js");
const eol = require("eol");

const ssaFunc = require("../functions/profiles/subStationAlpha.js");

module.exports = {
    decode: function (output, options) {
        let events = [];
        let tcPattern = new RegExp(/\d{1}:\d{2}:\d{2}.\d{1,2}/g);
        
        const fileLines = eol.split(output).filter(fileLine => {
            return fileLine;
        });

        fileLines.forEach(fileLine => {            
            let matches = fileLine.match(tcPattern);
            if (matches && matches.length >= 2){
                let parts = fileLine.split(",");
                parts.splice(0, 9);
                let ccEvent = new Event({
                    xOffset : 0,
                    yOffset : options.window.height * -0.10
                });
                ccEvent.start = tcLib.centisecToSeconds(matches[0]);
                ccEvent.end = tcLib.centisecToSeconds(matches[1]);
                ccEvent.text = convertToHtml(parts.join(",").replace(/\{...+\}/g,"").replace(/\\N/g,"\n"));
                events.push(ccEvent);
            }
        });

        return events;
    },

    encode: function (eventGroup, options) {
        let encodingOptions = getFormatOptions(options.formatOptions);
        // console.log(JSON.stringify(encodingOptions, null, 4));

        let output = "",
            videoWidth = encodingOptions['Video Width'] || 1280,
            videoHeight = encodingOptions['Video Height'] || 720,
            borderStyle = encodingOptions['Border Style'] || "3",
            fontFamily = encodingOptions['Font Family'] || "Arial",
            fontSize = encodingOptions['Font Size'],
            fontColor = encodingOptions['Font Color'] || "#FFFFFF",
            opacity = encodingOptions['Opacity'] || 100,
            spacing = encodingOptions['Letter Spacing'] || "0",
            shadow = encodingOptions['Shadow Size'] || "0",
            shadowColor = encodingOptions['Shadow Color'] || "#000000",
            shadowOpacity = encodingOptions['Shadow Opacity'] || 100,
            outline = encodingOptions['Outline Size'] || 1,
            olineColor = encodingOptions['Outline Color'] || "#000000",
            outlineOpacity = encodingOptions['Outline Opacity'] || 100,
            scaleX = encodingOptions['ScaleX'] || 100,
            scaleY = encodingOptions['ScaleY'] || 100;

        let primaryColor = ssaFunc.encodeColor(fontColor, opacity),
            secondaryColor = ssaFunc.encodeColor(fontColor, opacity),
            outlineColor = ssaFunc.encodeColor(olineColor, outlineOpacity),
            backgroundColor = ssaFunc.encodeColor(shadowColor, shadowOpacity);

        output += eol.after("[Script Info]");
        output += eol.after("; Script generated by Closed Caption Converter | www.closedcaptionconverter.com");
        output += eol.after("ScriptType: v4.00+");
        output += eol.after("Collisions: Normal");
        output += eol.after("PlayDepth: 0");
        output += String(eol.auto);
        output += eol.after("[V4+ Styles]");
        output += eol.after("Format: Name, Fontname, Fontsize, PrimaryColour, SecondaryColour, OutlineColour, BackColour, Bold, Italic, Underline, StrikeOut, ScaleX, ScaleY, Spacing, Angle, BorderStyle, Outline, Shadow, Alignment, MarginL, MarginR, MarginV, Encoding");
        output += eol.after(`Style: Default, Arial, 24,&H00B4FCFC,&H00B4FCFC,&H00000008,&H80000008,0,0,0,0,100,100,0.00,0.00,1,1.00,2.00,2,0,0,0,0`);
        output += eol.after(`Style: TopLeft, ${fontFamily},${fontSize},${primaryColor},${secondaryColor},${outlineColor},${backgroundColor},0,0,0,0,100,100,${spacing},0.00,${borderStyle},${outline},${shadow},7,0,0,0,0`);
        output += eol.after(`Style: TopCenter, ${fontFamily},${fontSize},${primaryColor},${secondaryColor},${outlineColor},${backgroundColor},0,0,0,0,100,100,${spacing},0.00,${borderStyle},${outline},${shadow},8,0,0,0,0`);
        output += eol.after(`Style: TopRight, ${fontFamily},${fontSize},${primaryColor},${secondaryColor},${outlineColor},${backgroundColor},0,0,0,0,100,100,${spacing},0.00,${borderStyle},${outline},${shadow},9,0,0,0,0`);
        output += eol.after(`Style: CenterLeft, ${fontFamily},${fontSize},${primaryColor},${secondaryColor},${outlineColor},${backgroundColor},0,0,0,0,100,100,${spacing},0.00,${borderStyle},${outline},${shadow},4,0,0,0,0`);
        output += eol.after(`Style: CenterCenter, ${fontFamily},${fontSize},${primaryColor},${secondaryColor},${outlineColor},${backgroundColor},0,0,0,0,100,100,${spacing},0.00,${borderStyle},${outline},${shadow},5,0,0,0,0`);
        output += eol.after(`Style: CenterRight, ${fontFamily},${fontSize},${primaryColor},${secondaryColor},${outlineColor},${backgroundColor},0,0,0,0,100,100,${spacing},0.00,${borderStyle},${outline},${shadow},6,0,0,0,0`);
        output += eol.after(`Style: BottomLeft, ${fontFamily},${fontSize},${primaryColor},${secondaryColor},${outlineColor},${backgroundColor},0,0,0,0,100,100,${spacing},0.00,${borderStyle},${outline},${shadow},1,0,0,0,0`);
        output += eol.after(`Style: BottomCenter, ${fontFamily},${fontSize},${primaryColor},${secondaryColor},${outlineColor},${backgroundColor},0,0,0,0,100,100,${spacing},0.00,${borderStyle},${outline},${shadow},2,0,0,0,0`);
        output += eol.after(`Style: BottomRight, ${fontFamily},${fontSize},${primaryColor},${secondaryColor},${outlineColor},${backgroundColor},0,0,0,0,100,100,${spacing},0.00,${borderStyle},${outline},${shadow},3,0,0,0,0`);

        output += String(eol.auto);
        output += eol.after("[Events]");
        output += eol.after("Format: Layer, Start, End, Style, Name, MarginL, MarginR, MarginV, Effect, Text");

        eventGroup.events.forEach((event,index,events) => {
            let eventStyle = ssaFunc.styleMap[event.yPos][event.xPos];
            let startTime = tcLib.secToTc(event.start, options.frameRate);
            let endTime = tcLib.secToTc(event.end, options.frameRate);

            startTime = startTime.substring(0, 8) + "." + startTime.substring(9);
            endTime = endTime.substring(0, 8) + "." + endTime.substring(9);
            //console.log(options.window,videoHeight,videoWidth,event.yOffset,event.yOffset/options.window.yOffset);
            let marginL = Math.abs(event.xOffset/2).toFixed(2);
            let marginR = Math.abs(event.xOffset/2).toFixed(2);
            let marginV = Math.abs((event.yOffset/options.window.height)*285) + 15;

            if ((event.yPos === 'start' && event.yOffset < 0) || (event.yPos === 'end' && event.yOffset > 0)){
                marginV = Math.abs((event.yOffset/options.window.height)*285) - 15;
            }

            marginV = marginV.toFixed(2);
            //console.log(`X Offset of ${event.xOffset} is now set to ${marginL}`);
            //console.log(`Y Offset of ${event.yOffset} is now set to ${marginV}`);

            if (marginV < 0){
                marginV = 0;
            }

            if (marginL < 0){
                marginL = 0;
            }

            if (marginR < 0){
                marginR = 0;
            }

            events[index].text = replaceBoldTags(event.text, "{\\b1}", "{\\b0}");
            events[index].text = replaceItalicTags(event.text, "{\\i1}", "{\\i0}");
            events[index].text = replaceUnderlineTags(event.text, "{\\u1}", "{\\u0}");
            events[index].text = convertToPlainText(event.text);

            let text = ssaFunc.formatText(events[index].text);
            let speaker = event.speaker && event.speaker.name ? event.speaker.name : "";
            output += eol.after(`Dialogue: 0,${startTime},${endTime},${eventStyle},${speaker},${events[index].xPos ==='start' ? marginL : ''},${events[index].xPos ==='end' ? marginR : ''},${marginV},,${text}`);
        });

        return output;
    },

    preProcess: {
        encode: function (eventGroup) {
            return removeInvalidEvents(eventGroup);
        },

        decode: function (input) {
            return input;
        }
    },

    postProcess: {
        encode: function (output) {
            return output;
        },

        decode: function (eventGroup) {
            return eventGroup;
        }
    }
}