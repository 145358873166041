const EventGroup = require("../classes/eventGroup.js");
const decoder = require("../profiles/index.js");
const defaults = require("../lib/defaults.js");
const applyWindowOffsets = require("./special/applyWindowOffsets.js");

module.exports = async function(input, options = new defaults.options()) {
    if (decoder[options.profile]){
        let eventGroup = new EventGroup();
        /* Pre-Process Decode */
        input = decoder[options.profile].preProcess.decode(input, options); 
        /* Decode */
        eventGroup.events = decoder[options.profile].decode(input, options);
        /* Post-Process Decode */
        eventGroup = decoder[options.profile].postProcess.decode(eventGroup, options);
        /* Apply Window Offset and Return Event Group */
        return applyWindowOffsets(eventGroup, options.window, "subtract");
    } else {
        throw new Error(`Source profile [${options.profile}] does not exist. Please ensure name matches a supported decoding profile. Profile names are case-sensitive.`);
    }    
}
