const cptable = require('codepage');
module.exports = {
    hexToAscii: function (hexValue) {
        return Buffer.from(hexValue, 'hex').toString();
    },
    calcTotalFrames: function (mm, ss, ff) {
        let totalFrames = 0;

        totalFrames += parseInt(mm, 16) * 256 * 256;
        totalFrames += parseInt(ss, 16) * 256;
        totalFrames += parseInt(ff, 16);
        return totalFrames;
    },
    hexToText: function (lineA, lineB, langA, langB) {
        let text = "";
        text += this.decodeHex(lineA, langB) + "\n";
        text += this.decodeHex(lineB, langB);
        return text;
    },
    decodeHex: function (line, lang) {
        let lineText = "";
        if (lang === 'russian') {
            line.forEach(char => {
                if (this.russianDict[char]) {
                    lineText += this.russianDict[char];
                } else {
                    lineText += this.decodeChar(char, 1252);
                }
            });
        } else if (lang === 'hebrew') {
            line.forEach(char => {
                if (this.hebrewDict[char]) {
                    lineText += this.hebrewDict[char];
                } else {
                    lineText += this.decodeChar(char, 1252);
                }
            });

            lineText
        } else if (lang === 'arabic') {
            line.forEach(char => {
                if (this.arabicDict[char]) {
                    lineText += this.arabicDict[char];
                } else {
                    lineText += this.decodeChar(char, 1252);
                }
            });
        } else if (lang === 'chineseTraditional' || lang === 'chineseSimplified') {
            line.forEach(char => {
                lineText += this.decodeChar(char, 1201);
            });
        } else {
            let skip = false;
            line.forEach((char,index,chars) => {
                if (skip){
                    skip = false;
                    return;
                }

                if (specialDict[char+chars[index+1]]){
                    lineText += specialDict[char+chars[index+1]];
                    skip = true;
                } else if (specialDict[char]){
                    lineText += specialDict[char]
                } else {
                    lineText += this.decodeChar(char, 1252);
                }
                
            });
        }

        return lineText;
    },
    // Decode from Hex
    decodeChar: function (charCode, codePage) {
        try {
            let char = cptable[codePage].dec[parseInt(charCode, 16)];
            return char;
        } catch (e) {
            console.log(e.message);
            return ("");
        }
    },
    languageCodes: {
        danish: "07",
        english: "09",
        russian: "56",
        arabic: "80",
        hebrew: "8f",
        chineseTraditional: "90",
        chineseSimplified: "91",
        romanian: "22",
        "07": "danish",
        "09": "english",
        "56": "russian",
        "80": "arabic",
        "8f": "hebrew",
        "90": "chineseTraditional",
        "91": "chineseSimplified",
        "20": "romanian"
    },
    logoGrams: ['的', '是', '啊', '吧', '好', '吧', '亲', '爱', '的', '早', '上'],
    russianChars: ['я', 'д', 'й', 'л', 'щ', 'ж', 'ц', 'ф', 'ы'],
    arabicDict: {
        "58": "م",
        "41": "ا",
        "4e": "ص",
        "42": "ب",
        "46": "ح",
        "57": "ل",
        "47": "خ",
        "5d": "ي",
        "4a": "ر",
        "2c": "،",
        "59": "ن",
        "5a": "و",
        "43": "ت",
        "1d": "-",
        "49": "ذ",
        "45": "ج",
        "5b": "ه",
        "56": "ك",
        "21": "؟",
        "4c": "س",
        "52": "ع",
        "5c": "ة",
        "5e": "ى",
        "61": "أ",
        "48": "د",
        "4d": "ش",
        "60": "ء",
        "68": "ﻷ",
        "54": "ف",
        "55": "ق",
        "22": "!",
        "67": "ﻻ",
        "66": "ؤ",
        "64": "آ",
        "50": "ط",
        "6a": "ﻵ",
        "4f": "ض",
        "6b": "ﺋ",
        "44": "ث",
        "51": "ظ",
        "53": "غ",
        "4b": "ز",
        "23": "\"",
        "6c": "ـ"
    },
    hebrewDict: {
        "40": "א",
        "41": "ב",
        "42": "ג",
        "43": "ד",
        "44": "ה",
        "45": "ו",
        "46": "ז",
        "47": "ח",
        "49": "י",
        "4c": "ל",
        "4d": "ם",
        "4e": "מ",
        "4f": "ן",
        "50": "נ",
        "51": "ס",
        "52": "ע",
        "54": "פ",
        "56": "צ",
        "57": "ק",
        "58": "ר",
        "59": "ש",
        "5a": "ת",
        "4b": "כ",
        "4a": "ך",
        "48": "ט",
        "53": "ף",
        "55": "ץ",
        "b1": "a",
        "b2": "b",
        "b3": "c",
        "b4": "d",
        "b5": "e",
        "b6": "f",
        "b7": "g",
        "b8": "h",
        "b9": "i",
        "ba": "j",
        "bb": "k",
        "bc": "l",
        "bd": "m",
        "be": "n",
        "bf": "o",
        "c0": "p",
        "c1": "q",
        "c2": "r",
        "c3": "s",
        "c4": "t",
        "c5": "u",
        "c6": "v",
        "c7": "w",
        "c8": "x",
        "c9": "y",
        "ca": "z",
        "91": "A",
        "db": "B",
        "93": "C",
        "dc": "D",
        "95": "E",
        "96": "F",
        "97": "G",
        "ab": "H",
        "99": "I",
        "9a": "J",
        "9b": "K",
        "9c": "L",
        "dd": "M",
        "de": "N",
        "9f": "O",
        "a0": "P",
        "a1": "Q",
        "a2": "R",
        "a3": "S",
        "a4": "T",
        "a5": "U",
        "a6": "V",
        "a7": "W",
        "a8": "X",
        "a9": "Y",
        "aa": "Z"
    },
    russianDict: {
        "42": "Б",
        "45": "Е",
        "5a": "З",
        "56": "В",
        "49": "И",
        "4e": "Н",
        "58": "Ы",
        "51": "Я",
        "56": "V",
        "53": "С",
        "72": "р",
        "69": "и",
        "71": "я",
        "6e": "н",
        "74": "т",
        "5c": "Э",
        "77": "ю",
        "46": "Ф",
        "5e": "Ч",
        "44": "Д",
        "62": "б",
        "73": "с",
        "75": "у",
        "64": "д",
        "60": "ж",
        "6a": "й",
        "6c": "л",
        "47": "Г",
        "78": "ы",
        "7a": "з",
        "7e": "ч",
        "6d": "м",
        "67": "г",
        "79": "ь",
        "70": "п",
        "76": "в",
        "55": "У",
        "7d": "щ",
        "66": "ф",
        "7c": "э",
        "7b": "ш",
        "50": "П",
        "52": "П",
        "68": "П"
    },
    specialDict: {
        "1b": "æ",
        "1c": "ø",
        "1d": "å",
        "1e": "Æ",
        "1f": "Ø",
        "5b": "Æ",
        "5c": "Ø",
        "5d": "Å",
        "8141": "À",
        "8145": "È",
        "8149": "Ì",
        "814f": "Ò",
        "8155": "Ù",
        "8161": "à",
        "8165": "è",
        "8169": "ì",
        "816f": "ò",
        "8175": "ù",
        "8241": "Á",
        "8243": "Ć",
        "8245": "É",
        "8249": "Í",
        "824c": "Ĺ",
        "824e": "Ń",
        "824f": "Ó",
        "8252": "Ŕ",
        "8253": "Ś",
        "8255": "Ú",
        "8257": "Ẃ",
        "8259": "Ý",
        "825a": "Ź",
        "8261": "á",
        "8263": "ć",
        "8265": "é",
        "8269": "í",
        "826c": "ĺ",
        "826e": "ń",
        "826f": "ó",
        "8272": "ŕ",
        "8273": "ś",
        "8275": "ú",
        "8277": "ẃ",
        "8279": "ý",
        "827a": "ź",
        "8341": "Â",
        "8343": "Ĉ",
        "8345": "Ê",
        "8347": "Ĝ",
        "8348": "Ĥ",
        "8349": "Î",
        "834a": "Ĵ",
        "834f": "Ô",
        "8353": "Ŝ",
        "8355": "Û",
        "8357": "Ŵ",
        "8359": "Ŷ",
        "8361": "â",
        "8363": "ĉ",
        "8365": "ê",
        "8367": "ĝ",
        "8368": "ĥ",
        "8369": "î",
        "836a": "ĵ",
        "836f": "ô",
        "8373": "ŝ",
        "8375": "û",
        "8377": "ŵ",
        "8379": "ŷ",
        "8441": "Ǎ",
        "8443": "Č",
        "8444": "Ď",
        "8445": "Ě",
        "8447": "Ǧ",
        "8449": "Ǐ",
        "844c": "Ľ",
        "844e": "Ň",
        "8452": "Ř",
        "8453": "Š",
        "8454": "Ť",
        "845a": "Ž",
        "8461": "ǎ",
        "8463": "č",
        "8464": "ď",
        "8465": "ě",
        "8467": "ǧ",
        "8469": "ǐ",
        "846c": "ľ",
        "846e": "ň",
        "8472": "ř",
        "8473": "š",
        "8474": "ť",
        "847a": "ž",
        "8541": "Ã",
        "8549": "Ĩ",
        "854e": "Ñ",
        "854f": "Õ",
        "8555": "Ũ",
        "8561": "ã",
        "8569": "ĩ",
        "856e": "ñ",
        "856f": "õ",
        "8575": "ũ",
        "8641": "Ä",
        "8645": "Ë",
        "8649": "Ï",
        "864f": "Ö",
        "8655": "Ü",
        "8659": "Ÿ",
        "8661": "ä",
        "8665": "ë",
        "8669": "ï",
        "866f": "ö",
        "8675": "ü",
        "8679": "ÿ",
        "8c61": "å",
        "8c41": "Å",
        "88": "<i>",
        "98": "</i>",
        "8961": "ă",
        "8941": "Ă",
        "8773": "ş",
        "8753": "Ş",
        "8774": "ţ",
        "8754": "Ţ"
    }

}
