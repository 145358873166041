const eol = require("eol");
const Event = require("../classes/event.js");
const tcLib = require("../lib/timecode.js");
const removeInvalidEvents = require("../functions/eventGroups/removeInvalidEvents.js");
const convertToHtml = require("../functions/quill/convertToHtml.js");
const convertToPlainText = require("../functions/quill/convertToPlainText.js");
module.exports = {
    decode: function (input, options) {
        let events = [], ccEvent, subtitleBlocks = input.split("\n\n"), tcFlag;
        subtitleBlocks.forEach(block => {
            tcFlag = false;
            block.split("\n").forEach(line => {
                let lineArray = line.split(" ");  
                          
                if (/\d\d.\d\d.\d\d.\d\d/g.test(lineArray[0]) && /\d\d.\d\d.\d\d.\d\d/g.test(lineArray[1]) && !tcFlag) {
                    tcFlag = true;
                    ccEvent = new Event({
                        xOffset : 0,
                        yOffset : options.window.height * -0.10
                    });
                    ccEvent.start = tcLib.tcToSec(lineArray[0], options.frameRate);
                    ccEvent.end = tcLib.tcToSec(lineArray[1], options.frameRate);
                } else if (tcFlag) {
                    ccEvent.text += "\n" + line.trim();
                }
            });
            if (ccEvent && ccEvent.text){
                ccEvent.text = convertToHtml(ccEvent.text.trim());
                events.push(ccEvent);
            }
        });

        return events;
    },

    encode: function (eventGroup, options) {
        let output = "<begin subtitles>\n\n";
        eventGroup.events.forEach(event => {
            output += tcLib.secToTc(event.start, options.frameRate).replace(/;/g, ":") + " ";
            output += eol.lf(tcLib.secToTc(event.end, options.frameRate).replace(/;/g, ":") + "\n");
            output += eol.lf(convertToPlainText(event.text));
            output += eol.lf("\n\n");
        });

        output += "<end subtitles>";
        return output;
    },

    preProcess: {
        encode: function (eventGroup) {
            return removeInvalidEvents(eventGroup);
        },

        decode: function (input) { /* All */
            return eol.lf(input.trim()).replace(/'(\n){3,}'/gim, "\n\n");
        }
    },

    postProcess: {
        encode: function (output) {
            return output;
        },

        decode: function (eventGroup) {
            return eventGroup;
        }
    }

}
