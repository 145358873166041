const tcLib = require("../lib/timecode.js");
const removeInvalidEvents = require("../functions/eventGroups/removeInvalidEvents.js");
const eol = require("eol");
const getFormatOptions = require("../functions/helpers/getFormatOptions.js");
const scenerist = require( "./scenerist.js");
const sccLookup = require("../dict/608.js");
const sccFunc = require("../functions/profiles/scenerist.js");
const proCapFunc = require("../functions/profiles/proCapTransfer.js");

module.exports = {
    decode: function (input, options) {
        let events = [], sccFile = "Scenarist_SCC V1.0", tc;
        let fileLines = eol.split(input);

        let frameRateInfo = proCapFunc.getFrameRateInfo(input, options);
        options.frameRate = frameRateInfo.frameRate;
        options.dropFrame = frameRateInfo.dropFrame;
        fileLines.forEach(fileLine => {
            if (!new RegExp(/TC:\W\W\d\d:\d\d.\d\d/).test(fileLine)){
                return;
            }

            sccFile += "\n\n" + fileLine.match(/\d\d:\d\d:\d\d.\d\d/)[0].replace(/\.|\,/g, ":") + "\t";

            let cmds = fileLine.split("  ")[2].replace("¶", "");
            let sccCodes = [];
            cmds.split("÷").forEach(cmd =>{
                if (!cmd){
                    return;
                }

                sccCodes.push(proCapFunc.translateCntrlCode(cmd.substring(0,4)));
                cmd = cmd.substring(4).split("");
                while (cmd.length > 0){
                    var s = cmd.shift();
                    if (s === "§"){
                        sccCodes.push("80");
                        cmd.shift();
                        cmd.shift();
                    } else if (s === "\\"){
                        sccCodes.push("dc");
                    } else {
                        sccCodes.push(sccFunc.getCodeByCmd(sccLookup.ch01, s));
                    }
                }
            });

            sccFile += sccFunc.formatEncodedCmds(sccCodes);
        });

        events = scenerist.decode(sccFile, {
            frameRate: options.frameRate,
            dropFrame: options.dropFrame,
            window : options.window
        });

        let eventGroup = scenerist.postProcess.decode({events : events}, options);

        return eventGroup.events;

    },

    encode: function (eventGroup, options) {
        let encodingOptions = getFormatOptions(options.formatOptions), channel = "ch01", incode;

        let clock = tcLib.createTc(
            tcLib.secToTc(eventGroup.events[0].start, options.frameRate),
            options.frameRate,
            options.dropFrame
        );

        try {
            clock.subtract(100);
        } catch (e) {
            clock = tcLib.createTc(
                "00:00:00:01",
                options.frameRate,
                options.dropFrame
            );
        }
        let output = `'**************************************************

\\ Title: ${encodingOptions.Title || ""}

\\ Version: 1.1
\\ Channel: F1C1
\\ Rate: ${proCapFunc.getRateCode(options.frameRate, options.dropFrame)}
\\ Type: LTC

\\ Generated By: CC Converter 3 (wwww.closedcaptionconverter.com)
\\ CaptionFile: Unknown
\\ MediaFile: Unknown

\\ Author: ${encodingOptions.Author || ""}
\\ Owner: ${encodingOptions.Owner || ""}

\\ Date: ${proCapFunc.getDate()}
\\ Time: ${proCapFunc.getTime()}
        
'**************************************************


`;
        eventGroup.events.forEach((event, index, events) =>{
            incode = tcLib.createTc(
                tcLib.secToTc(event.start, options.frameRate),
                options.frameRate,
                options.dropFrame
            );

            let eventDetails = sccFunc.getEventDetails(event, options.window);
            let encodedText = sccFunc.encodeEvent(eventDetails, channel, options.window);
            let encodedTextString = sccFunc.formatEncodedCmds(encodedText);
            let encodeTime = sccFunc.calculateEncodeTime(encodedTextString);
            // console.log(eventDetails);
            //console.log(encodeTime,encodedText);
            if (event.style === "Pop-On") {
                if (incode.frameCount - encodeTime >= 0){
                    incode.subtract(encodeTime);
                } else {
                    incode.subtract(incode.frameCount);
                }
                
            }
            
            output += `\\ TC:  ${proCapFunc.formatTimecodeString(incode.toString(), options.dropFrame)}  ¶`;
            
            encodedText.forEach(cmd =>{
                let proCapCmd = proCapFunc.translateSccCode(cmd);
                if (proCapCmd.length === 4){
                    output += `÷${proCapCmd.toUpperCase()}`;
                } else if (proCapCmd == "80") {
                    output += `§00`;
                } else {
                    output += proCapCmd;
                }
            });
            if (event.style === "Pop-On") {
                output += `÷142F`;
            }
            output += "\n"

            if ((events[index+1] && events[index+1].start <= event.end+0.03) || !events[index+1]){
                output += `\\ TC:  ${proCapFunc.formatTimecodeString(tcLib.secToTc(event.end, options.frameRate), options.dropFrame)}  ¶÷142C\n`;
            }
        });

        //console.log(eventGroup);
        return output.trim();
    },

    preProcess: {
        encode: function (eventGroup) {
            return removeInvalidEvents(eventGroup);
        },

        decode: function (input) {
            return eol.lf(input);
        }
    },

    postProcess: {
        encode: function (output) {
            return output;
        },

        decode: function (eventGroup) {
            return eventGroup;
        }
    }
}