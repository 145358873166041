const cptable = require('codepage');

module.exports = {
    codePageMapping: {
        "ISO 8859-1 Latin 1": 28591,
        "ISO 8859-2 Latin 2": 28592,
        "ISO 8859-3 Latin 3": 28593,
        "ISO 8859-4 Baltic": 28594,
        "ISO 8859-5 Cyrillic": 28595,
        "ISO 8859-6 Arabic": 28596,
        "ISO 8859-7 Greek": 28597,
        "ISO 8859-8 Hebrew" : 28598,
        "ISO 8859-9 Turkish" : 28599
    },
    // Decode from Hex
    decodeChar: function (charCode, codePage) {
        try {
            let char = cptable[codePage].dec[parseInt(charCode, 16)];
            return char;
        } catch (e) {
            console.log(e.message);
            return ("");
        }
    },
    /* Takes array of  hex values and returns a smpteTC: [ "e8", "03", "64", "00" ] -> 10:00:01:00*/
    decodeTc: function (hexTc) {
        let hhmm,
            ssff,
            tc,
            hh,
            mm,
            ss,
            ff;
        hhmm = parseInt(hexTc[1] + hexTc[0], 16).toString().padStart(4, "0");
        ssff = parseInt(hexTc[3] + hexTc[2], 16).toString().padStart(4, "0");
        hh = hhmm.substring(0, 2);
        mm = hhmm.substring(2, 4);
        ss = ssff.substring(0, 2);
        ff = ssff.substring(2, 4);
        tc = hh + ":" + mm + ":" + ss + ":" + ff;
        return (tc);
    },
    codePages: [
        "37",
        "437",
        "500",
        "620",
        "708",
        "720",
        "737",
        "775",
        "808",
        "850",
        "852",
        "855",
        "857",
        "858",
        "860",
        "861",
        "862",
        "863",
        "864",
        "865",
        "866",
        "869",
        "870",
        "872",
        "874",
        "875",
        "895",
        "932",
        "936",
        "949",
        "950",
        "1010",
        "1026",
        "1047",
        "1132",
        "1140",
        "1141",
        "1142",
        "1143",
        "1144",
        "1145",
        "1146",
        "1147",
        "1148",
        "1149",
        "1200",
        "1201",
        "1250",
        "1251",
        "1252",
        "1253",
        "1254",
        "1255",
        "1256",
        "1257",
        "1258",
        "1361",
        "10000",
        "10001",
        "10002",
        "10003",
        "10004",
        "10005",
        "10006",
        "10007",
        "10008",
        "10010",
        "10017",
        "10021",
        "10029",
        "10079",
        "10081",
        "10082",
        "12000",
        "12001",
        "20000",
        "20001",
        "20002",
        "20003",
        "20004",
        "20005",
        "20105",
        "20106",
        "20107",
        "20108",
        "20127",
        "20261",
        "20269",
        "20273",
        "20277",
        "20278",
        "20280",
        "20284",
        "20285",
        "20290",
        "20297",
        "20420",
        "20423",
        "20424",
        "20833",
        "20838",
        "20866",
        "20871",
        "20880",
        "20905",
        "20924",
        "20932",
        "20936",
        "20949",
        "21025",
        "21027",
        "21866",
        "28591",
        "28592",
        "28593",
        "28594",
        "28595",
        "28596",
        "28597",
        "28598",
        "28599",
        "28600",
        "28601",
        "28603",
        "28604",
        "28605",
        "28606",
        "29001",
        "38598",
        "47451",
        "50220",
        "50221",
        "50222",
        "50225",
        "50227",
        "51932",
        "51936",
        "51949",
        "52936",
        "54936",
        "57002",
        "57003",
        "57004",
        "57005",
        "57006",
        "57007",
        "57008",
        "57009",
        "57010",
        "57011",
        "65000",
        "6500"
    ],
    specialArabicChars: {
        "e081": "أ",
        "e086": "ځ",
        "e09b": "ؤ",
        "e09c": "ئ",
        "e181": "إ",
        "e281": "آ",
        "e781": "اً",
        "80": "ـ",
        "81": "ا",
        "82": "ب",
        "83": "ت",
        "84": "ث",
        "85": "ج",
        "86": "ح",
        "87": "خ",
        "88": "د",
        "89": "ذ",
        "8a": "ر",
        "8b": "ز",
        "8c": "س",
        "8d": "ش",
        "8e": "ص",
        "8f": "ض",
        "90": "ظ",
        "91": "ط",
        "92": "ع",
        "93": "غ",
        "94": "ف",
        "95": "ق",
        "96": "ك",
        "97": "ل",
        "98": "م",
        "99": "ن",
        "9A": "ه",
        "9b": "و",
        "9c": "ى",
        "9d": "ة",
        "9e": "لا",
        "9f": "ي",
        "e09f": "ي",
        "a0": "ء",
        "ad": "ڪ",
        "ae": "ﺭ",
        "b0": "ﺩ",
        "b1": "ﯼ",
        "b3": "۔",
        "3f": "؟",
        "25": "٪",
        "2c": "،",
        "3b": "؛",
        "e7": "\u064B",
        "ea": "\u064C",
        "e8": "\u064D",
        "e5": "\u064E",
        "e4": "\u064F",
        "e6": "\u0650",
        "e3": "\u0651",
        "e9": "\u0652",
        "e2": "\u0653",
        "e0": "\u0654",
        "e1": "\u0655",
    },
    specialLatinChars: {
        "e041": "Ã",
        "e04e": "Ñ",
        "e04f": "Õ",
        "e061": "ã",
        "e06e": "ñ",
        "e06f": "õ",
        "e161": "å",
        "e141": "Å",
        "618a": "ā",
        "418a": "Ā",
        "458a": "Ē",
        "658a": "ē",
        "498a": "Ī",
        "698a": "ī",
        "4f8a": "Ō",
        "6f8a": "ō",
        "558a": "Ū",
        "758a": "ū",
        "E54f": "Ö",
        "E56f": "ö",
        "e541": "Ä",
        "e561": "ä",
        "e555": "Ü",
        "e575": "ü",
        "e241": "Á",
        "e249": "Í",
        "e255": "Ú",
        "e259": "Ý",
        "e261": "á",
        "e265": "é",
        "e269": "í",
        "e245": "É",
        "e275": "ú",
        "e279": "ý",
        "e361": "à",
        "e365": "è",
        "e36f": "ò",
        "e345": "È",
        "e349": "Ì",
        "e34f": "Ò",
        "e369": "ì",
        "e443": "Ĉ",
        "e447": "Ĝ",
        "e448": "Ĥ",
        "e44A": "Ĵ",
        "e453": "Ŝ",
        "eA55": "Ǔ",
        "e463": "ĉ",
        "e467": "ĝ",
        "e468": "ĥ",
        "e46A": "ĵ",
        "e473": "ŝ",
        "eA75": "ǔ",
        "e341": "À",
        "e441": "Â",
        "e461": "â",
        "e643": "Ç",
        "e663": "ç",
        "e445": "Ê",
        "e465": "ê",
        "e545": "Ë",
        "e565": "ë",
        "e449": "Î",
        "e469": "î",
        "e549": "Ï",
        "e569": "ï",
        "e44F": "Ô",
        "e46F": "ô",
        "e355": "Ù",
        "e375": "ù",
        "e455": "Û",
        "e475": "û",
        "e559": "Ÿ",
        "e579": "ÿ",
        "eb41": "Ą",
        "eb61": "ą",
        "e243": "Ć",
        "e263": "ć",
        "eB45": "Ę",
        "eB65": "ę",
        "e24e": "Ń",
        "e26e": "ń",
        "e24f": "Ó",
        "e26f": "ó",
        "e253": "Ś",
        "e273": "ś",
        "e25a": "Ź",
        "e27a": "ź",
        "e85a": "Ż",
        "e87a": "ż",
        "e653": "Ş",
        "e673": "ş",
        "ea67": "ǧ",
        "ea47": "Ǧ",
        "e849": "İ",
        "e75a": "Ž",
        "e753": "Š",
        "e743": "Č",
        "e77a": "ž",
        "e773": "š",
        "e763": "č",
        "4ce2": "Ľ",
        "74e2": "ť",
        "64e2": "ď",
        "6ce2": "ľ",
        "e020": "˜",
        "e045": "Ẽ",
        "e049": "Ĩ",
        "e055": "Ũ",
        "e056": "Ṽ",
        "e059": "Ỹ",
        "e065": "ẽ",
        "e069": "ĩ",
        "e075": "ũ",
        "e076": "ṽ",
        "e079": "ỹ",
        "e120": "˚",
        "e155": "Ů",
        "e175": "ů",
        "e177": "ẘ",
        "e179": "ẙ",
        "e220": "´",
        "e247": "Ǵ",
        "e24b": "Ḱ",
        "e24c": "Ĺ",
        "e24d": "Ḿ",
        "e250": "Ṕ",
        "e252": "Ŕ",
        "e257": "Ẃ",
        "e25c": "Ǽ",
        "e25d": "Ǿ",
        "e267": "ǵ",
        "e26b": "ḱ",
        "e26c": "ĺ",
        "e26d": "ḿ",
        "e270": "ṕ",
        "e272": "ŕ",
        "e277": "ẃ",
        "e27c": "ǽ",
        "e27d": "ǿ",
        "e320": "`",
        "e34e": "Ǹ",
        "e357": "Ẁ",
        "e359": "Ỳ",
        "e36e": "ǹ",
        "e377": "ẁ",
        "e379": "ỳ",
        "e420": "^",
        "e457": "Ŵ",
        "e459": "Ŷ",
        "e45a": "Ẑ",
        "e477": "ŵ",
        "e479": "ŷ",
        "e47a": "ẑ",
        "e520": "¨",
        "e548": "Ḧ",
        "e557": "Ẅ",
        "e558": "Ẍ",
        "e568": "ḧ",
        "e574": "ẗ",
        "e577": "ẅ",
        "e578": "ẍ",
        "e620": "¸",
        "e644": "Ḑ",
        "e645": "Ȩ",
        "e647": "Ģ",
        "e648": "Ḩ",
        "e64b": "Ķ",
        "e64c": "Ļ",
        "e64e": "Ņ",
        "e652": "Ŗ",
        "e654": "Ţ",
        "e664": "ḑ",
        "e665": "ȩ",
        "e667": "ģ",
        "e668": "ḩ",
        "e66b": "ķ",
        "e66c": "ļ",
        "e66e": "ņ",
        "e672": "ŗ",
        "e674": "ţ",
        "e720": "ˇ",
        "e741": "Ă",
        "e744": "Ď",
        "e745": "Ě",
        "e747": "Ǧ",
        "e748": "Ȟ",
        "e749": "Ǐ",
        "e74b": "Ǩ",
        "e74e": "Ň",
        "e74f": "Ǒ",
        "e752": "Ř",
        "e754": "Ť",
        "e755": "Ǔ",
        "e761": "ă",
        "e765": "ě",
        "e768": "ȟ",
        "e769": "ǐ",
        "e76a": "ǰ",
        "e76b": "ǩ",
        "e76e": "ň",
        "e76f": "ǒ",
        "e772": "ř",
        "e775": "ǔ",
        "e820": "˙",
        "e841": "Ȧ",
        "e842": "Ḃ",
        "e843": "Ċ",
        "e844": "Ḋ",
        "e845": "Ė",
        "e846": "Ḟ",
        "e847": "Ġ",
        "e848": "Ḣ",
        "e84d": "Ṁ",
        "e84e": "Ṅ",
        "e84f": "Ȯ",
        "e850": "Ṗ",
        "e852": "Ṙ",
        "e853": "Ṡ",
        "e854": "Ṫ",
        "e857": "Ẇ",
        "e858": "Ẋ",
        "e859": "Ẏ",
        "e861": "ȧ",
        "e862": "ḃ",
        "e863": "ċ",
        "e864": "ḋ",
        "e865": "ė",
        "e866": "ḟ",
        "e867": "ġ",
        "e868": "ḣ",
        "e86d": "ṁ",
        "e86e": "ṅ",
        "e86f": "ȯ",
        "e870": "ṗ",
        "e872": "ṙ",
        "e873": "ṡ",
        "e874": "ṫ",
        "e877": "ẇ",
        "e878": "ẋ",
        "e879": "ẏ",
        "e920": "¯",
        "e941": "Ā",
        "e945": "Ē",
        "e947": "Ḡ",
        "e949": "Ī",
        "e94f": "Ō",
        "e955": "Ū",
        "e959": "Ȳ",
        "e95c": "Ǣ",
        "e961": "ā",
        "e965": "ē",
        "e967": "ḡ",
        "e969": "ī",
        "e96f": "ō",
        "e975": "ū",
        "e979": "ȳ",
        "e97c": "ǣ",
        "ea20": "ˇ",
        "ea41": "Ă",
        "ea45": "Ě",
        "ea49": "Ǐ",
        "ea4f": "Ǒ",
        "ea61": "ă",
        "ea65": "ě",
        "ea69": "ǐ",
        "ea6f": "ǒ",
        "eb20": "˛",
        "eb49": "Į",
        "eb4f": "Ǫ",
        "eb55": "Ų",
        "eb69": "į",
        "eb6f": "ǫ",
        "eb75": "ų",
        "ec20": "˝",
        "ec4f": "Ő",
        "ec55": "Ű",
        "ec6f": "ő",
        "ec75": "ű",
    },
    specialTurkishChars: {
        "e720" : "˘",
        "e741" : "Ă",
        "e745" : "Ĕ",
        "e747" : "Ğ",
        "e749" : "Ĭ",
        "e74f" : "Ŏ",
        "e755" : "Ŭ",
        "e761" : "ă",
        "e765" : "ĕ",
        "e769" : "ĭ",
        "e76f" : "ŏ",
        "e775" : "ŭ",
        "ea20" : "˘",
        "ea45" : "Ĕ",
        "ea47" : "Ğ",
        "ea49" : "Ĭ",
        "ea4f" : "Ŏ",
        "ea55" : "Ŭ",
        "ea65" : "ĕ",
        "ea67" : "ğ",
        "ea69" : "ĭ",
        "ea6f" : "ŏ",
        "ea75" : "ŭ"
    },
    specialHebrewChars : {
        "80": "ְ",
        "81": "ֱ",
        "82": "ֲ",
        "83": "ֳ",
        "84": "ִ",
        "85": "ֵ",
        "86": "ֶ",
        "87": "ַ",
        "88": "ָ",
        "89": "ֹ",
        "8b": "ֻ",
        "8c": "ּ",
        "8d": "ֽ",
        "92": "֗",
        "a0": "א",
        "a1": "ב",
        "a2": "ג",
        "a3": "ד",
        "a4": "ה",
        "a5": "ו",
        "a6": "ז",
        "a7": "ח",
        "a8": "ט",
        "a9": "י",
        "aa": "ך",
        "ab": "כ",
        "ac": "ל",
        "ad": "ם",
        "ae": "מ",
        "af": "ן",
        "b0": "נ",
        "b1": "ס",
        "b2": "ע",
        "b3": "ף",
        "b4": "פ",
        "b5": "ץ",
        "b6": "צ",
        "b7": "ק",
        "b8": "ר",
        "b9": "ש",
        "ba": "ת",
        "bb": "װ",
        "bc": "ױ",
        "bd": "ײ",
        "cc": "ףּ",
        "cd": "רּ",
        "ce": "אַ",
        "cf": "אּ",
        "d0": "אָ",
        "d1": "שּׂ",
        "d2": "שּׁ",
        "d3": "שׂ",
        "d4": "שׁ",
        "d5": "ﬥ",
        "d6": "ﬠ",
        "d7": "לּ",
        "d8": "יּ",
        "d9": "טּ",
        "da": "זּ",
        "db": "וּ",
        "dc": "הּ",
        "dd": "דּ",
        "de": "גּ",
        "df": "בּ",
        "2e": ".",
        "2c": ","
    },
    specialCyrillicChars : {
        "20" :  " ",
        "21" :  "!",
        "22" :  "Э",
        "23" :  "/",
        "24" :  "?",
        "25" :  ":",
        "26" :  ".",
        "27" :  "э",
        "28" :  "(",
        "29" :  ")",
        "2a" :  ";",
        "2b" :  "+",
        "2c" :  "б",
        "2d" :  "-",
        "2e" :  "ю",
        "3a" :  "Ж",
        "3b" :  "ж",
        "3c" :  "<",
        "3d" :  "=",
        "3e" :  ">",
        "41" :  "Ф",
        "42" :  "И",
        "43" :  "С",
        "44" :  "В",
        "45" :  "У",
        "46" :  "А",
        "47" :  "П",
        "48" :  "Р",
        "49" :  "Ш",
        "4a" :  "О",
        "4b" :  "Л",
        "4c" :  "Д",
        "4d" :  "Ь",
        "4e" :  "Т",
        "4f" :  "Щ",
        "50" :  "З",
        "51" :  "Q",
        "52" :  "К",
        "53" :  "Ы",
        "54" :  "Е",
        "55" :  "Г",
        "56" :  "М",
        "57" :  "Ц",
        "58" :  "Ч",
        "59" :  "Н",
        "5a" :  "Я",
        "5b" :  "х",
        "5d" :  "ъ",
        "5e" :  ",",
        "5f" :  "-",
        "61" :  "ф",
        "62" :  "и",
        "63" :  "с",
        "64" :  "в",
        "65" :  "у",
        "66" :  "а",
        "67" :  "п",
        "68" :  "р",
        "69" :  "ш",
        "6a" :  "о",
        "6b" :  "л",
        "6c" :  "д",
        "6d" :  "ь",
        "6e" :  "т",
        "6f" :  "щ",
        "70" :  "з",
        "72" :  "к",
        "73" :  "ы",
        "74" :  "е",
        "75" :  "г",
        "76" :  "м",
        "77" :  "ц",
        "78" :  "ч",
        "79" :  "н",
        "7a" :  "я",
        "7b" :  "Х",
        "7d" :  "Ъ",
        "80" :  "Б",
        "81" :  "Ю",
        "82" :  "Ђ",
        "84" :  "Є",
        "85" :  "Ѕ",
        "86" :  "І",
        "88" :  "Ј",
        "89" :  "Љ",
        "8a" :  "Њ",
        "8b" :  "Ћ",
        "8c" :  "Ґ",
        "8d" :  "ґ",
        "8f" :  "Џ",
        "90" :  "№",
        "92" :  "ђ",
        "94" :  "є",
        "95" :  "ѕ",
        "96" :  "і",
        "98" :  "ј",
        "99" :  "љ",
        "9a" :  "њ",
        "9b" :  "ћ",
        "9d" :  "§",
        "9f" :  "џ",
        "a2" :  "%",
        "a4" :  "&",
        "ac" :  "C",
        "ad" :  "D",
        "ae" :  "E",
        "af" :  "F",
        "b0" :  "G",
        "b1" :  "H",
        "b2" :  "'",
        "b3" :  "\"",
        "b4" :  "I",
        "b5" :  "J",
        "b6" :  "K",
        "b7" :  "L",
        "b8" :  "M",
        "b9" :  "N",
        "ba" :  "P",
        "bb" :  "Q",
        "bc" :  "R",
        "bd" :  "S",
        "be" :  "T",
        "bf" :  "U",
        "c0" :  "V",
        "c1" :  "*",
        "c2" :  "W",
        "c3" :  "X",
        "c4" :  "Y",
        "c5" :  "Z",
        "c6" :  "b",
        "c7" :  "c",
        "c8" :  "d",
        "c9" :  "e",
        "ca" :  "f",
        "cb" :  "g",
        "cc" :  "h",
        "cd" :  "i",
        "ce" :  "j",
        "cf" :  "k",
        "d0" :  "—",
        "d1" :  "l",
        "d2" :  "m",
        "d3" :  "n",
        "d4" :  "o",
        "d5" :  "p",
        "d6" :  "q",
        "d7" :  "r",
        "d8" :  "s",
        "d9" :  "t",
        "da" :  "u",
        "db" :  "v",
        "dc" :  "w",
        "dd" :  "э",
        "de" :  "ю",
        "df" :  "z",
        "e3" :  "`",
        "e5" :  "¨",
        "e020" :  "˘",
        "e03a" :  "Ӂ",
        "e03b" :  "ӂ",
        "e042" :  "Й",
        "e045" :  "Ў",
        "e046" :  "Ӑ",
        "e054" :  "Ӗ",
        "e062" :  "й",
        "e065" :  "ў",
        "e066" :  "ӑ",
        "e074" :  "ӗ",
        "e220" :  "´",
        "e252" :  "Ќ",
        "e255" :  "Ѓ",
        "e272" :  "ќ",
        "e275" :  "ѓ",
        "e342" :  "Ѝ",
        "e354" :  "Ѐ",
        "e362" :  "ѝ",
        "e374" :  "ѐ",
        "e522" :  "Ӭ",
        "e527" :  "ӭ",
        "e53a" :  "Ӝ",
        "e53b" :  "ӝ",
        "e542" :  "Ӥ",
        "e545" :  "Ӱ",
        "e546" :  "Ӓ",
        "e54a" :  "Ӧ",
        "e550" :  "Ӟ",
        "e553" :  "Ӹ",
        "e554" :  "Ё",
        "e558" :  "Ӵ",
        "e562" :  "ӥ",
        "e565" :  "ӱ",
        "e566" :  "ӓ",
        "e56a" :  "ӧ",
        "e570" :  "ӟ",
        "e573" :  "ӹ",
        "e574" :  "ё",
        "e578" :  "ӵ",
        "e586" :  "Ї",
        "e596" :  "ї",
        "e5b4" :  "Ї"
    },
    specialGreekChars : {
        "20" : " ",
        "21" : "!",
        "22" : "\"",
        "23" : "£",
        "24" : "$",
        "25" : "%",
        "26" : "&",
        "27" : "'",
        "28" : "(",
        "29" : ")",
        "2a" : "*",
        "2b" : "+",
        "2c" : ",",
        "2d" : "-",
        "2e" : ".",
        "2f" : "/",
        "3a" : ":",
        "3b" : ";",
        "3c" : "<",
        "3d" : "=",
        "3e" : ">",
        "3f" : "?",
        "40" : "@",
        "41" : "Α",
        "42" : "Β",
        "43" : "Γ",
        "44" : "Δ",
        "45" : "Ε",
        "46" : "Ζ",
        "47" : "Η",
        "48" : "Θ",
        "49" : "Ι",
        "4a" : "Κ",
        "4b" : "Λ",
        "4c" : "Μ",
        "4d" : "Ν",
        "4e" : "Ξ",
        "4f" : "Ο",
        "50" : "Π",
        "51" : "Ρ",
        "52" : "R",
        "53" : "Σ",
        "54" : "Τ",
        "55" : "Υ",
        "56" : "Φ",
        "57" : "Χ",
        "58" : "Ψ",
        "59" : "Ω",
        "5a" : "Z",
        "5f" : "-",
        "61" : "α",
        "62" : "β",
        "63" : "γ",
        "64" : "δ",
        "65" : "ε",
        "66" : "ζ",
        "67" : "η",
        "68" : "θ",
        "69" : "ι",
        "6a" : "κ",
        "6b" : "λ",
        "6c" : "μ",
        "6d" : "ν",
        "6e" : "ξ",
        "6f" : "ο",
        "70" : "π",
        "71" : "ρ",
        "72" : "ς",
        "73" : "σ",
        "74" : "τ",
        "75" : "υ",
        "76" : "φ",
        "77" : "χ",
        "78" : "ψ",
        "79" : "ω",
        "7a" : "z",
        "7e" : "§",
        "80" : "#",
        "81" : "ß",
        "82" : "²",
        "83" : "³",
        "84" : "«",
        "85" : "»",
        "86" : "±",
        "87" : "þ",
        "88" : "Þ",
        "89" : "ð",
        "8c" : "A",
        "8d" : "B",
        "8e" : "C",
        "8f" : "D",
        "90" : "E",
        "91" : "F",
        "92" : "G",
        "93" : "H",
        "94" : "I",
        "95" : "J",
        "96" : "K",
        "97" : "L",
        "98" : "M",
        "99" : "N",
        "9a" : "O",
        "9b" : "P",
        "9c" : "Q",
        "9d" : "R",
        "9e" : "S",
        "9f" : "T",
        "a0" : "U",
        "a1" : "V",
        "a2" : "W",
        "a3" : "X",
        "a4" : "Y",
        "a5" : "Z",
        "a9" : "°",
        "ab" : "½",
        "ac" : "a",
        "ad" : "b",
        "ae" : "c",
        "af" : "d",
        "b0" : "e",
        "b1" : "f",
        "b2" : "g",
        "b3" : "h",
        "b4" : "i",
        "b5" : "j",
        "b6" : "k",
        "b7" : "l",
        "b8" : "m",
        "b9" : "n",
        "ba" : "o",
        "bb" : "p",
        "bc" : "q",
        "bd" : "r",
        "be" : "s",
        "bf" : "t",
        "c0" : "u",
        "c1" : "v",
        "c2" : "w",
        "c3" : "x",
        "c4" : "y",
        "c5" : "z",
        "202a" : "®",
        "e241" : "Ά",
        "e242" : "Β́",
        "e243" : "Γ́",
        "e244" : "Δ́",
        "e245" : "Έ",
        "e246" : "Ζ́",
        "e247" : "Ή",
        "e248" : "Θ́",
        "e249" : "Ί",
        "e24a" : "Κ́",
        "e24b" : "Λ́",
        "e24c" : "Μ́",
        "e24d" : "Ν́",
        "e24e" : "Ξ́",
        "e24f" : "Ό",
        "e255" : "Ύ",
        "e258" : "Ψ́",
        "e259" : "Ώ",
        "e261" : "ά",
        "e262" : "β́",
        "e263" : "γ́",
        "e264" : "δ́",
        "e265" : "έ",
        "e266" : "ζ",
        "e267" : "ή",
        "e268" : "θ́",
        "e269" : "ί",
        "e26a" : "κ́",
        "e26b" : "λ́",
        "e26c" : "μ́",
        "e26d" : "ν́",
        "e26e" : "ξ",
        "e26f" : "ό",
        "e270" : "π",
        "e271" : "ρ́",
        "e272" : "ς́",
        "e273" : "σ́",
        "e274" : "τ́",
        "e275" : "ύ",
        "e276" : "φ́",
        "e277" : "χ́",
        "e278" : "ψ́",
        "e279" : "ώ",
        "e27b" : "ί",
        "e320" : "`",
        "e399" : "Ǹ",
        "e39a" : "Ò",
        "e3a0" : "Ù",
        "e3a2" : "Ẁ",
        "e3a4" : "Ỳ",
        "e3b9" : "ǹ",
        "e3c2" : "ẁ",
        "e3c4" : "ỳ",
        "e549" : "Ϊ",
        "e555" : "Ϋ",
        "e561" : "α̈",
        "e562" : "β̈",
        "e563" : "γ̈",
        "e564" : "δ̈",
        "e565" : "ε̈",
        "e566" : "ζ̈",
        "e567" : "η̈",
        "e568" : "θ̈",
        "e569" : "ϊ",
        "e56a" : "κ̈",
        "e56b" : "λ̈",
        "e56c" : "μ̈",
        "e56d" : "ν̈",
        "e56e" : "ξ̈",
        "e56f" : "ο̈",
        "e570" : "π̈",
        "e571" : "ρ",
        "e572" : "ς̈",
        "e573" : "σ̈",
        "e574" : "τ̈",
        "e575" : "ϋ",
        "e576" : "φ̈",
        "e577" : "χ̈",
        "e578" : "ψ̈",
        "e579" : "ω̈",
        "e57b" : "ϊ",
        "e5e269" : "ΐ",
        "e5e275" : "ΰ"
    }
}
