const cptable = require('codepage');
const Event = require("../../classes/event.js");
const tcLib = require("../../lib/timecode.js");
const convertToHtml = require("../quill/convertToHtml.js");
const quillClasses = require("../../dict/quillClasses.js")
const getLongestLine = require("../utility/getLongestLine.js");
const findCenter = require('../utility/findCenter.js');
const flexbox = require('../../dict/flexbox.js');

module.exports = {
    getVersion : function(headerInfo){
        if (headerInfo.join("") === "4341505400322e30"){
            return "2.0";
        } else if (headerInfo.join("") === "4341505400312e32"){
            return "1.2";
        } else {
            return "1.0";
        }
    },
    // Decode from Hex"
    decodeChar: function (charCode, codePage = "28591") {
        try {
            //console.log(charCode, cptable[codePage].dec[parseInt(charCode, 16)]);
            let char = cptable[codePage].dec[parseInt(charCode, 16)];
            return char;
        } catch (e) {
            console.log(e.message);
            return ("");
        }
    },
    decodeTc : function(hexTc, frameRate){
        try {
            hexTc = hexTc.match(/(..?)/g);
            let hh = parseInt(hexTc[0],16).toString().padStart(2, "0");
            let mm = parseInt(hexTc[1],16).toString().padStart(2, "0");
            let ss = parseInt(hexTc[2],16).toString().padStart(2, "0");
            let ff = parseInt(hexTc[3],16).toString().padStart(2, "0");
            //console.log(`${hh}:${mm}:${ss}:${ff}`);
            let tcSec = tcLib.tcToSec(`${hh}:${mm}:${ss}:${ff}`, frameRate);
            return tcSec;
        } catch(err){
            return 0
        }
        
        
    },
    decodeBlock : function(block, options){
        let ccEvent = new Event({
            start : block.start,
            end : block.end
        });

        let position = this.calcBlockPosition(block, options.window);
        ccEvent = {...ccEvent, ...position};

        ccEvent.text = convertToHtml(block.lines.map(line => {
            return line.text.trim();
        }).join("\n"), [quillClasses.align[ccEvent.alignment]]);

        return ccEvent;
    },
    calcBlockPosition : function(block, win){
        let pos = {
            alignment : "center",
            xPos : "center",
            yPos : "end",
            xOffset : 0,
            yOffset : win.height * -0.10
        }

        let details = this.getBlockInfo(block);
        //console.log(block);
        //console.log(details);

        /* Calculate Y Position */
        if (parseInt(block.lines[0].yPos) < 4){
            pos.yPos = "start";
            pos.yOffset = win.height * 0.10;
        } else if (parseInt(block.lines[0].yPos) < 6){
            pos.yPos = "center";
            pos.yOffset = 0;
        } else {
            pos.yPos = "end";
        }

        /* Calculate X Position and Alignment */
        if (block.lines.length > 1){
            if (details.alignments.every(alignment => {
                return alignment === "center";
            })){
                pos.xPos = "center";
                pos.xOffset = 0;
                pos.alignment = "center";
            } else if (details.xPositions.every(xPosition => {
                return xPosition === details.xPositions[0];
            })){
                pos.alignment = "left";
                if (details.minXStart < 13){
                    pos.xPos = "start";
                    pos.xOffset = win.width * ((details.minXStart+4)/40);
                } else if (details.minXStart > 19){
                    pos.xPos = "end";
                    pos.xOffset = win.width * ((36-details.maxXEnd)/-40);
                } else {
                    pos.xPos = "center";
                }
            } else if (details.xEnds.every(xEnd => {
                return xEnd === details.xEnds[0];
            })){
                pos.alignment = "right";
                if (details.minXStart < 13){
                    pos.xPos = "start";
                    pos.xOffset = win.width * ((details.minXStart+4)/40);
                } else if (details.minXStart > 19){
                    pos.xPos = "end";
                    pos.xOffset = win.width * ((36-details.maxXEnd)/-40);
                } else {
                    pos.xPos = "center";
                }
            } else {
                pos.alignment = "center";
            }
        } else {
            pos.xPos = flexbox.alignmentMap[details.alignments[0]];

            if (pos.xPos === "start"){
                xOffset = win.height * (details.xPositions[0]/40);
            } else if (pos.xPos === "end"){
                xOffset = win.height * ((36-details.xEnds[0])/-40);
            } else {
                xOffset = 0;
            }

            pos.alignment = "center";
        }

        return pos;
    },
    getBlockInfo : function(block){
        let info = {}
        info.plainText = block.lines.map(line => {return line.text.trim()}).join("\n").trim();
        info.lineCount = block.lines.length;
        info.longestLine = getLongestLine(info.plainText);
        info.xPositions = block.lines.map(line => {return line.xPos});
        info.yPositions = block.lines.map(line => {return line.yPos});
        info.xEnds = block.lines.map(line => {
            return parseInt(line.xPos) + line.text.trim().length
        });

        info.alignments = block.lines.map(line =>{
            let lineLength = line.text.trim().length;
            let center = findCenter(32, lineLength);
            if (line.xPos < center-1){
                return "left"
            } else if (line.xPos > center+1){
                return "right"
            } else {
                return "center"
            }
        })

        info.minXStart = Math.min(...info.xPositions);
        info.maxXStart = Math.max(...info.xPositions);
        info.minXEnd = Math.min(...info.xEnds);
        info.maxXEnd = Math.max(...info.xEnds);
        return info;
    },
    Block : class{
        constructor(options ={
            start : false,
            end : false,
            lines : []
        }){
            this.start = options.start,
            this.end = options.end,
            this.lines = options.lines || []
        }

        insertLine(options = {
            text: "",
            xPos: false,
            yPos: false,
            italics: false,
            underline: false,
            bold: false,
            color: "#FFFFFF",
            background: "#000000",
            opacity: 1
        }) {
            this.lines.push({
                text: options.text || "",
                xPos: options.xPos, //1-32
                yPos: options.yPos, //1-8 
                italics: options.italics,
                underline: options.underline,
                bold: options.bold,
                color: options.color || "#FFFFFF",
                background: options.background || "#000000",
                opacity: options.opacity || 1
            });
        }
    }

}