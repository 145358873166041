const Event = require("../classes/event.js");
const getFormatOptions = require("../functions/helpers/getFormatOptions.js");
const convertToHtml = require("../functions/quill/convertToHtml.js");
const _Speaker = require('../classes/speaker.js');
module.exports = {
    decode: function (input, options) {
        let events = [], ccEvent, decodingOptions = getFormatOptions(options.formatOptions);
        let fileJson = JSON.parse(input);
        let importType = decodingOptions["Import Type"] || "subtitle";
        let maxLines = decodingOptions["Max Lines"] || 2;
        let maxChars = decodingOptions["Max Characters"] || 32;
        let speakers = [];

        function insertOrGetSpeaker(speaker) {
            if (speaker === undefined || speaker === null) {
                return {};
            }
            let speakerLabel = 'sp' + speaker;
            let speakerIndex = speakers.findIndex(sp => {
                return sp.name === speakerLabel;
            });

            if (speakerIndex > -1) {
                /* Speaker Exists in Array Already */
                return speakers[speakerIndex];
            } else {
                let spNew = new _Speaker({
                    name: speakerLabel
                })
                speakers.push(spNew);

                return spNew;
            }
        }

        fileJson.utterances.forEach(utterance => {
            //console.log(utterance);
            if (!utterance.words || utterance.words.length === 0 || !utterance.text) {
                return;
            }

            if (importType === "transcription") {
                ccEvent = new Event({
                    xOffset: 0,
                    yOffset: options.window.height * -0.10
                });
                ccEvent.start = parseFloat(utterance.start) / 1000;
                ccEvent.end = parseFloat(utterance.end) / 1000;
                ccEvent.alignment = "left";
                ccEvent.text = convertToHtml(utterance.text.trim(), [""]);
                ccEvent.speaker = insertOrGetSpeaker(utterance.speaker);
                events.push(ccEvent);
            } else if (importType === "word map") {
                utterance.words.forEach(word => {
                    events.push(new Event(
                        {
                            start: parseFloat(word.start) / 1000,
                            end: parseFloat(word.end) / 1000,
                            text: word.text,
                            xOffset: 0,
                            yOffset: options.window.height * -0.10
                        }
                    ));
                });
            } else {
                ccEvent = new Event({
                    xOffset: 0,
                    yOffset: options.window.height * -0.10
                });
                ccEvent.speaker = insertOrGetSpeaker("A");
                let ccAdded = false;
                let lines = [];
                let line = "";
                while (utterance.words.length > 0) {
                    let word = utterance.words.shift();
                    if (!ccEvent.start) {
                        ccEvent.start = parseFloat(word.start) / 1000;
                    }

                    /* Test for gaps between words > 0.5 seconds */
                    if (ccEvent.end && parseFloat(word.start) / 1000 > ccEvent.end + 0.4) {

                        //console.log("BREAK", line, ccEvent.end, word.startTime);
                        lines.push(line);
                        ccEvent.text = convertToHtml(lines.join("\n"));
                        events.push(ccEvent);
                        ccAdded = true;

                        lines = [];
                        line = "";
                        ccEvent = new Event({
                            xOffset: 0,
                            yOffset: options.window.height * -0.10
                        });
                        ccEvent.speaker = insertOrGetSpeaker(word.speaker);
                        ccEvent.start = parseFloat(word.start) / 1000;

                    }

                    if (line.length + word.text.length > maxChars - 1) {
                        lines.push(line);
                        line = word.text;
                    } else {
                        line += " " + word.text;
                    }

                    if (lines.length >= maxLines) {
                        ccEvent.text = convertToHtml(lines.join("\n"));
                        events.push(ccEvent);
                        ccAdded = true;
                        lines = [];
                        ccEvent = new Event({
                            xOffset: 0,
                            yOffset: options.window.height * -0.10
                        });
                        ccEvent.speaker = insertOrGetSpeaker(word.speaker);
                        ccEvent.start = parseFloat(word.start) / 1000;
                    }

                    /* Set the end time to the new word end time */
                    ccEvent.end = parseFloat(word.end) / 1000;
                }

                if (line.length > 0) {
                    if (lines.length > 0 || utterance.text.trim().split(" ").length === 1) {
                        lines.push(line);
                        ccEvent.text = convertToHtml(lines.join("\n"));
                        events.push(ccEvent);
                    } else if (events.length > 0 && ccAdded) {
                        if (events[events.length - 1].end + 0.4 < ccEvent.start) {
                            lines.push(line);
                            ccEvent.text = convertToHtml(lines.join("\n"));
                            events.push(ccEvent);
                        } else {
                            events[events.length - 1].text += convertToHtml(line.trim());
                            events[events.length - 1].end = ccEvent.end;
                        }
                    } else {
                        lines.push(line);
                        ccEvent.text = convertToHtml(lines.join("\n"));
                        events.push(ccEvent);
                    }
                }
            }
        });

        //console.log(events);
        return events;
    },

    encode: function (eventGroup, options) {
        throw new Error("JSON Transcript files are not supported for encoding by Closed Caption Converter.");
    },

    preProcess: {
        encode: function (eventGroup) {
            return eventGroup;
        },

        decode: function (input) { /* All */
            return input;
        }
    },

    postProcess: {
        encode: function (output) {
            return output;
        },

        decode: function (eventGroup) {
            eventGroup.events = eventGroup.events.filter((event, index, events) => {
                if (index !== 0) {
                    return event.start !== events[index - 1].start;
                } else {
                    return true;
                }
            });

            return eventGroup;
        }
    }

}
