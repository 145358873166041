const Event = require("../classes/event.js");
const getFormatOptions = require("../functions/helpers/getFormatOptions.js");
const convertToHtml = require("../functions/quill/convertToHtml.js");
const _Speaker = require('../classes/speaker.js');
module.exports = {
    decode: function (input, options) {
        let events = [], ccEvent = new Event({
            xOffset: 0,
            yOffset: options.window.height * -0.10
        }), decodingOptions = getFormatOptions(options.formatOptions);
        let fileJson = JSON.parse(input);
        let importType = decodingOptions["Import Type"] || "subtitle";
        let maxLines = decodingOptions["Max Lines"] || 2;
        let maxChars = decodingOptions["Max Characters"] || 32;
        let speaker = 1;
        let speakers = [];

        function insertOrGetSpeaker(speaker) {
            if (speaker === undefined || speaker === null) {
                return {};
            }
            let speakerLabel = 'sp' + speaker;
            let speakerIndex = speakers.findIndex(sp => {
                return sp.name === speakerLabel;
            });

            if (speakerIndex > -1) {
                /* Speaker Exists in Array Already */
                return speakers[speakerIndex];
            } else {
                let spNew = new _Speaker({
                    name: speakerLabel
                })
                speakers.push(spNew);

                return spNew;
            }
        }

        try {
            speaker = fileJson.results.filter(result => {
                result.type === "word";
            })[0].alternatives[0].speaker || "S1";
        } catch (err) {
            speaker = "S1";
        }

        if (importType === "transcription") {
            fileJson.results.forEach(result => {
                if (result.type === "word") {
                    if (result.alternatives[0].speaker !== speaker || (ccEvent.end && result.start_time >= ccEvent.end + 0.5)) {
                        ccEvent.speaker = insertOrGetSpeaker(speaker);
                        speaker = result.alternatives[0].speaker;
                        ccEvent.alignment = "left";
                        ccEvent.text = convertToHtml(ccEvent.text.trim(), [""]);
                        events.push(ccEvent);
                        ccEvent = new Event({
                            xOffset: 0,
                            yOffset: options.window.height * -0.10
                        });
                        ccEvent.text += result.alternatives[0].content + " ";
                        ccEvent.start = result.start_time;
                        ccEvent.end = result.end_time;
                    } else {
                        ccEvent.text += " " + result.alternatives[0].content;
                        ccEvent.start = ccEvent.start ? ccEvent.start : result.start_time;
                        ccEvent.end = result.end_time;
                    }
                } else {
                    ccEvent.text += result.alternatives[0].content;
                    ccEvent.start = ccEvent.start ? ccEvent.start : result.start_time;
                    ccEvent.end = result.end_time;
                }
            });

            ccEvent.alignment = "left";
            ccEvent.speaker = insertOrGetSpeaker(speaker);
            ccEvent.text = convertToHtml(ccEvent.text.trim(), [""]);
            events.push(ccEvent);
        } else if (importType === "word map") {
            fileJson.results.forEach(result => {
                if (result.type === "word") {
                    events.push(new Event({
                        start: result.start_time,
                        end: result.end_time,
                        text: result.alternatives[0].content,
                        xOffset: 0,
                        yOffset: options.window.height * -0.10
                    }));
                }
            });
        } else {
            let ccAdded = false;
            let lines = [];
            let line = "";
            while (fileJson.results.length > 0) {
                let result = fileJson.results.shift();
                if (!ccEvent.start) {
                    ccEvent.start = result.start_time;
                    ccEvent.speaker = insertOrGetSpeaker(result.alternatives[0].speaker);
                }

                /* Test for gaps between words > 0.6 seconds */
                if ((ccEvent.end && result.start_time > ccEvent.end + 0.5) || (line.charAt(line.length - 1) === ".")) {
                    //console.log("BREAK", line, ccEvent.end, result.start_time);
                    lines.push(line);
                    ccEvent.text = convertToHtml(lines.join("\n"));

                    events.push(ccEvent);
                    ccAdded = true;

                    lines = [];
                    line = "";
                    ccEvent = new Event({
                        xOffset: 0,
                        yOffset: options.window.height * -0.10
                    });
                    ccEvent.speaker = insertOrGetSpeaker(result.alternatives[0].speaker);
                    ccEvent.start = result.start_time;
                }

                if (line.length + result.alternatives[0].content.length > maxChars - 1 && result.type !== "punctuation") {
                    lines.push(line);
                    line = result.alternatives[0].content;
                } else if (result.type === "punctuation") {
                    line += result.alternatives[0].content;
                } else {
                    line += " " + result.alternatives[0].content;
                }

                if (lines.length >= maxLines) {
                    ccEvent.speaker = insertOrGetSpeaker(speaker);
                    ccEvent.text = convertToHtml(lines.join("\n"));
                    events.push(ccEvent);
                    ccAdded = true;
                    lines = [];
                    ccEvent = new Event({
                        xOffset: 0,
                        yOffset: options.window.height * -0.10
                    });
                    ccEvent.speaker = insertOrGetSpeaker(result.alternatives[0].speaker);
                    ccEvent.start = result.start_time;
                }

                /* Set the end time to the new word end time */
                ccEvent.end = result.end_time;
            }

            lines.push(line);
            ccEvent.text = convertToHtml(lines.join("\n"));
            events.push(ccEvent);
        }

        return events;
    },

    encode: function (eventGroup, options) {
        throw new Error("JSON Transcript files are not supported for encoding by Closed Caption Converter.");
    },

    preProcess: {
        encode: function (eventGroup) {
            return eventGroup;
        },

        decode: function (input) { /* All */
            return input;
        }
    },

    postProcess: {
        encode: function (output) {
            return output;
        },

        decode: function (eventGroup) {
            eventGroup.events = eventGroup.events.filter((event, index, events) => {
                if (index !== 0) {
                    return event.start !== events[index - 1].start;
                } else {
                    return true;
                }
            });

            return eventGroup;
        }
    }

}
