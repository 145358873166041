const Event = require("../classes/event.js");
const tcLib = require("../lib/timecode.js");
const removeInvalidEvents = require("../functions/eventGroups/removeInvalidEvents.js");
const convertToHtml = require("../functions/quill/convertToHtml.js");
const rtfLookup = require("../dict/rtf.js");
const eol = require("eol");

function replaceEscapedCodes(text){
    Object.entries(rtfLookup).forEach(code =>{
        var re = new RegExp(code[0],"g");
        text = text.replace(re, code[1]);
    });

    return text;
}

function decodeRtfText(text){
    text = replaceEscapedCodes(text);
    text = text.replace(/\\par/gmi,"");
    text = text.replace(/\\i /gmi,"<i>");
    text = text.replace(/\\i0/gmi,"</i>");
    text = text.replace(/\n<\/i>/gmi,"</i>\n");
    return convertToHtml(text);
}

module.exports = {
    decode : function(input, options){
        let events = [], eventId, tcInfo, subtitleBlocks = [], subtitleBlock = "";
        eol.split(input).forEach(fileLine => {
            let matches = fileLine.match(/\d+  \d\d:\d\d:\d\d:\d\d \d\d:\d\d:\d\d:\d\d \d\d:\d\d/);
            if (matches){
                if (subtitleBlock){
                    subtitleBlocks.push(subtitleBlock);
                }
                
                subtitleBlock = matches.join("");
            } else if (subtitleBlock){
                subtitleBlock += "\n"+fileLine;
            }
        });

        subtitleBlocks.push(subtitleBlock);
        //console.log(subtitleBlocks);
        
        subtitleBlocks.forEach(block => {
            let ccEvent = new Event({
                xOffset : 0,
                yOffset : options.window.height * -0.10,
            });
            let blockInfo = eol.split(block);
            let tcInfo = blockInfo.shift();
            let tcData = tcInfo.match(/\d\d:\d\d:\d\d:\d\d/g);
            
            blockInfo = blockInfo.filter(part =>{
                return part !== `}` && part !== `\x00` && part !== `\\par`;
            });

            // console.log(blockInfo);
            if (tcData.length === 2){
                ccEvent.start = tcLib.parseTcToSec(tcData[0], options.frameRate);
                ccEvent.end = tcLib.parseTcToSec(tcData[1], options.frameRate);
            } else {
                return;
            }

            ccEvent.text = decodeRtfText(blockInfo.join("\n"));
            events.push(ccEvent);
        });

        return events;
    },

    encode : function(eventGroup, options){
        throw new Error("RTF files are not supported for encoding by Closed Caption Converter.");
    },

    preProcess : {
        encode : function(eventGroup){
            return removeInvalidEvents(eventGroup);
        },

        decode : function(input){
            return input;
        }
    },

    postProcess : {
        encode : function(output){
            return output;
        },

        decode : function(eventGroup){
            return eventGroup;
        }
    },

}