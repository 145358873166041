const Event = require("../classes/event.js");
const getFormatOptions = require("../functions/helpers/getFormatOptions.js");
const convertToHtml = require("../functions/quill/convertToHtml.js");
const _Speaker = require('../classes/speaker.js');
module.exports = {
    decode: function (input, options) {
        let events = [], ccEvent, decodingOptions = getFormatOptions(options.formatOptions);
        let fileJson = JSON.parse(input);
        let importType = decodingOptions["Import Type"] || "subtitle";
        let maxLines = decodingOptions["Max Lines"] || 2;
        let maxChars = decodingOptions["Max Characters"] || 32;

        fileJson.results.forEach(result => {
            result.alternatives.forEach(alternative => {
                //console.log(alternative);
                if (!alternative.words || alternative.words.length === 0 || !alternative.transcript) {
                    return;
                }

                if (importType === "transcription") {
                    ccEvent = new Event({
                        xOffset: 0,
                        yOffset: options.window.height * -0.10
                    });
                    ccEvent.start = parseFloat(alternative.words[0].startTime.replace("s", ""));
                    ccEvent.end = parseFloat(alternative.words[alternative.words.length - 1].endTime.replace("s", ""));
                    ccEvent.alignment = "left";
                    ccEvent.text = convertToHtml(alternative.transcript.trim(), [""]);
                    events.push(ccEvent);
                } else if (importType === "word map") {
                    alternative.words.forEach(word => {
                        events.push(new Event(
                            {
                                start: parseFloat(word.startTime.replace("s", "")),
                                end: parseFloat(word.endTime.replace("s", "")),
                                text: word.word,
                                xOffset: 0,
                                yOffset: options.window.height * -0.10
                            }
                        ));
                    });
                } else {
                    ccEvent = new Event({
                        xOffset: 0,
                        yOffset: options.window.height * -0.10
                    });
                    let ccAdded = false;
                    let lines = [];
                    let line = "";
                    while (alternative.words.length > 0) {
                        let word = alternative.words.shift();

                        if (!ccEvent.start) {
                            ccEvent.start = parseFloat(word.startTime.replace("s", ""));
                        }

                        /* Test for gaps between words > 0.5 seconds */
                        if (ccEvent.end && parseFloat(word.startTime.replace("s", "")) > ccEvent.end + 0.4) {

                            //console.log("BREAK", line, ccEvent.end, word.startTime);
                            lines.push(line);
                            ccEvent.text = convertToHtml(lines.join("\n"));
                            events.push(ccEvent);
                            ccAdded = true;

                            lines = [];
                            line = "";
                            ccEvent = new Event({
                                xOffset: 0,
                                yOffset: options.window.height * -0.10
                            });
                            ccEvent.start = parseFloat(word.startTime.replace("s", ""));

                        }

                        if (line.length + word.word.length > maxChars - 1) {
                            lines.push(line);
                            line = word.word;
                        } else {
                            line += " " + word.word;
                        }

                        if (lines.length >= maxLines) {
                            ccEvent.text = convertToHtml(lines.join("\n"));
                            events.push(ccEvent);
                            ccAdded = true;
                            lines = [];
                            ccEvent = new Event({
                                xOffset: 0,
                                yOffset: options.window.height * -0.10
                            });
                            ccEvent.start = parseFloat(word.startTime.replace("s", ""));
                        }

                        /* Set the end time to the new word end time */
                        ccEvent.end = parseFloat(word.endTime.replace("s", ""));
                    }

                    if (line.length > 0) {
                        if (lines.length > 0 || alternative.transcript.trim().split(" ").length === 1) {
                            lines.push(line);
                            ccEvent.text = convertToHtml(lines.join("\n"));
                            events.push(ccEvent);
                        } else if (events.length > 0 && ccAdded) {
                            if (events[events.length - 1].end + 0.4 < ccEvent.start) {
                                lines.push(line);
                                ccEvent.text = convertToHtml(lines.join("\n"));
                                events.push(ccEvent);
                            } else {
                                events[events.length - 1].text += convertToHtml(line.trim());
                                events[events.length - 1].end = ccEvent.end;
                            }
                        } else {
                            lines.push(line);
                            ccEvent.text = convertToHtml(lines.join("\n"));
                            events.push(ccEvent);
                        }
                    }
                }
            })
        });

        //console.log(events);
        return events;
    },

    encode: function (eventGroup, options) {
        throw new Error("Google STT Transcript files are not supported for encoding by Closed Caption Converter.");
    },

    preProcess: {
        encode: function (eventGroup) {
            return eventGroup;
        },

        decode: function (input) { /* All */
            return input;
        }
    },

    postProcess: {
        encode: function (output) {
            return output;
        },

        decode: function (eventGroup) {
            eventGroup.events = eventGroup.events.filter((event, index, events) => {
                if (index !== 0) {
                    return event.start !== events[index - 1].start;
                } else {
                    return true;
                }
            });

            return eventGroup;
        }
    }

}
