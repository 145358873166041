const proCapDict = require("../../dict/proCapTransfer.js");
const sccFunc = require("./scenerist.js");
const sccLookup = require("../../dict/608.js");
module.exports = {
    rateMapping: {
        "23.98": {
            frameRate: 23.976,
            dropFrame: false
        },
        "24": {
            frameRate: 24,
            dropFrame: false
        },
        "25": {
            frameRate: 25,
            dropFrame: false
        },
        "30d": {
            frameRate: 29.97,
            dropFrame: true
        },
        "30": {
            frameRate: 30,
            dropFrame: false
        },
        "60d": {
            frameRate: 59.94,
            dropFrame: true
        },
        "60": {
            frameRate: 60,
            dropFrame: false
        },
    },
    reverseRateMapping: {
        "23.976": {
            yes: "23.98",
            no: "23.98"
        },
        "24": {
            yes: "24",
            no: "24"
        },
        "25": {
            yes: "25",
            no: "25"
        },
        "29.97": {
            yes: "30d",
            no: "30"
        },
        "30": {
            yes: "30",
            no: "30"
        },
        "59.94": {
            yes: "60d",
            no: "59.94"
        },
        "60": {
            yes: "60",
            no: "60"
        },
    },
    getFrameRateInfo: function (fileContents, options) {
        let rate = fileContents.match(/(?<=Rate:).+(?=\n)/i);
        if (rate) {
            return this.rateMapping[rate[0].trim().toLowerCase()];
        } else {
            return {
                frameRate: options.frameRate,
                dropFrame: options.dropFrame
            }
        }
    },
    translateCntrlCode: function (cmd) {
        let cntrl = proCapDict[cmd.toLowerCase()];
        if (cntrl) {
            return sccFunc.getCodeByCmd(sccLookup.ch01, cntrl);
        } else {
            throw new Error("Command code no identified. Please contact support. Unknown code: " + cmd);
        }
    },
    translateSccCode : function(cmd){
        let sccCode = sccLookup.all[cmd];
        if (sccCode){
            //console.log(`${cmd} translated to ${sccCode}`);
            if (sccCode.length > 1){
                if (sccCode === "{FILLER}"){
                    return `§00`;
                } else {
                    return sccFunc.getCodeByCmd(proCapDict, sccCode);
                }
                
            } else {
                return sccCode;
            }            
        } else {
            throw new Error(`${cmd} is not recognized as an SCC code`);
        }
    },
    getRateCode: function (frameRate, dropFrame) {
        return this.reverseRateMapping[frameRate][dropFrame ? 'yes' : 'no'];
    },
    getDate: function () {
        const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        let d = new Date();
        return monthNames[d.getMonth()] + ' ' + d.getDay() + ', ' + d.getFullYear();
    },
    getTime: function () {
        let d = new Date();
        return d.toLocaleString('en-US', { hour: 'numeric', minute: '2-digit', second: '2-digit', hour12: true })
    },
    formatTimecodeString: function (tc, dropFrame) {
        if (dropFrame) {
            return tc.substring(0,8) + ";" + tc.substring(9);
        } else {
            return tc.substring(0,8) + "." + tc.substring(9);
        }               
    },

}