const eol = require("eol");
const frameRatesDict = require("../dict/frameRates.js");
const removeInvalidEvents = require("../functions/eventGroups/removeInvalidEvents.js");
const orderByTime = require("../functions/eventGroups/orderByTime.js");
const tcOffset = require("../functions/events/tcOffset.js");
const tcLib = require('../lib/timecode.js');
const xmlFormat = require('xml-formatter'); //Encode
const xmlToJson = require('fast-xml-parser');
const ittFunc = require("../functions/profiles/iTunesTimedText.js");
const convertToPlainTextCustom = require("../functions/quill/convertToPlainTextCustom.js");
const getFormatOptions = require("../functions/helpers/getFormatOptions.js");
const htmlEntities = require("html-entities");
module.exports = {
    decode: function (input, options) {
        let events = [];
        let fileJson = xmlToJson.parse(input, {
            ignoreAttributes: false,
            stopNodes: ["p"]
        });
        //console.log(fileJson);
        let frameRate = fileJson.tt['@_ttp:frameRate'] || options.frameRate;
        let frameRateMultiplier = fileJson.tt['@_ttp:frameRateMultiplier'];
        let dropFrame = fileJson.tt['@_ttp:dropMode'];

        if (frameRateMultiplier === "999 1000") {
            if (frameRate == 24) {
                frameRate = 23.976;
            } else if (frameRate == 30) {
                frameRate = 29.97;
            }
        }

        frameRate = parseFloat(frameRate);
        if (dropFrame === undefined) {
            dropFrame = frameRatesDict.dropFrameMap[frameRate];
        } else if (dropFrame === "nonDrop") {
            dropFrame = false;
        }

        if (Array.isArray(fileJson.tt.body.div)) {
            fileJson.tt.body.div.forEach(captionBlock => {
                //console.log(captionBlock);
                events = [...events, ...ittFunc.decodeCaptionBlock(captionBlock, frameRate, dropFrame)];
            });
        } else {
            //console.log(fileJson.tt.body.div);
            let captionBlock = fileJson.tt.body.div;
            events = [...events, ...ittFunc.decodeCaptionBlock(captionBlock, frameRate, dropFrame)];
        }

        return events;
    },

    encode: function (eventGroup, options) {
        let frameRate = ittFunc.frameRateMap[options.frameRate];
        let frameRateMultiplier = ittFunc.frameRateMultiplierMap[options.frameRate];
        let encodingOptions = getFormatOptions(options.formatOptions);
        let languageCode = "en-US";
        if (encodingOptions["Language Code"]) {
            languageCode = encodingOptions["Language Code"] || "en-US";
        }

        let output = eol.after(`<?xml version="1.0" encoding="UTF-8"?>`);
        output += eol.after(`<tt xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns="http://www.w3.org/ns/ttml" xmlns:tt="http://www.w3.org/ns/ttml" xmlns:tts="http://www.w3.org/ns/ttml#styling" xmlns:ttp="http://www.w3.org/ns/ttml#parameter" xml:lang="${languageCode}" ttp:timeBase="smpte" ttp:frameRate="${frameRate}" ttp:frameRateMultiplier="${frameRateMultiplier}" ttp:dropMode="${options.dropFrame ? "drop" : 'nonDrop'}">`);
        output += eol.after(`\t<head>
        <styling>
            <style xml:id="normal" tts:fontFamily="sansSerif" tts:fontWeight="normal" tts:fontStyle="normal" tts:color="white" tts:fontSize="100%"/>
            <style xml:id="bold" tts:fontFamily="sansSerif" tts:fontWeight="bold" tts:fontStyle="normal" tts:color="white" tts:fontSize="100%"/>
            <style xml:id="italic" tts:fontFamily="sansSerif" tts:fontWeight="normal" tts:fontStyle="italic" tts:color="white" tts:fontSize="100%"/>
            <style xml:id="underline" tts:fontFamily="sansSerif" tts:fontWeight="normal" tts:textDecoration='underline' tts:color="white" tts:fontSize="100%"/>
        </styling>
        <layout>
            <region xml:id="top" tts:origin="0% 10%" tts:extent="100% 15%" tts:textAlign="center" tts:displayAlign="before"/>
            <region xml:id="bottom" tts:origin="0% 75%" tts:extent="100% 15%" tts:textAlign="center" tts:displayAlign="after"/>
        </layout>
    </head>`);
        output += eol.after(`\t<body style="normal" region="bottom">`);

        eventGroup = orderByTime(eventGroup);
        let tcStart = tcLib.secToTc(eventGroup.events[0].start, options.frameRate);
        let hours = tcStart.split(":")[0];
        let offsetAmount = 0;

        if (hours !== "00"){
            output += eol.after(`\t\t<div begin="${hours}:00:00:00">`);
            offsetAmount = tcLib.tcToSec(hours + ":00:00:00", options.frameRate);
            eventGroup.events.forEach((event, index, events)=>{
                events[index] = tcOffset(event, offsetAmount, "subtract");
            });
        } else {
            output += eol.after(`\t\t<div begin="00:00:00:00">`);
        }

        eventGroup.events.forEach(event => {
            let startTc = tcLib.secToTc(event.start, options.frameRate);
            let endTc = tcLib.secToTc(event.end, options.frameRate);
            let region = event.yPos === 'start' ? "top" : "bottom";
            output += `\t\t\t<p begin="${startTc}" end="${endTc}" region="${region}">${(convertToPlainTextCustom(ittFunc.convertToItt(event.text), "<br/>").replace(/<br\/>$/g, ""))}</p>\n`
        });

        output += `\t\t</div>
    </body>
</tt>`;

        return eol.auto(output);
    },

    preProcess: {
        encode: function (eventGroup) {
            return removeInvalidEvents(eventGroup);
        },

        decode: function (input) { /* All */
            return eol.lf(input.trim()).replace(/'(\n){3,}'/gim, "\n\n");
        }
    },

    postProcess: {
        encode: function (output) {
            return output;
        },

        decode: function (eventGroup) {
            return eventGroup;
        }
    }

}
