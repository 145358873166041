const Event = require("../classes/event.js");
const tcLib = require("../lib/timecode.js");
const removeInvalidEvents = require("../functions/eventGroups/removeInvalidEvents.js");
const getFormatOptions = require("../functions/helpers/getFormatOptions.js");
const convertToHtml = require("../functions/quill/convertToHtml.js");
const convertToPlainText = require("../functions/quill/convertToPlainText.js");
const convertToPlainTextCustom = require("../functions/quill/convertToPlainTextCustom.js");
const formatTags = require("../dict/formatTags.js");
const eol = require("eol");

module.exports = {
    decode: function (input, options) {
        let events = [];
        let ccEvent = false;
        let fileLines = input.split("\n");
        fileLines.forEach(fileLine => {
            if (fileLine.split(",").length === 2 && /\d\d:\d\d:\d\d\.\d\d/.test(fileLine.split(",")[0]) && /\d\d:\d\d:\d\d\.\d\d/.test(fileLine.split(",")[1])) {
                if (ccEvent) {
                    ccEvent.text = convertToHtml(ccEvent);
                    events.push(ccEvent);
                }

                ccEvent = new Event({
                    xOffset : 0,
                    yOffset : options.window.height * -0.10
                });
                ccEvent.start = tcLib.tcToSec(fileLine.split(",")[0].replace(".", ":"), options.frameRate);
                ccEvent.end = tcLib.tcToSec(fileLine.split(",")[1].replace(".", ":"), options.frameRate);

            } else if (ccEvent){
                ccEvent.text += fileLine.replace(/\[br\]/gmi, "\n") + "\n";
            }
        });

        if (ccEvent) {
            ccEvent.text = convertToHtml(ccEvent);
            events.push(ccEvent);
        }

        events.push(ccEvent);
        return events;
    },

    encode: function (eventGroup, options) {
        let output = "";
        eventGroup.events.forEach(event=>{
            let tcIn = tcLib.secToTc(event.start, options.frameRate);
            let tcOut = tcLib.secToTc(event.end, options.frameRate);

            output += tcIn.substring(0,8) + "." + tcIn.substring(9);
            output+= ",";
            output += tcOut.substring(0,8) + "." + tcIn.substring(9);
            output+= "\n";
            output+= convertToPlainText(event.text, "[br]");
            output = output.slice(0, -4);
            output += "\n";
        });

        return output.trim();
    },

    preProcess: {
        encode: function (eventGroup) {
            return removeInvalidEvents(eventGroup);
        },

        decode: function (input) {
            return eol.lf(input.trim());
        }
    },

    postProcess: {
        encode: function (output) {
            return output;
        },

        decode: function (eventGroup) {
            return eventGroup;
        }
    }

}
