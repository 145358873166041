const Event = require("../classes/event.js");
const tcLib = require("../lib/timecode.js");
const removeInvalidEvents = require("../functions/eventGroups/removeInvalidEvents.js");
const eol = require("eol");
const convertToHtml = require("../functions/quill/convertToHtml.js");

module.exports = {
    decode : function(input, options){
        let subEvent, start, end, events = [], lineChunkArray,subtitleText;
        eol.split(input).forEach(fileLine =>{
            try { 
                if (!fileLine){
                    return;
                }

                subEvent ? subEvent.text += "\n" : null;

                fileLine.split(/\t+/).forEach((lineChunk,count) => {
                    let char = "", cache = "";
                    if (!lineChunk || count === 0 && lineChunk.match(/\d+/)){
                        return;
                    }
                    
                    if (lineChunk.match(/\d+\/\d+/)){
                        if (subEvent){
                            if (subEvent.italics){
                                let subtitleText = "";
                                subEvent.text.split("\n").forEach(textLine =>{
                                    textLine ? subtitleText += `<em>${textLine}</em>\n` : null;
                                });

                                subEvent.text = subtitleText;
                            }
                            subEvent.text = convertToHtml(subEvent.text);
                            events.push(subEvent);
                        }

                        start = lineChunk.split("/")[0].replace(/(.{2})(?!$)/g,"$1:");
                        end = lineChunk.split("/")[1].replace(/(.{2})(?!$)/g,"$1:");

                        //console.log(start, end);
                        subEvent = new Event({
                            start : tcLib.tcToSec(start, options.frameRate),
                            end : tcLib.tcToSec(end, options.frameRate),
                            text : "",
                            yOffset : options.window.height * -0.10
                        });

                    } else if (subEvent){
                        lineChunkArray = lineChunk.split("");
                        while (lineChunkArray.length > 0){
                            char = lineChunkArray.shift();
                            if (char === "＠"){
                                if (cache.length > 0){
                                    cache += char;
                                    /* process cache */
                                    //console.log(cache);
                                    if (cache.split("｜").length === 2){
                                        cache = cache.match(/(?<=［).+(?=］)/)[0];
                                        primaryOpt = cache.split("｜")[0];
                                        secondaryOpt = cache.split("｜")[1];
                                        if (secondaryOpt.match(/・+/g)){
                                            subEvent.text += `<strong>${primaryOpt}</strong>`;
                                        } else {
                                            subEvent.text += ` ${primaryOpt}(${secondaryOpt}) `;
                                        }
                                        
                                    } else if (cache.match(/(?<=［).+(?=］)/g)) {
                                        subEvent.text += cache.match(/(?<=［).+(?=］)/g)[0];
                                    }       

                                    cache = "";
                                } else {
                                    /* continue to build the cache */
                                    cache += char;
                                }                           
                            } else if (cache.length > 0){
                                cache += char;
                            } else {
                                subEvent.text += char;
                            }
                        }
                        /* cached commands */
                        if (cache === "＠行頭"){
                            subtitleText = subEvent.text.split("\n").filter(textLine =>{
                                return textLine;
                            });

                            subtitleText[subtitleText.length - 1] = "["+subtitleText[subtitleText.length - 1]+"]";
                            subEvent.text = subtitleText.join("\n");
                        } else if (cache.match(/＠斜/)){
                            subEvent.italics = true;
                        } else if (cache.match(/＠縦右/)){
                            subEvent.vertical = true;
                            subEvent.xPos = 'end';
                            subEvent.yPos = 'start';
                        } else if (cache.match(/＠縦左/)){
                            subEvent.vertical = true;
                            subEvent.xPos = 'start';
                            subEvent.yPos = 'start';
                        }
                    }
                });
            } catch(err){
                console.log(err, err.message);
            }            
        });
        return events;
    },

    encode : function(eventGroup, options){
        throw new Error("Videotron Lambda files are not supported for encoding by Closed Caption Converter. You can request this feature by contacting support@closedcaptioncreator.com");
    },

    preProcess : {
        encode : function(eventGroup){
            return removeInvalidEvents(eventGroup);
        },

        decode : function(input){
            return eol.lf(input).trim();
        }
    },

    postProcess : {
        encode : function(output){
            return output;
        },

        decode : function(eventGroup){
            return eventGroup;
        }
    },

}