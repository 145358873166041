/* Converts RollUp and Paint Captions to  Pop On based on the number of Lines Passed In*/
module.exports = function convertToPopOn(eventGroup, lines = 2, chars = 32) {
    let events = [], event;

    while (eventGroup.events.length > 0){
        event = eventGroup.events.shift();
        if (event.style === "Pop-On"){
            events.push(event);
        } else if (event.style === "Roll-Up 2"){
            if (eventGroup.events.length > 0  && eventGroup.events[0].style === "Roll-Up 2"){
                event.text += eventGroup.events[0].text;
                event.style = "Pop-On";                
                events.push(event);
                eventGroup.events.shift();
            } else {
                event.style = "Pop-On";
                events.push(event);                
            }
        } else if (event.style === "Roll-Up 3"){
            for (let i = 0; i < 2; i++){
                if (eventGroup.events.length > 0  && eventGroup.events[0].style === "Roll-Up 3"){
                    event.text += eventGroup.events[0].text;
                    eventGroup.events.shift();
                }
            }

            event.style = "Pop-On";                
            events.push(event);
        } else if (event.style === "Roll-Up 4"){
            for (let i = 0; i < 3; i++){
                if (eventGroup.events.length > 0  && eventGroup.events[0].style === "Roll-Up 4"){
                    event.text += eventGroup.events[0].text;
                    eventGroup.events.shift();
                }
            }

            event.style = "Pop-On";                
            events.push(event);
        } else if (event.style === "Paint-On"){
            event.style = "Pop-On";
            events.push(event);
        }
    }

    eventGroup.events = events;
    return eventGroup;
}