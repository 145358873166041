const removeInvalidEvents = require("../functions/eventGroups/removeInvalidEvents.js");
const nciFunc = require('../functions/profiles/nciCaption.js');

module.exports = {
    decode : function(input, options){
        let events = [], hexCodes = input.match(/(..?)/g), hexCode, header = hexCodes.splice(0,128), version = nciFunc.getVersion(header.slice(0,8)), textFlag = false, nciBlocks = [], nciBlock, contFlag = false, timecodes = [], tcStart, tcEnd, compareTc, timingHexCodes = [[]], textSectionFlag = false;



        //console.log(JSON.stringify(header, null, 4));
        //console.log(hexCodes);
        /* Parse For Text Events */
        let skipValue = 1;
        for (let i = 0; i < hexCodes.length; i+=skipValue){
            if (!textSectionFlag){
                if (hexCodes.slice(i,i+64).join("") === "ffff0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000" && hexCodes[i-1] !== "ff" && hexCodes[i-1] !== "fe"){
                    textSectionFlag = true;
                    skipValue = 64;
                }
            } else {
                //console.log(hexCodes.slice(i,i+64));
                if (!contFlag){
                    nciBlock = new nciFunc.Block();
                    nciBlock.insertLine({
                        xPos : hexCodes[i+3],
                        yPos : hexCodes[i+2]
                    });  
                }                
                
                for (let j = contFlag ? 2 : 4; j < 64; j++){
                    if (hexCodes[i+j] === "00"){
                        nciBlocks.push(nciBlock);
                        contFlag = false;
                        break;
                    } else if (hexCodes[i+j] === "0d"){
                        nciBlock.insertLine({
                            xPos : hexCodes[i+j+2],
                            yPos : hexCodes[i+j+1]
                        });

                        hexCodes[i+j+2] = "20"; 
                        hexCodes[i+j+1] = "20";

                    } else if (hexCodes[i+j] === "87"){
                        nciBlock.lines[nciBlock.lines.length - 1].text += "♪";
                        if (hexCodes[i+j+1] === "a0"){
                            hexCodes[i+j+1] = 20;
                        }
                    } else if (hexCodes[i+j] !== "ff") {
                        nciBlock.lines[nciBlock.lines.length - 1].text += nciFunc.decodeChar(hexCodes[i+j], "28591");
                        if (j===63){
                            contFlag = true;
                        }
                    }
                }
            }
        }

        while (hexCodes.length > 0){
            hexCode = hexCodes.shift();
            if (hexCode === "ff" && hexCodes.slice(0,3).join("") === "ffffff"){
                hexCodes.splice(0,3);
                timingHexCodes.push([]);
            } else {
                timingHexCodes[timingHexCodes.length-1].push(hexCode);
            }
        }

        //console.log(JSON.stringify(timingHexCodes, null, 4));
        timingHexCodes.forEach((tcSection,index, tcSections) => {
            // console.log("-----------");
            if (index === 0){
                //console.log(JSON.stringify(tcSection, null, 4));
                try {
                    tcStart = nciFunc.decodeTc(tcSection.slice(-4).join(""),options.frameRate);
                    timecodes.push({
                        start : tcStart,
                        end : false
                    });                    
                } catch(err){
                    //Not a timecode
                }
            } else if (index === tcSections.length-1){
                //console.log(JSON.stringify(tcSection, null, 4));
            } else {
                //console.log(tcSection);
                while (tcSection.length > 0){
                    tcSection.splice(0,6);
                    if (tcSection.length > 4){
                        tcStart = nciFunc.decodeTc(tcSection.splice(0,4).join(""),options.frameRate);
                        tcEnd = nciFunc.decodeTc(tcSection.splice(0,4).join(""),options.frameRate);

                        if (timecodes.length === 0){
                            timecodes.push({
                                start : tcStart,
                                end : tcEnd
                            });
                        } else {
                            compareTc = timecodes[timecodes.length-1].end || timecodes[timecodes.length-1].start;

                            if (tcStart >= compareTc && tcEnd >= compareTc && tcStart <= parseInt(compareTc)+600 && tcEnd <= parseInt(compareTc)+600){
                                timecodes.push({
                                    start : tcStart,
                                    end : tcEnd
                                });
                            }
                        } 
                        
                    } else {
                        try {
                            tcStart = nciFunc.decodeTc(tcSection.splice(0,4).join(""),options.frameRate);

                            if (timecodes.length === 0){
                                timecodes.push({
                                    start : tcStart,
                                    end : false
                                });
                            } else {
                                compareTc = timecodes[timecodes.length-1].end || timecodes[timecodes.length-1].start;
                                
                                if (tcStart >= compareTc && tcStart <= parseInt(compareTc)+600){
                                    timecodes.push({
                                        start : tcStart,
                                        end : false
                                    });
                                }
                            }                            
                        } catch(err){
                            //Not a timecode
                        }                        
                    }                    
                }
            }
        });

        timecodes.forEach((timecode, index, timecodesRef) =>{
            if (!nciBlocks[index]){
                return;
            }

            if (timecode.start !== false){
                nciBlocks[index].start = timecode.start;
            }

            if (timecode.end !== false){
                nciBlocks[index].end = timecode.end;
            } else if (timecodesRef[index+1]) {
                nciBlocks[index].end = timecodesRef[index+1].start;
            } else {
                nciBlocks[index].end = parseFloat(timecode.start) + 2;
            }
        });

        nciBlocks.forEach(block =>{
            events.push(nciFunc.decodeBlock(block, options));
        });       
        
        //console.log(nciBlocks.length, timecodes.length);
        //console.log(JSON.stringify(timecodes,null,4));
        //console.log(JSON.stringify(events,null,4));
        return events;
    },

    encode : function(eventGroup, options){
        throw new Error("NCI Caption files are not supported for encoding by Closed Caption Converter. You can request this feature by contacting support@closedcaptioncreator.com");
    },

    preProcess : {
        encode : function(eventGroup, options){
            return removeInvalidEvents(eventGroup);
        },

        decode : function(input, options){
            return input;
        }
    },

    postProcess : {
        encode : function(output){
            return output;
        },

        decode : function(eventGroup){
            return eventGroup;
        }
    },

}